<script lang="ts" setup>
import { PrimaryButton } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getQuoteUrl } = UseUrlHelper();
</script>

<template>
  <div class="md:px-12">
    <h4 class="text-blue text-xl font-bold pb-6 md:pb-10">When is Open Enrollment for Health Insurance This Year?</h4>
    <p class="text-gray-500 text-left">Enrollment is open now! Due to the Covid-19 Pandemic, you can enroll or change plans today.</p>
    <div class="flex py-5">
      <PrimaryButton :href="getQuoteUrl()">
        Get My Free Quote Now! <hfc-icon icon="arrow-right" class="text-white ml-1 md:ml-5" />
      </PrimaryButton>
    </div>
    <p class="text-gray-500 text-left pb-7"><strong>The Open Enrollment Period (OEP):</strong> November 1 through January 31</p>
    <p class="text-gray-500 text-left pb-7"><strong>Renewal Packets Arrive:</strong> October and November</p>
    <p class="text-gray-500 text-left pb-7"><strong>The Special Enrollment Period (SEP):</strong> February through October</p>
    <p class="text-gray-500 text-left pb-5">Most enrollment occurs during the Open Enrollment Period. No Qualifying Life Events (QLE’s) are required at this time. For those renewing, OEP gives members the opportunity to change plans each year. You can cancel your plan at any time, but to change plans, that must be done during the Open Enrollment Period. Or, you can change plans during the Special Enrollment Period with a Qualifying Life Event.</p>
    <p class="text-gray-500 text-left pb-5">Normally, during the Special Enrollment Period you would need a QLE to enroll or change plans.  Qualifying Life Events include losing coverage, losing a job, having a baby, moving to California, getting married or divorced, etc.).</p>
    <p class="text-gray-500 text-left pb-5">Due to President Biden's American Rescue Plan and the Covid-19 Pandemic, more opportunities for enrollment have opened up outside of OEP. Also, this legislation made greater discounts available.</p>
  </div>
</template>
