<script setup lang="ts">
import { ref } from 'vue';
import { LinkButton, viewportSize } from '@hfc/ui-core';
import { Popover, PopoverPanel } from '@headlessui/vue';

defineProps<{
  phoneNumber: string
}>();

const { viewport } = viewportSize();

const popoverOpen = ref(false);

</script>
<template>
  <LinkButton v-bind="$props" :href='`tel:${phoneNumber}`' v-if="viewport.isMobile">
    <hfc-icon class="text-2xl hover:text-blue-300 dark:text-white text-blue flex h-6 w-6 cursor-pointer" icon="phone" />
  </LinkButton>
  <Popover class="relative" v-else v-click-outside="() => popoverOpen = false">
    <hfc-icon class="text-2xl hover:text-blue-300 dark:text-white text-blue flex h-6 w-6 cursor-pointer" icon="phone" @click="popoverOpen =! popoverOpen"/>
    <div v-show="popoverOpen">
      <PopoverPanel :static="true" class="absolute left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4 sm:px-0 w-36">
        <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-blue-900 ring-opacity-5">
          <div class="bg-blue-50 p-4 text-center">
            <span class="text-sm font-medium text-blue-500">
              {{phoneNumber}}
            </span>
          </div>
        </div>
      </PopoverPanel>
    </div>
</Popover>
</template>
