<script lang="ts" setup>
import { ref, inject } from 'vue';
import PostBox from '@/blog/infrastructure/components/PostBox.vue';
import PostBoxSkeleton from '@/blog/infrastructure/components/PostBoxSkeleton.vue';
import { usePlocState } from '@hfc/ui-core';
import { PostStore } from '@/blog/infrastructure/store/PostStore';
const postStore = inject<PostStore>('PostStore') as PostStore;
const postState = usePlocState(postStore);
postStore.getFeaturedPost();

const isHorizontal = ref(true);
const isFeatured = ref(true);
const showExcerpt = ref(true);
</script>
<template>
  <div class="w-full">
    <div :class="!isHorizontal ? 'max-w-[400px]' : ''">
      <PostBox
        v-if="postState.featuredLoaded"
        :is-featured="isFeatured"
        :post='postState.featured'
        :show-excerpt="showExcerpt"
        :horizontal="isHorizontal"
      />
      <PostBoxSkeleton
        v-else
        :is-featured="isFeatured"
        :show-excerpt="showExcerpt"
        :horizontal="isHorizontal"
      />

    </div>
  </div>
</template>
