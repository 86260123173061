<template>
<svg xmlns="http://www.w3.org/2000/svg" width="43.688" height="43.688" viewBox="0 0 43.688 43.688">
  <g id="Group_102" data-name="Group 102" transform="translate(-3214 -304)">
    <g id="Group_101" data-name="Group 101" transform="translate(3214 304)">
      <g id="Group_100" data-name="Group 100" transform="translate(0 0)">
        <g id="Group_86" data-name="Group 86" transform="translate(0.533 0.533)">
          <g id="Group_77" data-name="Group 77" transform="translate(21.311 29.409)">
            <path id="Path_858" data-name="Path 858" d="M3466.443,641.2a9.135,9.135,0,0,0,3.21,2.287,20,20,0,0,1-6.1,5.953l-1.087-1.307L3460,645.181A9.506,9.506,0,0,0,3466.443,641.2Z" transform="translate(-3460 -641.203)" fill="#fff"/>
          </g>
          <g id="Group_78" data-name="Group 78" transform="translate(22.732 31.697)">
            <path id="Path_859" data-name="Path 859" d="M3478.131,672.914a20,20,0,0,0,6.1-5.953,29.921,29.921,0,0,0,5.555,1.868c6.124,1.478,6.1,5.164,6.1,9.057h-19.18l-.71-3.552,1.044-2.728Z" transform="translate(-3476 -666.961)" fill="#fff"/>
          </g>
          <g id="Group_79" data-name="Group 79" transform="translate(18.846 33.387)">
            <path id="Path_860" data-name="Path 860" d="M3437.172,688.955l-1.044,2.728h-2.842l-1.044-2.728,2.465-2.955Z" transform="translate(-3432.242 -686)" fill="#f8d14b"/>
          </g>
          <g id="Group_80" data-name="Group 80" transform="translate(19.18 39.07)">
            <path id="Path_861" data-name="Path 861" d="M3439.552,750l.71,3.552H3436l.71-3.552Z" transform="translate(-3436 -750)" fill="#f8d14b"/>
          </g>
          <g id="Group_81" data-name="Group 81" transform="translate(14.868 24.494)">
            <path id="Path_862" data-name="Path 862" d="M3388.2,588.763v-2.919a6.928,6.928,0,0,0,1.421,1.079c1.116.647,1.506,1.421,4.262,1.421s3.146-.774,4.262-1.421a6.925,6.925,0,0,0,1.42-1.079v2.919a3.194,3.194,0,0,0,.761,2,7.207,7.207,0,0,1-12.887,0A3.194,3.194,0,0,0,3388.2,588.763Z" transform="translate(-3387.438 -585.844)" fill="#fff"/>
          </g>
          <g id="Group_82" data-name="Group 82" transform="translate(11.657 29.409)">
            <path id="Path_863" data-name="Path 863" d="M3358.47,648.136l-1.087,1.307a19.994,19.994,0,0,1-6.1-5.953,9.139,9.139,0,0,0,3.21-2.287,9.5,9.5,0,0,0,6.443,3.978Z" transform="translate(-3351.281 -641.203)" fill="#fff"/>
          </g>
          <g id="Group_83" data-name="Group 83" transform="translate(10.655)">
            <path id="Path_864" data-name="Path 864" d="M3340.511,323.7a9.267,9.267,0,0,1-.511-3.758,7.378,7.378,0,0,1,4.262-7.1c1.108-1.641,2.841-2.841,6.394-2.841,6.663,0,10.655,2.721,10.655,9.369a12.554,12.554,0,0,1-.511,4.334.961.961,0,0,0-.2-.206c-3.374,0-1.57-6.393-5.683-6.393-2.131,0-2.927.71-4.262.71s-2.131-.71-4.262-.71c-4.113,0-2.309,6.393-5.683,6.393A.961.961,0,0,0,3340.511,323.7Z" transform="translate(-3340 -310)" fill="#979faf"/>
          </g>
          <g id="Group_84" data-name="Group 84" transform="translate(0 31.697)">
            <path id="Path_865" data-name="Path 865" d="M3220,677.886c0-3.893-.021-7.579,6.1-9.057a29.917,29.917,0,0,0,5.555-1.868,19.991,19.991,0,0,0,6.1,5.953l1.087-1.307,1.044,2.728-.71,3.552Z" transform="translate(-3220 -666.961)" fill="#fff"/>
          </g>
          <g id="Group_85" data-name="Group 85" transform="translate(10.655 7.104)">
            <path id="Path_866" data-name="Path 866" d="M3340.71,396.393c3.374,0,1.57-6.393,5.683-6.393,2.131,0,2.927.71,4.262.71s2.131-.71,4.262-.71c4.113,0,2.309,6.393,5.683,6.393a.96.96,0,0,1,.2.206,3.11,3.11,0,0,1,.511,1.925c0,3.2-2.267,3.4-2.267,3.4a10.326,10.326,0,0,1-2.706,5.471,6.914,6.914,0,0,1-1.421,1.079c-1.115.646-1.506,1.421-4.262,1.421s-3.147-.774-4.262-1.421a6.914,6.914,0,0,1-1.421-1.079,10.327,10.327,0,0,1-2.707-5.471s-2.266-.2-2.266-3.4a3.11,3.11,0,0,1,.511-1.925A.96.96,0,0,1,3340.71,396.393Z" transform="translate(-3340 -390)" fill="#fff"/>
          </g>
        </g>
        <g id="Group_99" data-name="Group 99">
          <g id="Group_87" data-name="Group 87" transform="translate(10.655 13.586)">
            <path id="Path_867" data-name="Path 867" d="M3345.188,471.481a5.714,5.714,0,0,1-3.939-1.106c-.194-.136-.378-.264-.59-.386a7.5,7.5,0,0,1-1.527-1.16,10.52,10.52,0,0,1-2.781-5.4c-.982-.278-2.351-1.309-2.351-3.85a3.611,3.611,0,0,1,.621-2.248,1.447,1.447,0,0,1,.327-.326l.591.887a.152.152,0,0,0,.018-.013.632.632,0,0,0-.089.1,2.6,2.6,0,0,0-.4,1.6c0,2.6,1.6,2.845,1.785,2.865a.526.526,0,0,1,.471.426,9.694,9.694,0,0,0,2.555,5.193,6.351,6.351,0,0,0,1.314,1c.253.146.463.293.667.435a6.519,6.519,0,0,0,6.66,0c.2-.142.415-.289.665-.434a6.345,6.345,0,0,0,1.314-1,9.7,9.7,0,0,0,2.557-5.195.533.533,0,0,1,.476-.427c.177-.019,1.78-.269,1.78-2.864a2.6,2.6,0,0,0-.4-1.6.628.628,0,0,0-.09-.1l.018.013.591-.887a1.444,1.444,0,0,1,.327.326,3.61,3.61,0,0,1,.62,2.248c0,2.541-1.369,3.572-2.35,3.85a10.522,10.522,0,0,1-2.784,5.4,7.494,7.494,0,0,1-1.527,1.16c-.209.121-.393.249-.587.385A5.715,5.715,0,0,1,3345.188,471.481Z" transform="translate(-3334 -457.008)" fill="#303e58"/>
          </g>
          <g id="Group_88" data-name="Group 88" transform="translate(11.366 7.104)">
            <path id="Path_868" data-name="Path 868" d="M3362.423,391.459c-2,0-2.533-1.774-3-3.339-.571-1.9-1.037-3.054-2.68-3.054a8.141,8.141,0,0,0-2.5.4,5.1,5.1,0,0,1-3.525,0,8.135,8.135,0,0,0-2.5-.4c-1.644,0-2.109,1.154-2.68,3.054-.471,1.565-1,3.339-3,3.339a.533.533,0,0,1,0-1.066c1.149,0,1.5-.98,1.983-2.58.51-1.7,1.146-3.813,3.7-3.813a9.161,9.161,0,0,1,2.793.437,4.09,4.09,0,0,0,2.938,0,9.159,9.159,0,0,1,2.793-.437c2.554,0,3.189,2.114,3.7,3.813.48,1.6.833,2.58,1.982,2.58a.533.533,0,1,1,0,1.066Z" transform="translate(-3342 -384)" fill="#303e58"/>
          </g>
          <g id="Group_89" data-name="Group 89" transform="translate(26.994 24.494)">
            <path id="Path_869" data-name="Path 869" d="M3534.161,599.038a.533.533,0,0,1-.533-.533v-.041c0-3.823,0-7.124-5.694-8.5a29.757,29.757,0,0,1-5.659-1.9,9.544,9.544,0,0,1-3.4-2.435,3.73,3.73,0,0,1-.878-2.33v-2.919a.533.533,0,0,1,1.066,0V583.3a2.654,2.654,0,0,0,.646,1.667,8.537,8.537,0,0,0,3.02,2.135,28.718,28.718,0,0,0,5.453,1.832c6.509,1.571,6.509,5.619,6.509,9.534v.041A.533.533,0,0,1,3534.161,599.038Z" transform="translate(-3518 -579.844)" fill="#303e58"/>
          </g>
          <g id="Group_90" data-name="Group 90" transform="translate(0 24.494)">
            <path id="Path_870" data-name="Path 870" d="M3214.533,599.038a.533.533,0,0,1-.533-.533v-.041c0-3.915,0-7.964,6.51-9.534a28.7,28.7,0,0,0,5.452-1.831,8.539,8.539,0,0,0,3.024-2.14,2.652,2.652,0,0,0,.643-1.663v-2.919a.533.533,0,0,1,1.066,0V583.3a3.726,3.726,0,0,1-.875,2.326,9.55,9.55,0,0,1-3.4,2.439,29.767,29.767,0,0,1-5.658,1.9c-5.695,1.374-5.695,4.676-5.695,8.5v.041A.533.533,0,0,1,3214.533,599.038Z" transform="translate(-3214 -579.844)" fill="#303e58"/>
          </g>
          <g id="Group_91" data-name="Group 91" transform="translate(10.655)">
            <path id="Path_871" data-name="Path 871" d="M3355.333,318.769a.533.533,0,0,1-.5-.721,12.1,12.1,0,0,0,.477-4.146c0-5.946-3.311-8.837-10.123-8.837-3.716,0-5.132,1.393-5.952,2.607a.533.533,0,0,1-.244.2,6.1,6.1,0,0,0-2.563,2.082,7.565,7.565,0,0,0-1.364,4.527,8.817,8.817,0,0,0,.477,3.57.533.533,0,0,1-1,.376,9.749,9.749,0,0,1-.546-3.946,7.915,7.915,0,0,1,4.446-7.535c1.418-2.007,3.566-2.943,6.743-2.943,7.424,0,11.188,3.332,11.188,9.9a13.086,13.086,0,0,1-.545,4.521A.533.533,0,0,1,3355.333,318.769Z" transform="translate(-3334 -304)" fill="#303e58"/>
          </g>
          <g id="Group_92" data-name="Group 92" transform="translate(21.311 29.409)">
            <path id="Path_872" data-name="Path 872" d="M3458.085,644.509a.531.531,0,0,1-.41-.192l-1.087-1.307-2.464-2.955a.533.533,0,0,1,.336-.869,8.976,8.976,0,0,0,6.08-3.755.533.533,0,0,1,.873.611,10.08,10.08,0,0,1-5.89,4.027l2.972,3.567a.533.533,0,0,1-.409.873Z" transform="translate(-3454 -635.203)" fill="#303e58"/>
          </g>
          <g id="Group_93" data-name="Group 93" transform="translate(14.868 29.409)">
            <path id="Path_873" data-name="Path 873" d="M3384.861,644.508a.533.533,0,0,1-.41-.873l2.972-3.567a10.08,10.08,0,0,1-5.89-4.027.533.533,0,0,1,.873-.611,8.975,8.975,0,0,0,6.08,3.756.533.533,0,0,1,.336.869l-2.465,2.955-1.087,1.306A.531.531,0,0,1,3384.861,644.508Z" transform="translate(-3381.437 -635.203)" fill="#303e58"/>
          </g>
          <g id="Group_94" data-name="Group 94" transform="translate(24.863 31.697)">
            <path id="Path_874" data-name="Path 874" d="M3494.532,667.979a.533.533,0,0,1-.282-.985,19.6,19.6,0,0,0,5.939-5.794.533.533,0,0,1,.89.587,20.674,20.674,0,0,1-6.265,6.111A.531.531,0,0,1,3494.532,667.979Z" transform="translate(-3493.999 -660.96)" fill="#303e58"/>
          </g>
          <g id="Group_95" data-name="Group 95" transform="translate(11.657 31.697)">
            <path id="Path_875" data-name="Path 875" d="M3351.915,667.979a.529.529,0,0,1-.281-.081,20.669,20.669,0,0,1-6.265-6.111.533.533,0,0,1,.889-.587,19.594,19.594,0,0,0,5.939,5.794.533.533,0,0,1-.283.985Z" transform="translate(-3345.28 -660.96)" fill="#303e58"/>
          </g>
          <g id="Group_96" data-name="Group 96" transform="translate(22.732 36.342)">
            <path id="Path_876" data-name="Path 876" d="M3471.243,720.625a.533.533,0,0,1-.522-.428l-.71-3.552a.533.533,0,0,1,.025-.295l1.044-2.728a.533.533,0,0,1,1,.381l-.989,2.584.68,3.4a.533.533,0,0,1-.523.637Z" transform="translate(-3470 -713.28)" fill="#303e58"/>
          </g>
          <g id="Group_97" data-name="Group 97" transform="translate(18.846 36.342)">
            <path id="Path_877" data-name="Path 877" d="M3427.108,720.626a.527.527,0,0,1-.1-.01.533.533,0,0,1-.418-.627l.68-3.4-.989-2.584a.533.533,0,0,1,1-.381l1.044,2.728a.538.538,0,0,1,.025.3l-.71,3.552A.533.533,0,0,1,3427.108,720.626Z" transform="translate(-3426.241 -713.281)" fill="#303e58"/>
          </g>
          <g id="Group_98" data-name="Group 98" transform="translate(19.89 39.07)">
            <path id="Path_878" data-name="Path 878" d="M3441.374,745.066h-2.842a.533.533,0,0,1,0-1.066h2.842a.533.533,0,0,1,0,1.066Z" transform="translate(-3438 -744)" fill="#303e58"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
</template>