<script lang="ts" setup>
import { computed, inject, onMounted } from 'vue';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import { usePlocState } from '@hfc/ui-core';
import MetalPlan from '@/page/infrastructure/components/MetalPlan.vue';
import { MetalPlanData } from '@/page/domain/MetalPlanData';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import PlanCoverage from '@/page/infrastructure/components/plans/PlanCoverage.vue';

const { getBaseUrl } = UseUrlHelper();

const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);

const metalPlans = computed(() => pageState.value.metalPlans.filter(plan => plan.primary) as Array<MetalPlanData>);
const catastrophicPlan = computed(() => pageState.value.metalPlans.find(plan => plan.key == 'catastrophic') as MetalPlanData);

onMounted(async () => {
  await pageStore.getMetalPlans();
});
</script>

<template>
  <div class="text-left h-full">
    <h4 class="text-blue font-bold text-xl">What Types of Health Plans are Available</h4>
    <p class="text-gray-500 py-5 text-base ">Health plans are classified under five major categories. This makes comparisons easier. The categories are divided according to the percentage of health care costs that consumers pay.</p>
    <MetalPlan v-for="(plan, i) in metalPlans" :key="i" :plan="plan" class="border-b-2"></MetalPlan>
    <MetalPlan v-if="catastrophicPlan" :plan="catastrophicPlan">
      <template #plan-coverage>
        These Plans Are Only Available For People Under 30 Years Or Those Experiencing <a class="text-blue font-medium text-sm hover:underline" :href="getBaseUrl('/')">Financial Hardship.</a>
      </template>
      <template #client-coverage>
        <PlanCoverage :coverage="catastrophicPlan.coverage" title="This Plan Pays less than" subtitle="Of Your Health Costs." />
      </template>
    </MetalPlan>
  </div>
</template>
