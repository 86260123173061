import { CarrierPage } from '@/page/domain/CarrierPage';

export const blueShieldPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Blue Shield <br> Health Insurance Coverage`,
  },
  aboutInfo:{
    visible: true,
    title: 'About Blue Shield',
    content: `<p>Blue Shield of California, based in San Francisco, has been providing health coverage to California residents since 1939. Today, Blue Shield of California is one of only two preferred provider organizations that offers plans to all California ZIP codes and counties.logo blue shield of california</p><br>
    <p>Blue Shield of California serves more than 4 million members. So why are so many Californians turning to Blue Shield for their health insurance coverage? Blue Shield believes every Californian deserves quality health care, comprehensive benefits and plans at affordable prices.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'What Are the Benefits of Blue Shield?',
    content: `<p>Blue Shield is a nonprofit health plan provider, and in 2011, they pledged to take only 2% of their net income as revenue and give the rest back to their members and community. Since making this pledge, Blue Shield has returned as much as $560 million.</p><br>
    <p>Blue Shield offers many benefits for its members, including the following.</p>`,
    imageUrl: '/assets/img/bg/doctor_desk.webp'
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>For 2016 L.A. Care's individual and family plans offered through Covered California will provide access to</p>`,
    redirectUrl: 'https://www.lacare.org/health-plans/la-care-covered',
    subContent: [{
      title: '42',
      content: 'Lorem Ipsum'
    },
    {
      title: '19',
      content: 'Lorem Ipsum'
    },
    {
      title: '2,559',
      content: 'Lorem Ipsum'
    },
    {
      title: '2,801',
      content: 'Lorem Ipsum'
    }
  ]
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'Who Should Consider Blue Shield Coverage?',
    content: `<p>You’re aware of the plans Blue Shield of California has to offer. You know the benefits, and you may even have an idea of the cost. But how do you know if you should consider Blue Shield coverage?</p><br>
    <p>Californians in need of affordable health care should consider Blue Shield. Members can enjoy a myriad of benefits and the peace of mind of having essential, high-quality coverage they can depend on.</p><br>
    <p>Those who are seeking a health plan provider that gives back to the community should look no further than Blue Shield of California. On top of the provider’s pledge to return the majority of their revenue, employees of Blue Shield perform volunteer work to help the community. In 2014, employees raised more than $400,000 to donate to local nonprofits.</p><br>
    <p>If you’re looking for affordable health insurance coverage in California, get a quote for Blue Shield from Health for California today.</p>`,
    imageUrl: '/assets/img/bg/skating.webp'
  }
};
