<script setup lang="ts">
import { MetalPlanData } from '@/page/domain/MetalPlanData';
import ShowPriceRate from '@/page/infrastructure/components/metalPlans/ShowPriceRate.vue';
import { StarsRate } from '@hfc/ui-core'
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();

defineProps<{
  plan: MetalPlanData,
  highlight?: boolean,
}>();
</script>
<template>
  <div class="flex flex-col w-full border border-slate-50 rounded-md px-6 py-8" :class="{ 'bg-sky-50': highlight, 'bg-white': !highlight }">
    <div class="flex flex-row space-x-6 mb-8">
      <div class="flex w-28">
        <img :src="getCdnUrl(plan.icon)" :alt="plan.key" />
      </div>
      <div class="flex flex-col flex-1">
        <div class="flex flex-row mb-3">
          <h4 class="text-black">
            {{ plan.tierName }} {{ plan.tierNumber }}
            <span v-if="plan.tierSuffix" class="text-black">{{ plan.tierSuffix }}</span>
          </h4>
        </div>
        <div class="flex flex-row mb-2">
          Price:&nbsp;&nbsp;<ShowPriceRate v-if="plan.priceRating" :price-rate="plan.priceRating" />
        </div>
        <div class="flex flex-row">
          Popularity:&nbsp;&nbsp;<StarsRate v-if="plan.popularityRating" :popularity-rate="plan.popularityRating" icon-size="text-md"/>
        </div>
      </div>
    </div>
    <div class="flex flex-col mb-4">
      <div class="flex flex-row border-b-2 py-2">
        <div class="flex flex-1">
          <p>Deductible</p>
        </div>
        <div class="flex flex-1">
          <div v-html="plan.deductible"></div>
        </div>
      </div>
      <div class="flex flex-row border-b-2 py-2">
        <div class="flex flex-1">
          <p>Doctor Visits</p>
        </div>
        <div class="flex flex-1">
          <div v-html="plan.doctorVisits"></div>
        </div>
      </div>
      <div class="flex flex-row border-b-2 py-2">
        <div class="flex flex-1">
          <p>Generic Drugs</p>
        </div>
        <div class="flex flex-1">
          <div v-html="plan.genericDrugs"></div>
        </div>
      </div>
      <div class="flex flex-row border-b-2 py-2">
        <div class="flex flex-1">
          <p>Hospital</p>
        </div>
        <div class="flex flex-1">
          <div v-html="plan.hospital"></div>
        </div>
      </div>
      <div class="flex flex-row py-2">
        <div class="flex flex-1">
          <p>OOP Max</p>
        </div>
        <div class="flex flex-1">
          <div v-html="plan.oopMax"></div>
        </div>
      </div>
    </div>
    <div v-if="plan.primary" class="flex flex-col justify-end h-full">
      <div class="flex w-full flex-col px-6 py-4 bg-blue-400 rounded-md">
        <div class="flex justify-center">
          <h6 class="text-white font-semibold">Estimated Avg Medical Costs</h6>
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex flex-row">
            <div class="flex flex-1 flex-row">
              <p class="text-white">Insurance Company pays</p>
            </div>
            <div class="flex flex-row font-bold">
              <p class="text-white">{{ plan.coverage }}%</p>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="flex flex-1 flex-row">
              <p class="text-white">You Pay</p>
            </div>
            <div class="flex flex-row font-bold">
              <p class="text-white">{{ 100 - plan.coverage }}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
