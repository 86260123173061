<script setup lang="ts">
import { ref } from 'vue';
import { BaseButton, viewportSize } from '@hfc/ui-core';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import HeadingNavSubItem from '@/shared/infrastructure/components/heading/HeadingNavSubItem.vue';
import { MenuItem } from '@/shared/domain/MenuItem';

defineProps<{
  item: MenuItem,
  smallHeight?: boolean
}>();

const emits = defineEmits<{
  (event: 'closeOthers'): void
}>();

const popoverOpen = ref(false);
const { viewport } = viewportSize();

function onClickOutside(): void {
  if (!viewport.value.isMobile) {
    popoverOpen.value = false;
  }
}

// All this is a workaround because headless ui behavior was not working when importing it as a WC
function toggleMenu(): void {
  const prevValue = popoverOpen.value;
  emits('closeOthers');
  popoverOpen.value = !prevValue;
}

defineExpose({
  onClickOutside
});
</script>
<template>
  <BaseButton v-if="item.href" :href="item.href" :ignore-styles="true" class="flex items-center hover:bg-blue-100 group lg:px-5">
    <span class="sm:px-2 items-center text-base md:text-sm lg:text-base font-normal focus:outline-none focus:text-blue-dark text-white md:text-blue-500 group-hover:text-blue dark:text-white border-transparent border-b-2">
      {{ item.label }}
    </span>
  </BaseButton>
  <Popover v-else class="overflow-x-hidden md:overflow-x-visible md:flex md:hover:bg-blue-100 group" v-click-outside="onClickOutside" :class="{ 'md:bg-blue-100': popoverOpen }">
    <PopoverButton ref="buttonRef" class="group w-full inline-flex items-center text-base md:text-sm lg:text-base font-normal focus:outline-none focus:text-blue-dark text-white md:text-blue-500 dark:text-white dark:group-hover:md:text-blue-500 border-transparent border-b-2 max-h-[112px]" :class="{ 'md:dark:text-blue-500': popoverOpen }">
      <div class="flex items-center w-full sm:px-2 lg:px-7" @click="toggleMenu">
        <span class="sm:px-2 md:pr-4 h-full py-6 md:py-11">
          {{ item.label }}
        </span>
        <hfc-icon v-if="item.subMenu.length" :icon="`chevron-${popoverOpen ? 'up' : 'down'}`" class="h-4 w-4 -mt-1" />
      </div>
    </PopoverButton>
    <div v-show="popoverOpen">
      <PopoverPanel :static="true" v-if="item.subMenu.length" ref="dropdownRef" :class="{ 'md:mt-20': smallHeight, 'md:mt-24': !smallHeight }" class=" mt- md:absolute z-20 px-2 sm:px-5 text-center right-0 left-0 flex flex-col md:flex-row mt-6 w-screen max-w-7xl mx-auto [&>.sub-item:nth-of-type(even)]:bg-blue-100 [&>.sub-item:nth-of-type(odd)]:bg-white">
        <HeadingNavSubItem v-for="i in item.subMenu" :key="i.label" :item="i" :class="item.customClass" class="flex-grow py-2 md:py-8 shadow sub-item" />
      </PopoverPanel>
    </div>
  </Popover>
</template>