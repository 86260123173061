<script setup lang="ts">
import { computed } from 'vue';
import { Post } from '@/blog/domain/Post';
import { PrimaryButton, viewportSize, useRouter } from '@hfc/ui-core';

const { viewport } = viewportSize();
const router = useRouter();

const props = defineProps<{
  isFeatured?: boolean,
  horizontal?: boolean,
  showExcerpt: boolean,
  post: Post
}>();

const isHorizontal = computed(() => props.horizontal && !viewport.value.isMobile);
const directionClass = computed(() => isHorizontal.value ? 'flex-row' : 'flex-col');
const imageSectionBorderClass = computed(() => isHorizontal.value ? 'border-r-4 border-r-yellow-400' : 'border-b-4 border-b-yellow-400');
const imageSectionRoundedClass = computed(() => isHorizontal.value ? 'rounded-l-2xl' : 'rounded-t-2xl');
const featuredRoundedClasses = computed(() => isHorizontal.value ? '' : 'rounded-tr-2xl');
const contentSectionRoundedClasses = computed(() => isHorizontal.value ? 'rounded-r-2xl space-y-3' : 'rounded-b-2xl space-y-6');
const postRoute = router.resolve({ name: 'Post', params: { id: props.post.id } });

</script>

<template>
  <div class="flex w-full" :class="directionClass">
    <div class="bg-blue-500 flex-1 flex flex-col min-h-[324px] rounded-tl-2xl"
      :class="[imageSectionRoundedClass, imageSectionBorderClass]">
      <div v-if="isFeatured" class="bg-blue-900 rounded-tl-2xl py-3 md:py-5 px-6 sm:px-10 md:px-14 flex items-center"
        :class="featuredRoundedClasses">
        <span class="text-white text-2xl font-bold">Featured Article</span>
      </div>
      <div class="flex items-center justify-center flex-1 bg-center bg-no-repeat bg-cover"
        :class="{ 'rounded-tl-2xl': !isFeatured, 'rounded-tr-2xl': !isHorizontal && !isFeatured, 'rounded-bl-2xl': isHorizontal }"
        :style="`background-image:url('${post.imageUrl}')`">
      </div>
    </div>
    <div class="min-h-[324px] flex-1 flex flex-col justify-between p-8 text-gray-500 box-shadow-highlighted"
      :class="contentSectionRoundedClasses">
      <div>
        <h3 class="font-semibold text-2xl text-blue-500">{{ post.title }}</h3>
        <div>{{ post.author.name }}</div>
      </div>
      <div v-if="showExcerpt" class=" font-normal text-base leading-relaxed text-left">
        {{ post.excerpt }}
      </div>
      <div class="flex justify-between items-center">
        <PrimaryButton :href="postRoute.href">Read more</PrimaryButton>
        <span class="ml-3">{{ post.formattedDate }}</span>
      </div>
  </div>
</div></template>
