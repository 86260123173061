import { CarrierPage } from '@/page/domain/CarrierPage';

export const healthNetPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Health Net <br> Health Insurance Coverage`,
  },
  aboutInfo: {
    visible: true,
    title: 'About Health Net',
    content: `<p>Health Net believes every person deserves a safety net for their health, regardless of age, income, employment status or current state of health. Founded in
    California more than 40 years ago, HealthNet is dedicated to transforming the health of the community, one person at a time. Health Net provides health plans for individuals, families, businesses of every size and people who qualify for Medi-Cal or Medicare — Coverage for Every Stage of LifeTM. Health Net offers the following plans:</p><br>
    <ul class="ml-10 list-disc">
    <li>Exclusive provider organization (EPO)</li>
    <li>Health maintenance organization (HMO)</li>
    <li>Preferred provider organization (PPO)</li>
    <li>Health care services plans (HSP)</li>
    </ul>`,
    textDirection: 'text-left',
  },
  sectionInfo: {
    visible: true,
    title: 'Take Note',
    content: `<p>Health Net is proud to be nationally recognized for quality care and service by the following organizations:</p><br>
    <b>2021</b><br>
    <ul class="ml-10 list-disc">
    <li>#1 in Customer Service among Health Insurers in Forrester’s Proprietary 2021 US Customer Experience Index (CX Index™) Survey</li>
    <li>America’s Best Customer Service Among Health Insurance Plans – Newsweek</li>
    <li>DHCS consumer satisfaction award 2021 – Large Scale Plan (Child)</li>
    </ul><br>
    <b>2020</b><br>
    <ul class="ml-10 list-disc">
    <li>Recognized for Excellent Quality of Care and Customer Service – The Center for Medicare & Medicaid Services</li>
    </ul><br>
    <b>2019</b><br>
    <ul class="ml-10 list-disc">
    <li>Multicultural Health Care Distinction Award – The National Coalition of Quality Assurance (NCQA)</li>
    <li>Health Equity Award for Postpartum Care Disparities Project – The Department of Health Care Services (DHCS)</li>
    <li>Diversity Champion Award – National Association of Asian Pacifics in Politics and Public Affairs</li>
    <li>eValue8 Innovation Award – National Alliance of Healthcare Purchaser Conditions</li>
    </ul><br>
    With millions of members in California alone, Health Net’s network offers something for everyone. Health Net Covered California plans are available in much of California.</p><br>
    <p>As of 2015, Health Net Covered California enrollees can access University of California Los Angeles (UCLA) hospitals and doctors by selecting the Small Group Health Net PPO on all metal tiers, including Bronze, Silver, Gold and Platinum.</p><br>
    <p>In 2018, Health Net brought its PPO into Sacramento, Placer, Yolo, Los Angeles, San Diego and parts of Southern California.</p><br>
    <p>Its EnhancedCare PPO will give members the flexibility they expect from a PPO without all the cost. It comes with a smaller PPO network at a lower cost but still includes access to an advice nurse, virtual doctor, retail clinic and the Enhanced Care hospitals and doctors in-network. If members want to go out of network, they can, but at a higher cost. It basically puts them in the driver’s seat.</p>`,
    imageUrl: '/assets/img/bg/doctor_desk.webp',
    textDirection: 'text-left',
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>For 2016 L.A. Care's individual and family plans offered through Covered California will provide access to</p>`,
    redirectUrl: 'https://www.lacare.org/health-plans/la-care-covered',
    subContent: [{
        title: '42',
        content: 'Lorem Ipsum'
      },
      {
        title: '19',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,559',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,801',
        content: 'Lorem Ipsum'
      }
    ]
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>Licensed nurses are available to Health Net members 24/7 to assist with many concerns, including minor illnesses and injuries, advice on urgent health situations and other health questions.</p><br>
    <p>Members also have access to online tools that allow them to see details about their plan and pharmacy benefits, view health screenings, change a doctor, use programs for weight management and more.</p><br>
    <p>Health Net offers a variety of health education classes in English and many in Spanish.</p>`,
    imageUrl: '/assets/img/bg/skating.webp'
  }
};
