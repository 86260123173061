<script setup lang="ts">
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import { CarrierList } from '@hfc/ui-core';

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:afterFinalSection="{ pageData }">
      <SectionInfo v-if="pageData.extraSection1" :carrier-data="pageData.extraSection1" />
      <SectionInfo v-if="pageData.extraSection2" :carrier-data="pageData.extraSection2" />
      <SectionInfo v-if="pageData.extraSection3" :carrier-data="pageData.extraSection3" />
    </template>
  </CarrierPlan>
</template>
