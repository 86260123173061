import { CarrierPage } from '@/page/domain/CarrierPage';
import ArchiveIcon from '@/page/infrastructure/components/carrier/ArchiveIcon.vue';
import BookmarkIcon from '@/page/infrastructure/components/carrier/BookmarkIcon.vue';
import CarpetIcon from '@/page/infrastructure/components/carrier/CarpetIcon.vue';
import ClipboardIcon from '@/page/infrastructure/components/carrier/ClipboardIcon.vue';
import DoctorIcon from '@/page/infrastructure/components/carrier/DoctorIcon.vue';
import HandCrossIcon from '@/page/infrastructure/components/carrier/HandCrossIcon.vue';
import HospitalIcon from '@/page/infrastructure/components/carrier/HospitalIcon.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();

export const laCarePageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `L.A. Care Health <br> Insurance Coverage`,
    content: `Find the Lowest Prices Available on L.A. Care Medical Insurance Plans or Get Discounts Through Our Covered California Options`
  },
  aboutInfo: {
    visible: true,
    title: 'About Los Angeles Care',
    content: `<p>Los Angeles Health Care coverage is vital in providing essential health care services to the diverse population of Los Angeles County. The program enables low-income earners to access quality medical services, especially those who are uninsured or underinsured. From Medi-Cal to specialized programs like Medicare Plus, L.A. Care delivers comprehensive coverage for individuals and families. The membership continues to grow, yet educating people about what the plan entails is vital.</p><br>
    <p>This guide discusses L.A. Care in detail, as well as the diversity initiatives, network size and coverage. Contact Health for California today to learn more and <a class="underline" href="${getBaseUrl('/individual-and-family-quote')}">get free assistance finding</a> the ideal coverage.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'What Is L.A. Care Insurance?',
    content: `L.A. Care Health Plan is the largest publicly operated health plan in the United States. It was established in 1997 to provide affordable and quality health coverage to low-income residents of Los Angeles County. Today, the <a class="underline" href="https://www.lacare.org/about/what-we-do" target="_blank">health plan serves over 2.5 million members</a> through several health coverage programs. Including:`,
    imageSection: {
      title: 'Health Coverage Programs',
      titleSeparator: true,
      titleSeparatorColor: 'bg-teal-400',
      contentDirection: 'center',
      imageUrl: '/assets/img/carriers/la-care-background.webp',
      redirectButtonText: 'Get A Free Quote',
      boxContent: [{
        title: 'L.A. Care Covered',
        content: 'It is generally available to individuals or families disqualified from Medi-Cal who need affordable health coverage.',
        boxData: {
          component: CarpetIcon,
        }
      },
      {
        title: 'Medi-Cal',
        content: 'This public program provides health care coverage to adults, older adults, families and people with disabilities.',
        boxData: {
          component: BookmarkIcon,
        }
      },
      {
        title: 'L.A. Care Medicare Plus (HMO D-SNP)',
        content: 'This plan coordinates Medi-Cal and Medicare benefits under one health plan.',
        boxData: {
          component: ArchiveIcon,
        }
      },
      {
        title: 'PASC-SEIU',
        content: 'The Homecare Workers Health Care Plan is tailored for homecare workers.',
        boxData: {
          component: ClipboardIcon,
        }
      }]
    },
    subContent: [{
        title: 'L.A. Care Covered',
        content: 'This program offers health insurance plans through Covered California. It is available to individuals or families disqualified from Medi-Cal who need affordable health coverage.'
      },
      {
        title: 'Medi-Cal',
        content: `This public program <a class="underline" href="https://www.lacare.org/health-plans/medi-cal" target="_blank">provides health care coverage to adults</a>, older adults, families and people with disabilities. You must meet the established income requirements to qualify.`
      },
      {
        title: 'L.A. Care Medicare Plus (HMO D-SNP) plan',
        content: `This plan <a class="underline" href="https://medicare.lacare.org/" target="_blank">coordinates Medi-Cal and Medicare benefits</a> under one health plan, giving you access to a vast network os hospitals, doctors and pharmacies.`
      },
      {
        title: 'PASC-SEIU plan',
        content: `The Homecare Workers Health Care Plan, <a class="underline" href="https://www.lacare.org/health-plans/pasc-seiu" target="_blank">also known as PASC-SEIU</a>, is tailored for homecare workers. The Personal Assistance Services Council (PASC) determines the eligibility criteria.`
      }
    ],
    secondContent: `L.A. Care is one of 12 carriers offered on California's State Exchange - Covered California. Coverage is provided in the Southwest and Northeast Los Angeles county pricing regions. Members can get assistance or receive information in their preferred language other than English. The plan covers people from <a class="underline" href="https://lacare.org/providers/news/thepulse/population-needs-assessment-key-findings" target="_blank">diverse racial and ethnic backgrounds</a>, primarily women and children. L.A. Care Health Plan is <a class="underline" href="https://www.lacare.org/about/social-justice" target="_blank">intolerant of discrimination</a> and provides equal opportunities for all.`
  },
  networkInfo: {
    visible: true,
    title: 'The L.A. Care Network and Coverage',
    imageSection: {
      title: 'The L.A. Care Network and Coverage',
      titleColor: 'text-white',
      contentDirection: 'center',
      imageUrl: '/assets/img/carriers/la-care-network-banner.webp',
      boxContent: [{
        boxData: {
          component: HospitalIcon,
          title: '42',
          content: 'HOSPITALS',
        }
      },
      {
        boxData: {
          component: DoctorIcon,
          title: '2000',
          content: 'PHYSICIANS',
        }
      },
      {
        boxData: {
          component: HandCrossIcon,
          title: '1000',
          content: 'PHARMACISTS',
        }
      },
      {
        boxData: {
          list: ['Hospital services', 'Primary care', 'Preventive care', 'Emergency room care', 'Mental health services', 'Prescription medication'],
          listColor: 'text-white',
          boxColor: 'bg-slate-700',
        }
      }]
    },
    content: `<p>L.A. Care members also have access to nurses to consult over the phone, disease management programs, health education and exercise classes. L.A. Care engages in community outreach as membership and networks grow, prompting members about available services and resources. Health for California provides invaluable solutions, such as helping individuals and families find the ideal coverage.</p><br>
    <p>Members can also <a class="underline" href="https://lacare.org/agents/insider/members-can-now-pay-premiums-cash" target="_blank">leverage the PayNearMe platform</a> to conveniently pay their monthly health insurance premium with cash at several retail locations, such as 7-Eleven, ACE Cash Express or Family Dollar Store. There are over 680 locations throughout California.</p>`,
    subContent: [{
        content: `<p>The L.A. Care Health Plan provides individuals and families access to <a class="underline" href="https://www.lacare.org/agents/insider/la-care-offers-largest-easy-access-network-la-county" target="_blank">medical facilities and professionals</a> through Covered Calif. including:</p>
        <ul class="ml-4 mt-4 list-decimal">
          <li>42 Hospitals</li>
          <li>2,000 Physicians</li>
          <li>1,000 Pharmacists</li>
        </ul>`
      },
      {
        content: `<p>The coverage is comprehensive and tied to the various programs. These generally include:</p>
        <ul class="ml-4 mt-4 list-decimal">
          <li>Hospital services</li>
          <li>Primary care</li>
          <li>Preventive care</li>
          <li>Emergency room care</li>
          <li>Mental health services</li>
          <li>Prescription medication</li>
        </ul>`
      },
    ],
    redirectButtonText: 'GET MY FREE QUOTE NOW',
    redirectUrl: getQuoteUrl(),
    imageUrl: '/assets/img/bg/skating.webp'
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'Contact Health for California to Learn More About the L.A. Care Health Plan',
    content: `<p>The L.A. Care Health Plan provides affordable and quality health care coverage to residents in California County. Since its introduction over two decades ago, the program has helped millions of individuals and families conveniently access medical facilities and professionals. The members continue to grow, yet educating more people about the benefits and the application process is crucial.</p><br>
    <p>Health for California can connect you to the ideal coverage without any charge. We help people get discounts through our covered California options or find the lowest prices available on L.A. Care medical insurance plans. Do you want to learn more? <a class="underline" href="${getBaseUrl('/contact-us')}">Contact us</a> today!</p>`,
    bgColor: 'bg-sky-50',
    imageSection: {
      title: 'Contact <span class="font-bold">Health for California</span> to Learn More About the L.A. Care Health Plan',
      contentWidth: 'w-1/2',
      justifyContent: 'items-end',
      textDirection: 'text-left',
      imageUrl: '/assets/img/carriers/doctor-woman-banner.webp',
      redirectButtonUrl: getBaseUrl('/contact-us'),
      redirectButtonText: 'Contact Us'
    },
  },
  extraSection1: {
    visible: true,
    title: 'L.A. Care Cultural Diversity and Support',
    content: `<p>L.A. Care takes its member's needs seriously. Members can take advantage of in- person interpretation services in over 50 languages and over-the-phone interpretations in 180 languages. They can also receive membership materials in 10 languages. Because of L.A. Care's cultural and linguistic outreach efforts to its diverse membership, the National Committee for Quality Assurance (NCQA) awarded them the <a class="underline" href="https://lacare.org/providers/news/thepulse/la-care-awarded-2021-multicultural-health-care-distinction" target="_blank">Multicultural Health Care Distinction</a> in 2013 and 2021. Only a few health plans in the United States have received this honor.</p><br>
    <p>L.A. Care has partnered with the Los Angeles Department of Public Health to implement the eConsult system, allowing primary care doctors and specialists to discuss patient cases. This has created more efficiency by reducing wait time for specialists and removing referrals that are not needed.</p>`,
    redirectUrl: 'https://www.lacare.org/health-plans/la-care-covered',
    redirectButtonText: 'GET MY FREE QUOTE NOW',
    imageUrl: '/assets/img/carriers/diversity-doctors.webp'
  },
};
