import { Component } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { RouteMeta } from '@/shared/application/RouteMeta';

export class MetalPlanRouteCollection {
  private routes: Array<RouteRecordRaw & { meta: RouteMeta }> = [];

  constructor(metalPlans: Array<{ href: string; title: string; description: string; metallicPlanName: string; component: Component }>) {
    this.routes = metalPlans.map(metalPlan => ({
      path: `/covered-california/plans/${metalPlan.href}`,
      name: this.getMetallicPlanRouteName(metalPlan.metallicPlanName),
      meta: {
        layout: 'HomePage',
        title: metalPlan.title,
        description: metalPlan.description,
        theme: 'light',
        quoteBanner: true,
        breadcrumbs: [
          {
            label: 'California Health Insurance',
            href: '/'
          },
          {
            label: 'Covered California',
            href: '/covered-california'
          },
          {
            label: 'Plans',
            href: '/covered-california/plans'
          },
          {
            label: this.getMetallicPlanBreadcrumbLabel(metalPlan.metallicPlanName),
            href: `/covered-california/plans/${metalPlan.href}`
          }
        ]
      },
      props: {
        planName: metalPlan.metallicPlanName
      },
      component: metalPlan.component
    }));
  }

  private getMetallicPlanRouteName(metallicPlanName: string) {
    const splittedMetallicPlanName = metallicPlanName.split('_');
    const capitalizedWords = splittedMetallicPlanName.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    return capitalizedWords.join('');
  }

  private getMetallicPlanBreadcrumbLabel(metallicPlanName: string) {
    const splittedMetallicPlanName = metallicPlanName.split('_');
    const capitalizedWords = splittedMetallicPlanName.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    return capitalizedWords.join(' ');
  }

  public getRoutes() {
    return this.routes;
  }
}
