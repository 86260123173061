export default [{
  label: 'Enrollment',
  href: '',
  description: null,
  customClass: '',
  prefixImg: '',
  subMenu: [
    {
      label: 'Deadlines',
      href: '/covered-california/deadline',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Open enrollment period',
      href: '/covered-california-enrollment/open-enrollment',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Special Enrollment Period',
      href: '/covered-california-enrollment/open-enrollment/special-enrollment',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Qualifying Events',
      href: '/covered-california/life-events',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Reporting Changes',
      href: '/covered-california/application/report-changes',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    }
  ]
},
{
  label: 'Affordable Care',
  href: '/affordable-care-act',
  description: null,
  customClass: '',
  prefixImg: '',
  subMenu: [
    {
      label: 'Income Limits Charts',
      href: '/covered-california/income-limits',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Government Discounts',
      href: '/covered-california/subsidy-tax-credits',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Penalty for the Uninsured',
      href: '/covered-california/income-limits',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'IRS form 1095A',
      href: '/covered-california/irs-form-1095',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Free Preventive Care',
      href: '/obamacare/preventive-care',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    }
  ]
},
{
  label: 'Resources',
  href: '',
  description: null,
  customClass: '',
  prefixImg: '',
  subMenu: [
    {
      label: 'FAQ',
      href: '/faq',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Health Care Blog',
      href: '/blog',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'HMO vs PPO',
      href: '/covered-california/plans/hmo-or-ppo',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Save with HMO',
      href: '/is-it-time-to-switch-to-an-hmo-plan',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Zero Dollar Plans',
      href: '/blog/free-health-insurance',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
    {
      label: 'Self Employed Options',
      href: '/blog/self-employed-health-insurance',
      description: null,
      customClass: '',
      prefixImg: '',
      subMenu: []
    },
  ]
}];
