import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();
export const platinum90PageData: Partial < MetalPlanPageSection > = {
  initialBanner: {
    visible: true,
    title: `Covered California`,
    subtitle: `Top of the Line`,
    subtitleColor: 'text-slate-400',
  },
  highlightSection: {
    visible: true,
    content: `<p>Think the “Rolls-Royce” of the Covered California Metal Plan Portfolio. The Platinum plan offers a much <b>smaller out of pocket expense</b> when it comes time for doctor’s visits, labs, prescriptions, etc. in exchange for a higher premium per month. This plan features an attractive <b>$0 deductible with minimal copays of $15-$30</b> for common services. And to top it off, if the worst should happen, the most you would have to pay in a year for covered services would be <b>$4,500 for an individual or $9,000 for a family</b>. This is the best of any plan offered on the Covered California Exchange. The only exception to this would be if you qualify for the <a href="${getBaseUrl('/covered-california/plans/silver-87')}">Silver 87</a> or <a href="${getBaseUrl('/covered-california/plans/silver-94')}">Silver 94</a> plans.</p>`,
    imageUrl: '/assets/img/bg/platinum_a.webp',
    redirectText: 'Get a Free Plan Quote',
    redirectUrl: getQuoteUrl()
  },
  textSection: {
    visible: true,
    title: `Is the Platinum Plan Right for Me?`,
    subtitle: `It may be perfect for you:`,
    contentMode: 'twoColumns',
    subContent: [
      `<p>You have health needs that require a lot of services.</p>`,
      `<p>You don't want a deductible, and you want the lowest out-of-pocket maximum in the event of a worst case scenario.</p>`,
      `<p>This plan may also be a good fit if you have a planned event, such as having a baby or if you know you will need a particular surgery or procedure that will be expensive. Once you utilize the plan for your coverage needs, you can always switch to another level of coverage if desired, during the next year's open enrollment. The open enrollment typically takes place at the end of the calendar year and lasts 1-2 months. So keep this in mind as you plan.</p>`,
      `<p>If you want benefits similar to what you had through an employer. Oftentimes, the Platinum is the closest. To compare your previous plan to this one, feel free to click on the link above to get a free plan quote and view the benefit details.</p>`,
      `<p>For other people it is enough that the Platinum level of coverage offers peace of mind that you just can't put a price tag on.</p>`,
      `<p>In comparing other metal plans ask yourself, "Does the premium justify the amount of times I would use the coverage?" If not, it may be to your interest to take a little more risk, pay less for either a <a href="${getBaseUrl('/covered-california/plans/silver')}">Silver</a> or <a href="${getBaseUrl('/covered-california/plans/gold-80')}">Gold</a> plan, and put the premium savings to use by saving it for unexpected events that may require a little extra money up front.</p>`,
    ]
  },
  metalPlansSection: {
    visible: true,
    title: `What Types of Health Plans are Available`,
    redirectUrl: '/covered-california/plans'
  },
  googleReviewsSection: {
    visible: true,
    title: `Customer Reviews`,
    imageUrl: '/assets/img/bg/guys_working.webp'
  },
  certifiedBanner: {
    visible: true,
  },
  twoColumnSection: {
    visible: true,
    title: `A Little More to Think About`,
    content: `<p>We have quite a few people that are concerned with good Emergency Room coverage that <b>doesn't break the bank</b>. For many people the Platinum 90 is what they are looking for.</p><br> <p>Additionally, if you are considering a <b>PPO</b> Platinum plan, be aware that if you receive care "Out of Network" you will have much higher costs. PPO plans have a separate "Out of Network" deductible which can be thousands of dollars. Only after you reach that deductible, will you begin to see discounts of around 50% of reasonable and customary charges. Check your plan benefit details for more information so that you aren't caught by surprise.</p>`,
    imageUrl: '/assets/img/bg/platinum_b.png'
  },
  finalBanner: {
    visible: true,
    title: `A LITTLE MORE TO THINK ABOUT`,
    imageUrl: '/assets/img/bg/child_and_doctor_fadedx.png',
    redirectUrl: '/covered-california/plans/hmo-or-ppo'
  }
};
