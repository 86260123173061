<script lang="ts" setup>
import { computed } from 'vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();
const props = withDefaults(defineProps<{
  img: string,
  width: string,
  height: string,
  alt: string,
  reverse?: boolean,
}>(), {
  alt: '',
  reverse: false,
});

const directionClass = computed(() => props.reverse ? 'lg:flex-row-reverse' : 'lg:flex-row');
const orientationClass = computed(() => props.reverse ? 'reverse lg:right-0' : 'normal lg:left-0');
</script>

<template>
  <div class="flex flex-col bg-white lg:h-[630px] 3xl:h-[700px]" :class="directionClass">
    <div
      class="lg:flex-1 bg-no-repeat bg-image-container bg-cover h-80 sm:h-96 md:h-100 lg:h-auto"
      :style="`background-image:url('${getCdnUrl(img)}');`"
      >

    </div>
    <div class="lg:flex-1 relative pb-10 lg:pb-0">
      <div class="bg-white shadow-2xl border border-gray-100 p-10 lg:w-[1010px] lg:absolute bg-container right-0 mx-6 -mt-12 lg:m-0" :class="orientationClass">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
  @media(min-width: 1024px) {
    .bg-container.normal {
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .bg-container.reverse {
      top: 50%;
      transform: translate(50%, -50%);
    }
  }
  @media(min-width: 1280px) {
    .bg-container.normal {
      transform: translate(-30%, -50%);
    }
    .bg-container.reverse {
      transform: translate(30%, -50%);
    }
  }
  @media(min-width: 1536px) {
    .bg-container.normal {
      transform: translate(-20%, -50%);
    }
    .bg-container.reverse {
      transform: translate(20%, -50%);
    }
  }
</style>
