<script lang="ts" setup>
import { inject } from 'vue';
import { usePlocState } from '@hfc/ui-core';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import AnimatedPlaceholder from '@/shared/infrastructure/components/AnimatedPlaceholder.vue';
const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);
pageStore.getBySlug('benjiman2');

</script>
<template>
  <div class="w-60">
    <div v-if="pageState.currentPageLoaded">
      <h1 class="h-9 text-3xl font-bold underline">{{ pageState.currentPage?.getTitle() }}</h1>
      <p class="h-6">{{ pageState.currentPage?.getContent() }}</p>
    </div>
    <div v-else class="animate-pulse">
      <AnimatedPlaceholder class="mb-2 h-9 rounded-lg" />
      <AnimatedPlaceholder class="mb-2 h-9 rounded-lg" />
    </div>
  </div>
</template>
