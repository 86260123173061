<script lang="ts" setup>
import { computed, ref } from 'vue';
import { viewportSize, VIEWPORT_NAMES } from '@hfc/ui-core';
import { TabGroup, TabList, Tab, TabPanels, TabPanel, Disclosure, DisclosureButton, DisclosurePanel, } from '@headlessui/vue';

const { viewport } = viewportSize();

interface TabItem {
  component: unknown,
  text: string
}

const props = withDefaults(defineProps<{
  tabs: Array<TabItem>,
  allowMultipleOpen: boolean,
}>(), {
  allowMultipleOpen: true
});

const defaultTabClass = 'font-bold border-b-2 border-blue-100 text-left flex-1 px-5 first:rounded-tl-3xl last:rounded-bl-3xl';
const prevOpenedDisclosureCloseFunction = ref();
const desktopMode = computed(() => viewport.value.name !== VIEWPORT_NAMES.ALL && viewport.value.name !== VIEWPORT_NAMES.SM);

const onDisclosureClick = (closeFunction: () => void, wasOpen: boolean):void => {
  if (props.allowMultipleOpen) {
    return;
  }

  if (!wasOpen && prevOpenedDisclosureCloseFunction) {
    prevOpenedDisclosureCloseFunction.value();
  }
  prevOpenedDisclosureCloseFunction.value = wasOpen ? null : closeFunction;
}

const getTabCssClass = (selected:boolean):string => {
  const selectedClass = selected ? 'bg-blue text-white hover:bg-blue-700' : 'hover:bg-blue-200';

  return `${selectedClass} ${defaultTabClass}`;
}
</script>

<template>
  <div class="flex bg-white rounded-3xl" v-if="desktopMode">
    <TabGroup vertical>
      <TabList class="flex flex-col text-gray-600 w-1/4">
        <Tab v-slot="{ selected }" as="template" v-for="(tab, i) in tabs" :key="i">
          <button type="button" :class="getTabCssClass(selected)" style="min-height: 7rem">
            {{tab.text}}
          </button>
        </Tab>
      </TabList>
      <TabPanels class="flex-1 text-center w-3/4 border-l-2 border-blue-100 p-2">
        <TabPanel v-for="(tab, i) in tabs" :key="i" v-slot="{ selected }" static>
          <div v-show="selected">
            <component :is="tab.component" class="p-8 text-left"/>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
  <div v-else class="flex flex-col px-5">
    <Disclosure v-slot="{ open, close }" v-for="(tab, i) in tabs" :key="i" :default-open="i === 0">
      <DisclosureButton
        :class="open ? 'bg-blue text-white' : 'bg-white text-gray-600'"
        class="first:rounded-t-3xl last:rounded-b-3xl font-bold text-left px-5 w-full"
        @click="onDisclosureClick(close, open)">
        <span :class="open ? '' : 'border-b border-t border-blue-100'" class="flex items-center justify-between h-20">
          {{tab.text}}
          <font-awesome-icon :icon="`arrow-${open ? 'up' : 'down'}`" :class="open ? 'text-white' : 'text-blue'" class="h-4 w-4 mx-3" />
        </span>
      </DisclosureButton>
      <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-out"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
        <DisclosurePanel class="text-gray-600 bg-white last:rounded-b-3xl p-5 overflow-hidden" static v-show="open">
          <component :is="tab.component"/>
        </DisclosurePanel>
      </transition>
    </Disclosure>
  </div>
</template>
