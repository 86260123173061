import { createVueApp, SentryTracking } from '@hfc/ui-core';
import { appConfig } from '../AppConfig';
import App from '@/App.vue';
import '@hfc/ui-core/dist/index.css';
import '@/shared/infrastructure/main.css';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import { PostStore } from '@/blog/infrastructure/store/PostStore';
import VueLazyLoad from 'vue3-lazyload';

const app = await createVueApp(App, appConfig);
const sentry = new SentryTracking(appConfig.tracking, app);

sentry.init();

app.provide('PageStore', new PageStore());
app.provide('PostStore', new PostStore());
app.use(VueLazyLoad, {
  log: false,
  observerOptions: {
    rootMargin: '300px', // This is to start calling the img 100px before it appears
    threshold: 0
  }
});

app.mount('#app');
