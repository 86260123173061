<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import { MetalPlanData } from '@/page/domain/MetalPlanData';
import { MetalPlanPageData } from '@/page/domain/MetalPlanPageData';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import MetalTierCard from '@/page/infrastructure/components/metalPlans/MetalTierCard.vue';

const { getCdnUrl } = UseUrlHelper();

defineProps<{
  pageData: Partial<MetalPlanPageData>;
  plan: MetalPlanData;
}>();
</script>
<template>
  <div class="w-full py-16">
    <div class="flex flex-col max-w-7xl mx-auto px-6 lg:px-3">
      <TextAndImage div-class="py-2 lg:py-12" text-class="flex flex-col lg:w-3/5 order-1" image-class="flex flex-1 order-2" :content-direction="pageData.contentDirection">
        <template #text>
          <div class="flex flex-col mb-8" v-html="pageData.content">
          </div>
          <div v-if="pageData.imageUrl">
            <img class="rounded" :src="getCdnUrl(pageData.imageUrl)" />
          </div>
        </template>
        <template #image>
          <div v-if="plan" class="flex flex-row w-full mt-10 lg:mt-0">
            <MetalTierCard :plan="plan" :highlight="true" />
          </div>
        </template>
      </TextAndImage>
      <div v-if="pageData.redirectUrl" class="flex justify-center mt-12 lg:mt-0">
        <PrimaryButton :href="pageData.redirectUrl" size="xl" class="text-xl font-semibold">{{ pageData.redirectText }}</PrimaryButton>
      </div>
    </div>
  </div>
</template>