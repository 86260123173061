<script lang="ts" setup>
import { PrimaryButton } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getQuoteUrl } = UseUrlHelper();
</script>

<template>
  <div class="md:px-12">
    <h4 class="text-blue text-xl font-bold pb-6 md:pb-10">Where Can I Enroll?</h4>
    <p class="text-gray-500 text-left pb-5">Health for California gives you quick and instant access to quotes. You’ll find affordable Covered California individual, family and small business plans.</p>
    <p class="text-gray-500 text-left">Get a quote in 30 seconds. Apply in 5-10 minutes. Our process is fast, easy and free. Plans start at $0 per month. No contact information is required. Find the best quotes from multiple providers in your area.</p>
    <div class="flex py-5">
      <PrimaryButton :href="getQuoteUrl()">
        Get My Free Quote Now! <hfc-icon icon="arrow-right" class="text-white ml-1 md:ml-5" />
      </PrimaryButton>
    </div>
  </div>
</template>
