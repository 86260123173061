import { GlobalSetting } from '@/page/domain/GlobalSetting';
import { SettingsRepository } from '@/page/domain/SettingsRepository';
import { UseCase } from '@/shared/application/UseCase';
import { SfApiResponse } from '@hfc/ui-core';

export class SettingsUseCase implements UseCase<void, SfApiResponse<GlobalSetting>> {

  private repository: SettingsRepository;

  public constructor (repository: SettingsRepository) {
    this.repository = repository;
  }

  public async run (): Promise<SfApiResponse<GlobalSetting>> {
    return await this.repository.getSettings();
  }

}
