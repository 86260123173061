import BlogContent from '@/blog/infrastructure/views/BlogContent.vue';
import CategoryContent from '@/blog/infrastructure/views/CategoryContent.vue';
import PostContent from '@/blog/infrastructure/views/PostContent.vue';

export const blogRouter = [
  {
    path: '/blog',
    name: 'Blog',
    component: BlogContent
  },
  {
    path: '/blog/category',
    name: 'Category',
    component: CategoryContent
  },
  {
    path: '/blog/category/post/:id',
    name: 'Post',
    component: PostContent,
  }
];
