export class Page {

  private title: string;
  private content: string;

  public constructor ({ title, content }: { title: string, content: string}) {
    this.title = title;
    this.content = content;
  }

  public getTitle (): string {
    return this.title;
  }

  public getContent (): string {
    return this.content;
  }

}
