import axios, { AxiosResponse, AxiosError } from 'axios';
import { SettingsRepository } from '@/page/domain/SettingsRepository';
import { GlobalSetting } from '@/page/domain/GlobalSetting';
import { SfApiResponse, RawSfError } from '@hfc/ui-core';

export class SettingsApi implements SettingsRepository {

  public async getSettings(): Promise<SfApiResponse<GlobalSetting>> {
    try {
      const axiosResponse: AxiosResponse = await axios.get(
        `${import.meta.env.VITE_APP_LEGACY_API_URL}/settings/frontend`,
        {
          headers: { 'Authorization-Api': import.meta.env.VITE_APP_HFC_API_SECRET_KEY }
        }
      );
      return {
        success: true,
        data: axiosResponse.data.data
      };
    } catch (error) {
      return {
        success: false,
        error: error as AxiosError<RawSfError>
      };
    }
  }
}
