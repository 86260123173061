<template>
<svg xmlns="http://www.w3.org/2000/svg" width="43.69" height="54.281" viewBox="0 0 43.69 54.281">
  <g id="Group_181" data-name="Group 181" transform="translate(-382 -304)">
    <g id="Group_180" data-name="Group 180" transform="translate(382 304)">
      <g id="Group_179" data-name="Group 179" transform="translate(0)">
        <g id="Group_168" data-name="Group 168" transform="translate(0.662 0.662)">
          <g id="Group_164" data-name="Group 164" transform="translate(0 39.604)">
            <path id="Path_933" data-name="Path 933" d="M430.366,682.315H388c0-7.935,6.17-10.433,10.583-13.3a14.71,14.71,0,0,0,10.6,4.475,14.55,14.55,0,0,0,10.521-4.528C424.117,671.848,430.366,674.328,430.366,682.315Z" transform="translate(-388 -668.961)" fill="#f8d14b"/>
          </g>
          <g id="Group_165" data-name="Group 165" transform="translate(6.178)">
            <path id="Path_934" data-name="Path 934" d="M474.009,323.239a23.513,23.513,0,0,1-.477,5.887,1.747,1.747,0,0,0-.521-.591c-1.042,0-1.6-9.374-7.829-11.033-1.6-.424-3.866.441-6.178.441s-4.58-.865-6.178-.441c-6.232,1.659-6.787,11.033-7.829,11.033a1.747,1.747,0,0,0-.521.591,23.513,23.513,0,0,1-.477-5.887c0-6.346,6.452-13.239,15-13.239a15.587,15.587,0,0,1,9.1,2.922,2.616,2.616,0,0,0,1.508.476,2.72,2.72,0,0,0,1.139-.255,2.647,2.647,0,0,0,1.491-2.207s2.577,2.524-.521,5.622A11.772,11.772,0,0,1,474.009,323.239Z" transform="translate(-444 -310)" fill="#979faf"/>
          </g>
          <g id="Group_166" data-name="Group 166" transform="translate(6.178 7.385)">
            <path id="Path_935" data-name="Path 935" d="M452.773,403.1a12.576,12.576,0,0,1-5.587-8.147S444,394.709,444,390.737a3.92,3.92,0,0,1,.477-2.048c-.009-.009,0-.009,0-.009a1.747,1.747,0,0,1,.521-.591c1.042,0,1.6-9.374,7.829-11.033,1.6-.424,3.866.441,6.178.441s4.58-.865,6.178-.441c6.232,1.659,6.787,11.033,7.829,11.033a1.747,1.747,0,0,1,.521.591s.009,0,0,.009a3.92,3.92,0,0,1,.477,2.048c0,3.972-3.186,4.218-3.186,4.218a12.544,12.544,0,0,1-5.587,8.138,11.907,11.907,0,0,1-12.464.009Z" transform="translate(-444 -376.94)" fill="#fff"/>
          </g>
          <g id="Group_167" data-name="Group 167" transform="translate(10.583 33.54)">
            <path id="Path_936" data-name="Path 936" d="M505.043,620.064a14.55,14.55,0,0,1-10.521,4.528,14.71,14.71,0,0,1-10.6-4.475c2.453-1.589,4.368-3.292,4.368-6.108A11.907,11.907,0,0,0,500.754,614C500.754,616.789,502.624,618.492,505.043,620.064Z" transform="translate(-483.922 -614)" fill="#fff"/>
          </g>
        </g>
        <g id="Group_178" data-name="Group 178">
          <g id="Group_169" data-name="Group 169" transform="translate(6.655 18.629)">
            <path id="Path_937" data-name="Path 937" d="M442.981,474.675a.662.662,0,0,1-.569-1,2.365,2.365,0,0,1,.75-.822l.681,1.135.017-.011a1.222,1.222,0,0,0-.308.371A.662.662,0,0,1,442.981,474.675Z" transform="translate(-442.319 -472.854)" fill="#303e58"/>
          </g>
          <g id="Group_170" data-name="Group 170" transform="translate(6.178 19.135)">
            <path id="Path_938" data-name="Path 938" d="M453.667,494.93a12.519,12.519,0,0,1-6.579-1.855,13.259,13.259,0,0,1-5.78-8.128c-1.358-.318-3.307-1.588-3.307-4.8a4.618,4.618,0,0,1,.57-2.387.662.662,0,1,1,1.138.677,3.335,3.335,0,0,0-.383,1.709c0,3.321,2.47,3.55,2.575,3.558a.662.662,0,0,1,.595.514,11.868,11.868,0,0,0,5.289,7.73,11.246,11.246,0,0,0,11.767-.008,11.854,11.854,0,0,0,5.291-7.723.662.662,0,0,1,.594-.513,2.949,2.949,0,0,0,1.308-.533,3.507,3.507,0,0,0,1.267-3.025,3.335,3.335,0,0,0-.383-1.709.662.662,0,0,1,1.138-.677,4.618,4.618,0,0,1,.57,2.387c0,3.213-1.95,4.483-3.308,4.8a13.05,13.05,0,0,1-5.781,8.12A12.45,12.45,0,0,1,453.667,494.93Z" transform="translate(-438 -477.436)" fill="#303e58"/>
          </g>
          <g id="Group_171" data-name="Group 171" transform="translate(35.495 18.629)">
            <path id="Path_939" data-name="Path 939" d="M704.6,474.675a.662.662,0,0,1-.57-.325,1.221,1.221,0,0,0-.308-.371l.017.011.681-1.135a2.366,2.366,0,0,1,.75.822.662.662,0,0,1-.569,1Z" transform="translate(-703.723 -472.854)" fill="#303e58"/>
          </g>
          <g id="Group_172" data-name="Group 172" transform="translate(7.176 7.386)">
            <path id="Path_940" data-name="Path 940" d="M475.716,383.416c-.8,0-1.065-.843-1.6-2.524-.891-2.791-2.382-7.462-6.4-8.531a8.2,8.2,0,0,0-3.029.152,14.4,14.4,0,0,1-5.958,0,8.2,8.2,0,0,0-3.03-.152c-4.015,1.069-5.506,5.74-6.4,8.531-.536,1.68-.806,2.524-1.6,2.524a.662.662,0,0,1-.258-1.272,12.481,12.481,0,0,0,.6-1.655c.91-2.849,2.6-8.153,7.319-9.408a8.983,8.983,0,0,1,3.579.124,13.155,13.155,0,0,0,5.539,0,8.987,8.987,0,0,1,3.579-.124c4.716,1.256,6.409,6.559,7.319,9.408a12.486,12.486,0,0,0,.6,1.655.662.662,0,0,1-.258,1.272Z" transform="translate(-447.039 -370.942)" fill="#303e58"/>
          </g>
          <g id="Group_173" data-name="Group 173" transform="translate(33.902 6.558)">
            <path id="Path_941" data-name="Path 941" d="M691.752,377.339a.67.67,0,0,1-.634-.863,23.47,23.47,0,0,0,.448-5.695,11.161,11.161,0,0,0-2.165-6.3.662.662,0,0,1,1.082-.763,12.492,12.492,0,0,1,2.407,7.063,25.271,25.271,0,0,1-.484,6,.675.675,0,0,1-.209.381A.66.66,0,0,1,691.752,377.339Z" transform="translate(-689.281 -363.438)" fill="#303e58"/>
          </g>
          <g id="Group_174" data-name="Group 174" transform="translate(6.178)">
            <path id="Path_942" data-name="Path 942" d="M439.138,324.459a.66.66,0,0,1-.445-.172.675.675,0,0,1-.209-.381,25.277,25.277,0,0,1-.484-6,13.328,13.328,0,0,1,4.5-9.482,16.3,16.3,0,0,1,20.648-1.373,1.941,1.941,0,0,0,1.123.352,2.072,2.072,0,0,0,.856-.191,1.991,1.991,0,0,0,1.114-1.651.662.662,0,1,1,1.321.086A3.317,3.317,0,0,1,465.7,308.4l-.012.006a3.4,3.4,0,0,1-1.417.316,3.261,3.261,0,0,1-1.89-.6,14.882,14.882,0,0,0-8.719-2.8c-6.77,0-14.343,5.379-14.343,12.577a23.469,23.469,0,0,0,.448,5.695.665.665,0,0,1-.634.863Z" transform="translate(-438 -304)" fill="#303e58"/>
          </g>
          <g id="Group_175" data-name="Group 175" transform="translate(0 33.544)">
            <path id="Path_943" data-name="Path 943" d="M382.662,628.776a.662.662,0,0,1-.662-.662,11.711,11.711,0,0,1,2.988-8.154,25.345,25.345,0,0,1,6.047-4.556c.649-.393,1.262-.765,1.85-1.147,3.376-2.186,4.067-3.8,4.067-5.553a.666.666,0,0,1,.662-.666.658.658,0,0,1,.662.658c0,3.207-2.245,5.1-4.671,6.672-.6.393-1.226.77-1.884,1.168-4.128,2.5-8.4,5.089-8.4,11.577A.662.662,0,0,1,382.662,628.776Z" transform="translate(-382 -608.039)" fill="#303e58"/>
          </g>
          <g id="Group_176" data-name="Group 176" transform="translate(27.415 33.54)">
            <path id="Path_944" data-name="Path 944" d="M646.1,628.742a.662.662,0,0,1-.662-.662c0-6.492-4.269-9.078-8.4-11.579-.687-.416-1.336-.809-1.966-1.221-2.382-1.548-4.589-3.422-4.589-6.618a.662.662,0,1,1,1.324,0c0,2.422,1.594,3.954,3.988,5.509.612.4,1.252.788,1.929,1.2a25.324,25.324,0,0,1,6.046,4.555,11.714,11.714,0,0,1,2.989,8.157A.662.662,0,0,1,646.1,628.742Z" transform="translate(-630.484 -608)" fill="#303e58"/>
          </g>
          <g id="Group_177" data-name="Group 177" transform="translate(10.583 39.604)">
            <path id="Path_945" data-name="Path 945" d="M489.152,668.812a15.427,15.427,0,0,1-11.045-4.677.662.662,0,0,1,.952-.92,14.081,14.081,0,0,0,10.123,4.273,13.807,13.807,0,0,0,10.043-4.323.662.662,0,0,1,.958.914,15.12,15.12,0,0,1-11,4.733Z" transform="translate(-477.922 -662.961)" fill="#303e58"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
</template>