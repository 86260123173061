import { Page } from '@/page/domain/Page';
import { PageRepository } from '@/page/domain/PageRepository';
import { UseCase } from '@/shared/application/UseCase';
import { ServicesApiResponse } from '@hfc/ui-core';

export class FindPageUseCase implements UseCase<string, ServicesApiResponse<Page>> {

  private repository: PageRepository;

  public constructor (repository: PageRepository) {
    this.repository = repository;
  }

  public async run (slug: string): Promise<ServicesApiResponse<Page>> {
    return await this.repository.getBySlug(slug);
  }

}
