<script setup lang="ts">
import { MetalPlanPageData } from '@/page/domain/MetalPlanPageData';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';

const { getCdnUrl } = UseUrlHelper();

defineProps<{
  pageData: Partial<MetalPlanPageData>;
}>();
</script>
<template>
  <div class="w-full py-16" :class="pageData.backgroundColor ? pageData.backgroundColor : 'bg-white'">
    <div class="flex flex-col max-w-7xl mx-auto px-6 lg:px-3">
      <div class="flex flex-col w-full items-center">
        <h2 class="font-bold mb-2" v-html="pageData.title"></h2>
        <div v-html="pageData.subtitle"></div>
      </div>
      <TextAndImage div-class="py-2 lg:py-12" :content-direction="pageData.contentDirection">
        <template #text>
          <div class="flex flex-1 mt-10 lg:mt-0">
            <img v-if="pageData.imageUrl" class="rounded" :src="getCdnUrl(pageData.imageUrl)" />
          </div>
        </template>
        <template #image>
          <div class="flex flex-col h-full" v-html="pageData.content"></div>
        </template>
      </TextAndImage>
    </div>
  </div>
</template>