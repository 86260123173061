import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();
export const silver73PageData: Partial<MetalPlanPageSection> = {
  initialBanner: {
    visible: true,
    title: `Covered California`,
    subtitle: `Enhanced Plan`,
    subtitleColor: 'text-slate-400',
  },
  highlightSection: {
    visible: true,
    content: `<p>The Silver 73 is <b>one of four Silver health plans available</b> through Covered California. It is available to those who meet the Covered California eligibility requirements and whose income is a certain percentage of the <a href="${getBaseUrl('/federal-poverty-level')}">Federal Poverty Level</a>. Obtain a quote for <a href="${getQuoteUrl()}">Affordable Health Insurance for California</a> to see if you are eligible for a Silver 73 plan.</p><br> <p>The "73" refers to the average percentage that the plan will generally pay for health services. So under the Silver 73, enrollees may anticipate their health plan to pay <b>about 73% of the total covered expenses</b>. This plan has a $0 deductible. Also, it allows members to pay reduced fees of $35 to $85 for common services such as doctor visits, generic drugs, and other covered services. And in the event of a “worst-case scenario,” the most you would pay for covered, in-network services in a year would be the out-of-pocket maximum. See the illustration.</p>`,
    redirectText: 'See All Silver Plans',
    redirectUrl: '/covered-california/plans/silver'
  },
  infoBanner: {
    visible: true,
    title: `How does the Silver 73 compare to the Silver 70 Plan?`,
    content: `<p>The main difference between the <a class="text-white underline" href="${getBaseUrl('/covered-california/plans/silver-70')}">Standard Silver 70</a> and the Enhanced Silver 73 Plans<br> is the deductible size and maximum out-of-pocket amounts that the consumer would pay.</p>`,
  },
  textSection: {
    visible: true,
    title: `Is the Silver 73 Right for me?`,
    subtitle: `This plan may be a good fit if:`,
    contentMode: 'right',
    subContent: [
      `<p>You are relatively healthy.</p>`,
      `<p>If you want a plan with NO deductible at all and moderate copays (flat fees) or coinsurance (percentage of the bill) for your medical services.</p>`,
      `<p>You want more coverage than the Bronze 60 plan, but want a lower premium per month than the Gold Plan.</p>`,
    ],
  },
  metalPlansSection: {
    visible: true,
    title: `What Types of Health Plans are Available`,
    redirectUrl: '/covered-california/plans'
  },
  googleReviewsSection: {
    visible: true,
    title: `Customer Reviews`,
    imageUrl: '/assets/img/bg/guys_working.webp'
  },
  certifiedBanner: {
    visible: true,
  },
  twoColumnSection: {
    visible: true,
    title: `A Little More to Think About`,
    content: `<p>An individual may qualify for a Silver 73 Plan only if their income meets the qualifications and if they <a href="${getBaseUrl('/apply-for-health-insurance')}">enroll through the Covered California Exchange.</a></p> <p>Typically, adults with an <b>annual income of 200% to 250%</b> of the <a href="${getBaseUrl('/federal-poverty-level')}">Federal Poverty Level</a> may be eligible for a Covered California Silver 73 Plan. <b>Minors 18 and under do not qualify</b> and more than likely will have the option to enroll on Medi-Cal. Some parents choose to enroll their minors in a child-only plan if they prefer to not use Medi-Cal. These plans would be at full charge.</p>`,
    imageUrl: '/assets/img/bg/silver_73.png'
  },
  finalBanner: {
    visible: true,
    title: `A LITTLE MORE TO THINK ABOUT`,
    imageUrl: '/assets/img/bg/doctor_and_patient_faded.png',
    redirectUrl: '/child-only-quote'
  }
};
