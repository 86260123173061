import { MenuItem } from '@/shared/domain/MenuItem';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import MedicareButtons from '@/shared/infrastructure/components/heading/MedicareButtons.vue';

const { getEnrollmentAppUri } = UseUrlHelper();

export const medicarePreset: Array < MenuItem > = [
  {
    label: 'Find Medicare Plans',
    href: '',
    description: null,
    customClass: '',
    prefixImg: '',
    subMenu: [
      {
        label: 'Find Medicare Plans',
        href: '',
        description: null,
        customClass: '',
        subMenuSideComponent: MedicareButtons,
        prefixImg: '',
        subMenu: [
          {
            label: 'Parts A & B (Original Medicare)',
            href: '/medicare/original-medicare',
            description: null,
            customClass: '',
            prefixImg: '',
            subMenu: []
          },
          {
            label: 'Part C (Medicare Advantage)',
            href: '/medicare/medicare-advantage-part-c',
            description: null,
            customClass: '',
            prefixImg: '',
            subMenu: []
          },
          {
            label: 'Supplemental Plans',
            href: '/medicare/supplemental-plans',
            description: null,
            customClass: '',
            prefixImg: '',
            subMenu: []
          },
          {
            label: 'Part D (Prescription Drug Only)',
            href: '/medicare/prescription-drugs-part-d',
            description: null,
            customClass: '',
            prefixImg: '',
            subMenu: []
          },
          {
            label: 'Dental Coverage',
            href: getEnrollmentAppUri('/dental/quote-senior'),
            description: null,
            isAbsolute: true,
            customClass: '',
            prefixImg: '',
            subMenu: []
          },
          {
            label: 'Vision Coverage',
            href: getEnrollmentAppUri('/vision/quote-senior'),
            description: null,
            isAbsolute: true,
            customClass: '',
            prefixImg: '',
            subMenu: []
          }
        ]
      }
    ]
  },
  {
    label: 'Learn About Medicare',
    href: '',
    description: null,
    customClass: '',
    prefixImg: '',
    subMenu: [
      {
        label: 'Learn About Medicare',
        href: '',
        description: null,
        customClass: '',
        subMenuSideComponent: MedicareButtons,
        prefixImg: '',
        subMenu: [
          {
            label: 'What is Medicare?',
            href: '/medicare',
            description: null,
            customClass: '',
            prefixImg: '',
            subMenu: []
          },
          {
            label: 'Medicare Eligibility',
            href: '/medicare/eligibility',
            description: null,
            customClass: '',
            prefixImg: '',
            subMenu: []
          },
          {
            label: 'Senior Health Insurance Plans',
            href: '/medicare/senior-health-insurance',
            description: null,
            customClass: '',
            prefixImg: '',
            subMenu: []
          },
          {
            label: 'Medicare Consent Form',
            href: getEnrollmentAppUri('/medicare/consent'),
            description: null,
            isAbsolute: true,
            isBlank: true,
            customClass: '',
            prefixImg: '',
            subMenu: []
          }
        ]
      }
    ]
  }
];
