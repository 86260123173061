<script setup lang="ts">
import { CarrierList, PrimaryButton } from '@hfc/ui-core';
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl, getQuoteUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:NetworkInfo="{ carrierData }">
      <div v-if="carrierData" class="w-full" :class="carrierData.bgColor">
        <TextAndImage div-class="px-6" :content-direction="carrierData.contentDirection" :text-class="!carrierData.imageUrl ? 'w-full' : 'flex flex-col lg:w-3/5'">
          <template #text>
            <div class="flex flex-col h-full items-start justify-center mt-10 lg:mt-0" :text-class="!carrierData.imageUrl ? 'flex-1' : ''">
              <h2 class="mb-6" v-html="carrierData.title"></h2>
              <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.content"></div>
              <PrimaryButton v-if="carrierData.redirectUrl" class="text-sm mt-6 lg:text-md uppercase" size="lg" :href="getQuoteUrl()">{{ carrierData.redirectButtonText }}</PrimaryButton>
              <ImageSection v-if="carrierData.imageSection" class="mt-6" :content="carrierData.imageSection"></ImageSection>
            </div>
          </template>
          <template #image v-if="carrierData.imageUrl">
            <div class="flex flex-1 justify-center">
              <img class="flex max-h-96 rounded" :src="getCdnUrl(carrierData.imageUrl)">
            </div>
          </template>
        </TextAndImage>
      </div>
    </template>
  </CarrierPlan>
</template>
