import { PostAuthor } from '@/blog/domain/PostAuthor';
import { PostResponse } from '@/blog/domain/PostResponse';
import moment from 'moment';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();

export class Post {

  public title: string;
  public date: number;
  public id: number;
  public excerpt: string;
  public author: PostAuthor;
  public image: string;

  public constructor (postResponse: PostResponse) {
    this.title = postResponse.title;
    this.date = postResponse.date;
    this.id = postResponse.id;
    this.excerpt = postResponse.excerpt;
    this.author = postResponse.author;
    this.image = postResponse.image;
  }

  public get formattedDate (): string {
    return moment(this.date).format('MMM. Do, YYYY');
  }

  public get imageUrl (): string {
    return getCdnUrl(`/assets/img/${this.image}`);
  }

}
