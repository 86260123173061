export default [{
    search: 'OOP Max',
    content: "You pay $0 for covered benefits once you reach the Out-of-Pocket Maximum. The OOP Max resets every year on January 1st."
  },
  {
    search: 'HMO',
    content: "<strong>Health Maintenance Organization (HMO) Plans:</strong> Also, called managed care. A type of plan that focuses on coordinating your care with a network of providers. A primary care provider is required for each member to assist in coordination and will refer the member to see specialists. HMOs typically do not cover out-of-network costs (except in an emergency)."
  },
  {
    search: 'PPO',
    content: "<strong>Preferred Provider Organization (PPO) Plans:</strong> A type of plan that has a network of preferred providers from which you can choose. Your plan may assign a primary care provider to assist in coordination of care. However, the member may still coordinate their own care and can see any provider within the network without a referral. Members can also see providers outside the network, but at a higher cost."
  },
  {
    search: 'EPO',
    content: "<strong>Exclusive Provider Organization (EPO) Plans:</strong> A type of plan that has a network of exclusive providers from which you can choose. Your plan may assign a primary care provider to assist in coordination of care. However, the member may still coordinate their own care and can see any provider within the network without a referral. EPOs typically do not cover out of network costs (except in an emergency)."
  },
  {
    search: 'HSA',
    content: "<strong>Health Savings Account (HSA) Plans:</strong> These High Deductible Health Plans (HDHP) can be used with a Health Savings Account (HSA). An HSA allows you to set aside money on a pre-tax basis to pay for qualified medical expenses (deductibles, Co-Insurance, co-pays), helping reduce your taxes and overall health care costs. HSA funds roll over year to year if you don’t spend them. You can open an HSA through your bank or other financial institution."
  },
  {
    search: 'Yearly Deductible',
    content: "A deductible is the amount of money you will need to pay each year for some health care services before your health plan will begin to help pay for the costs. Silver, Gold, and Platinum plans have a $0 deductible. With Bronze plans you have access to many common services (like a doctor visit) without meeting the deductible first. With Minimum Coverage (catastrophic) plans, you get 3 free doctor visits before the deductible, and you pay for most services until you reach the deductible amount."
  },
  {
    search: 'Metal Tier',
    content: "Covered California health Insurance plans are sold in four levels of coverage: Platinum, Gold, Silver and Bronze. Plans in higher metal categories have higher monthly premiums, but when you need medical care, you pay less. Alternatively, you can choose to pay a lower monthly premium, and when you need medical care, you pay more. You can choose the level of coverage that best meets your health care needs and budget."
  },
  {
    search: 'Platinum Plans',
    content: "A Platinum level plan has the highest monthly premiums, but also the lowest out-of-pocket costs. This plan, on average, pays 90 percent of medical costs."
  },
  {
    search: 'Gold Plans',
    content: "A Gold level plan has high monthly premiums, but has low out-of-pocket costs. This plan, on average, pays 80 percent of medical costs."
  },
  {
    search: 'Silver Plans',
    content: "A Silver level plan offers a balance of moderate monthly premiums and out-of-pocket costs. This plan, on average, pays 70 percent of medical costs."
  },
  {
    search: 'Bronze Plans',
    content: "A Bronze level plan has low monthly premiums but high out-of-pocket costs. This plan, on average, pays 60 percent of medical costs."
  },
  {
    search: 'Minimum Coverage',
    content: "The Minimum Coverage Plans have low monthly premiums but higher out-of-pocket costs. Compared to Bronze Plans, the Minimum Coverage Plans have lower costs for doctor visits but a higher Out-of-Pocket Maximum."
  },
  {
    search: 'Annual Medical Deductible',
    content: "The drug (pharmacy) deductible is separate from the medical deductible. When your plan has a drug deductible, you pay full charge for your prescriptions until met. Then you receive discounts in the form of copays or Co-Insurance."
  },
  {
    search: 'Annual Drug Deductible',
    content: "The drug (pharmacy) deductible is separate from the medical deductible. When your plan has a drug deductible, you pay full charge for your prescriptions until met. Then you receive discounts in the form of copays or Co-Insurance."
  },
  {
    search: 'Annual Out-of-Pocket Maximum',
    content: "The Out-of-Pocket Maximum (OOP Max) is the most you will spend in any one calendar year.  Once met, all covered medical expenses are free for you, because your Insurance pays 100% for the rest of the year.  The OOP Max does not include monthly premiums, or non-covered services. Be aware PPO plans have an additional, separate out-of-network OOP Max of up to 5 times the regular OOP Max or more."
  },
  {
    search: 'Preventative Care Visit',
    content: "A preventative care visit includes in-network: annual physicals, well-baby-care, most immunizations, preventative colorectal screenings, and women’s services such as routine ob-gyn visits, pap smear, mammograms, contraceptives, pre-natal visits and the 1st post-natal visit."
  },
  {
    search: 'Urgent Care Visit',
    content: "An urgent care visit includes care for an illness, injury or condition serious enough that a reasonable person would seek care right away, but not so severe as to require an emergency room visit."
  },
  {
    search: 'Specialty Care Visit',
    content: "A specialty care visit includes in-network office visits to providers in specialized areas such as dermatologists, neurologists, or gastroenterologists."
  },
  {
    search: 'Lab Tests',
    content: "Copays for lab work and pathology tests to diagnose an illness are per visit or encounter. Preventative labs, such as blood pressure or cholesterol screenings, are free at an in-network provider."
  },
  {
    search: 'X-Rays',
    content: "Copays for x-rays are per visit or encounter.   "
  },
  {
    search: 'Imaging (CT/PET scans, MRIs)',
    content: "These higher cost imaging services charge a copay or Co-Insurance per visit or procedure."
  },
  {
    search: 'Emergency Room',
    content: "The emergency room copay is for treatment of an emergency medical condition at a hospital ER facility.  If you are admitted to the hospital, you are not charged for the ER fee."
  },
  {
    search: 'Hospitalization',
    content: "Hospitalization care is received when admitted as an in-patient to a hospital facility, usually overnight. Some plans require you to pay a deductible first. Plans that offer copays will help you save considerably verses plans that offer Co-Insurance.  Remember, you never pay more than your out-of-pocket maximum each year."
  },
  {
    search: 'Specialist Visit',
    content: "A visit to a doctor or other health care provider who delivers medical services in a specialized area, such as a dermatologist, neurologist or gastroenterologist."
  },
  {
    search: 'Other Practitioner Office Visit',
    content: "Office visits to health care providers who are non-specialist practitioners, such as nurse practitioners, physician assistants, acupuncturists and certified nurse midwives."
  },
  {
    search: 'Preventive Care/Screening/Immunization',
    content: "Coverage includes important and free preventive services, which can help you avoid illness and improve your health."
  },
  {
    search: 'In Network',
    content: "The plan’s primary provider network. Seeing health care providers that are in network will result in lower out-of-pocket costs."
  },
  {
    search: 'Out of Network',
    content: "Doctors who aren’t within a plan’s network(s) are considered out of network. Seeing doctors outside of a plan’s network can be very costly."
  },
  {
    search: 'Laboratory Tests',
    content: "Includes various lab tests, such as bloodwork."
  },
  {
    search: 'X-rays and Diagnostic Imaging',
    content: "Any x-ray test or ultrasound procedure used for a diagnosis."
  },
  {
    search: 'Tier 1 (Most Generic Drugs)',
    content: "Tier 1 includes most generic drugs and low-cost preferred brand drugs. Generic drugs are copies of brand-name drugs. They have the same dosage, safety, strength, administration method, quality, performance characteristics and intended use. Generic drugs usually cost less than brand-name drugs."
  },
  {
    search: 'Tier 2 (Preferred Brand Drugs)',
    content: "Tier 2 includes preferred brand drugs and some non-preferred generic drugs. Preferred brand drugs are brand-name drugs for which a generic alternative either does not exist or a generic has not yet been approved by the health plan. Each health plan determines which drugs are preferred brand drugs."
  },
  {
    search: 'Tier 3 (Non-Preferred Brand Drugs)',
    content: "Tier 3 includes non-preferred brand name drugs, which may be more expensive or newer than other drugs. Non-preferred brand drugs are determined by each health plan. Because these drugs often cost more, your copayment may be higher."
  },
  {
    search: 'Tier 4 (Specialty Drugs)',
    content: "Tier 4 includes specialty drugs, which are high-cost drugs used to treat complex or rare conditions, such as multiple sclerosis, rheumatoid arthritis, hepatitis C, and hemophilia. The drugs are often self-injected or administered in a physician’s office or through home health services."
  },
  {
    search: 'Maximum Cost per Prescription',
    content: "The maximum cost per prescription applies each time a prescription is filled, including each refill."
  },
  {
    search: 'Outpatient Facility Fee',
    content: "Fee for use of an outpatient care facility, ambulatory care center, or a hospital-owned medical office for outpatient services."
  },
  {
    search: 'Outpatient Surgery Physician/Surgical Services',
    content: "Fees charged by doctors and surgeons for providing outpatient surgical services."
  },
  {
    search: 'Outpatient Services Office Visits',
    content: "An office visit for outpatient services such as chemotherapy, radiation, infusion therapy and dialysis."
  },
  {
    search: 'Emergency Room Facility Fee',
    content: "Facility fee charged for treatment received at a hospital emergency department."
  },
  {
    search: 'Emergency Transportation',
    content: "Ambulance services for an emergency medical condition."
  },
  {
    search: 'Urgent Care',
    content: "Care for an illness, injury, or condition serious enough that a reasonable person would seek care right away, but not so severe as to require an emergency room visit."
  },
  {
    search: 'Emergency Room Professional Fee',
    content: "Fees charged by doctors and surgeons for providing emergency medical services."
  },
  {
    search: 'Inpatient Hospital Services',
    content: "Fees for use of a hospital facility, such as a hospital room, for an inpatient hospital stay."
  },
  {
    search: 'Inpatient Physician and Surgical Services',
    content: "Fees charged by doctors and surgeons for their inpatient services."
  },
  {
    search: 'Mental/Behavioral Health Outpatient Services',
    content: "Mental or behavioral health services that do not require an overnight stay in a facility. Mental or behavioral health outpatient services include assessment, treatment (individual medical evaluation and management, including medication management, individual and group therapy behavioral health counseling), and psychological testing for recipients of all ages."
  },
  {
    search: 'Mental/Behavioral Health Inpatient Facility Fee',
    content: "Facility fee charged for mental or behavioral health services that require an overnight stay in a facility. For example, the evaluation, stabilization and treatment for adults and adolescents who are having a psychiatric crisis or a temporary worsening of a chronic mental illness."
  },
  {
    search: 'Mental/Behavioral Health Inpatient Professional Fee',
    content: "Fees charged by medical professionals for providing inpatient mental or behavioral health services."
  },
  {
    search: 'Substance Use Disorder Outpatient Services',
    content: "Treatment for a substance use disorder that does not require an overnight stay in a facility."
  },
  {
    search: 'Substance Use Disorder Inpatient Facility Fee',
    content: "Facility fee for treatment for a substance use disorder that requires an overnight stay in a facility."
  },
  {
    search: 'Substance Use Disorder Inpatient Professional Fee',
    content: "Fees charged by medical professionals for providing inpatient substance use disorder health services."
  },
  {
    search: 'Prenatal Care',
    content: "Prenatal care is the health care you get while you are pregnant. Prenatal care can help keep the mother and baby healthy."
  },
  {
    search: 'Delivery and Maternity Care Inpatient Facility Fee',
    content: "Facility fee charged for childbirth and inpatient maternity care, which usually includes room and board costs in the hospital, nursery fee, laboratory fees and medications and supplies."
  },
  {
    search: 'Delivery and Maternity Care Inpatient Professional Fee',
    content: "Fees charged by medical professionals for childbirth and inpatient maternity care."
  },
  {
    search: 'Home Health Care Services',
    content: "Health care services a person receives at home."
  },
  {
    search: 'Outpatient Rehabilitation Services',
    content: "Services that help a person keep, get back, or improve skills and functioning for daily living that have been lost or impaired because the person was sick, hurt or disabled. These services may include physical and occupational therapy, speech-language pathology and psychiatric rehabilitation services."
  },
  {
    search: 'Habilitation Services',
    content: "Inpatient or outpatient health care services that help a person keep, learn or improve skills and functioning for daily living, such as therapy for a child who is not walking or talking at the expected age. These services may include physical and occupational therapy, speech-language pathology and other services for people with disabilities."
  },
  {
    search: 'Skilled Nursing Facility',
    content: "Services from licensed nurses where skilled care services are provided by technicians or therapists in your own home or in a nursing home."
  },
  {
    search: 'Durable Medical Equipment',
    content: "Equipment and supplies ordered by a health care provider for everyday or extended use. Coverage for durable medical equipment may include oxygen equipment, wheelchairs, crutches or blood testing strips for diabetics."
  },
  {
    search: 'Hospice Services',
    content: "Services to provide comfort and support for family members and persons in the last stages of a terminal illness."
  },
  {
    search: 'Acupuncture',
    content: "Acupuncture benefits are provided as part of treatment for comprehensive pain management or nausea."
  },
  {
    search: 'Rehabilitative Speech Therapy',
    content: "Services to keep, get back or improve a communication impairment, swallowing disorder, an expressive or receptive language disorder, or an abnormal delay in speech development."
  },
  {
    search: 'Rehabilitative Occupational and Rehabilitative Physical Therapy',
    content: "Services to keep, get back, improve or maintain a patient’s ability to perform daily living skills as well as improve musculoskeletal, neuromuscular and respiratory systems."
  },
  {
    search: 'Well Baby Visits and Care',
    content: "Routine doctor visits for comprehensive preventive health services that occur when a baby is young. Services include physical exam and measurements, vision and hearing screening and vaccinations."
  },
  {
    search: 'Allergy Testing',
    content: "Services such as blood tests and skin prick testing to help confirm or rule out allergies."
  },
  {
    search: 'Diabetes Education',
    content: "Health education programs for diabetes, which may include programs provided online and counseling over the phone, individual counseling when the office visit is solely for health education, or health education provided during an outpatient consultation or exam."
  },
  {
    search: 'Nutritional Counseling',
    content: "Consultations with a registered dietitian or other health professional to assess dietary intake and identify areas for diet modification."
  },
  {
    search: 'Eye Exam for Children',
    content: "Comprehensive exam of visual functions and prescription for corrective eyewear."
  },
  {
    search: 'Eyeglasses for Children',
    content: "Covers one pair of glasses per year."
  },
  {
    search: 'Child Filling - One Surface ',
    content: "A mixture of materials used to repair cavities on a single surface of a tooth."
  },
  {
    search: 'Child Dental Checkup',
    content: "A dental checkup includes a cleaning and oral exam, and x-rays when appropriate."
  },
  {
    search: 'Child Root Canal - Molar',
    content: "Dental procedure to fix a damaged nerve of a tooth by removing the nerve and filling it with suitable material."
  },
  {
    search: 'Child Medically Necessary Orthodontia',
    content: "A dental procedure used when it is determined that treatment of malocclusion (misalignment) and other neuromuscular and skeletal abnormalities of the teeth and surrounding structures is medically necessary. Care is medically necessary for the purpose of: controlling or eliminating infection, pain, and disease; and restoring facial configuration or function necessary for speech, swallowing or chewing."
  },
  {
    search: 'Adult Dental Checkup',
    content: "A dental checkup includes a cleaning and oral exam, and x-rays when appropriate."
  }
];
