<script setup lang="ts">
import { UseMetallicPlansStore } from '@/page/infrastructure/composition/UseMetallicPlansStore';
import MetalTierContent from '@/page/infrastructure/views/MetalTierContent.vue';
import TextSection from '@/page/infrastructure/components/metalPlans/TextSection.vue';

const props = defineProps<{
  planName: string;
}>();

const { metalPlanPageSections } = UseMetallicPlansStore(props.planName);
</script>
<template>
  <MetalTierContent v-if="metalPlanPageSections" :metal-plan-page-sections="metalPlanPageSections" :plan-name="planName">
    <template v-slot:afterMetalPlansSection="{ pageData }">
      <TextSection v-if="pageData.textSection" :pageData="pageData.textSection" />
    </template>
  </MetalTierContent>
</template>
