<script setup lang="ts">
import { computed } from 'vue';
import AccordionContent from '@/page/infrastructure/components/AccordionContent.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();

interface Question {
  answer: string;
  response: string;
}

const props = withDefaults(defineProps<{
  id: string;
  title: string;
  questions: Array<Question>;
  images: Array<string>;
  contentDirection: 'left' | 'right';
}>(), {
  contentDirection: 'left',
});

const questionSection = computed(() => props.contentDirection === 'left' ? 'lg:order-1' : 'lg:order-2');
const imageSection = computed(() => props.contentDirection === 'left' ? 'lg:order-2' : 'lg:order-1');

</script>
<template>
  <div class="flex flex-col lg:flex-row space-x-4">
    <div :id="id" class="flex flex-1 w-full flex-col lg:justify-center space-y-8" :class="questionSection">
      <h1 class="text-3xl font-medium">
        {{ title }}
      </h1>
      <AccordionContent v-for="question in questions">
        <template #title>
          {{ question.answer }}
        </template>
        <template #content>
          <p v-html="question.response" class="text-justify"></p>
        </template>
      </AccordionContent>
    </div>
    <div class="flex flex-row lg:w-[500px] mt-6 lg:mt-0" :class="imageSection">
      <div class="flex-1">
        <img :src="getCdnUrl(images[0])">
      </div>
      <div v-if="images.length > 1" class="flex-1">
        <img class="mt-16" :src="getCdnUrl(images[1])">
      </div>
    </div>
  </div>
</template>
