<script setup lang="ts">
import { PrimaryButton, BaseButton } from '@hfc/ui-core';
import MetalTierContent from '@/page/infrastructure/views/MetalTierContent.vue';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import TextSection from '@/page/infrastructure/components/metalPlans/TextSection.vue';
import ParagraphWithIcon from '@/page/infrastructure/components/ParagraphWithIcon.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import { UseMetallicPlansStore } from '@/page/infrastructure/composition/UseMetallicPlansStore';

const props = defineProps<{
  planName: string;
}>();

const { getBaseUrl, getCdnUrl } = UseUrlHelper();

const { metalPlanPageSections } = UseMetallicPlansStore(props.planName);
</script>
<template>
  <MetalTierContent v-if="metalPlanPageSections" :metal-plan-page-sections="metalPlanPageSections" :plan-name="planName">
    <template v-slot:afterTextSection="{ pageData }">
      <div v-if="pageData.metalPlanTier" class="w-full bg-blue py-16">
        <div class="flex flex-1 flex-col items-center justify-center max-w-7xl mx-auto relative space-y-6 px-6 lg:px-3">
          <h3 class="text-white font-bold" v-html="pageData.metalPlanTier.title"></h3>
          <div class="text-white text-center flex-col" v-html="pageData.metalPlanTier.subtitle"></div>
          <div class="flex flex-col lg:flex-row w-full justify-center space-y-10 lg:space-y-0 lg:space-x-10">
            <div class="flex flex-col lg:w-[500px] items-center bg-white rounded-xl px-10 py-6">
              <h4 class="text-red font-semibold mb-2">Bronze 60 HDHP/HSA Plan</h4>
              <div class="text-blue-500">Check out this plan for additional tax benefits.</div>
              <div class="flex h-full flex-col justify-end">
                <BaseButton class="text-red font-bold text-md" :ignore-styles="true" :href="getBaseUrl('/covered-california/plans/bronze-60-hsa')">
                  Learn More <hfc-icon icon="arrow-right" class="ml-1 md:ml-2 mt-2" />
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TextSection v-if="pageData.metalTierHighlight" :pageData="pageData.metalTierHighlight" />
    </template>
    <template v-slot:TwoColumnSection="{ pageData }">
      <div v-if="pageData" class="w-full py-16" :class="pageData.backgroundColor ? pageData.backgroundColor : 'bg-white'">
        <div class="flex flex-col max-w-7xl mx-auto px-6 lg:px-3">
          <div class="flex flex-col w-full items-center">
            <h2 class="font-bold mb-2" v-html="pageData.title"></h2>
            <div v-html="pageData.subtitle"></div>
          </div>
          <TextAndImage div-class="py-2 lg:py-12" :content-direction="pageData.contentDirection">
            <template #text>
              <div class="flex flex-col space-y-4 mt-6 lg:mt-0">
                <ParagraphWithIcon v-for="subContent in pageData.subContent" :text="subContent" icon="chevron-right" />
              </div>
              <div v-if="pageData.redirectUrl" class="flex mt-10">
                <PrimaryButton :href="pageData.redirectUrl" size="xl" class="text-xl font-semibold">{{ pageData.redirectText }}</PrimaryButton>
              </div>
            </template>
            <template #image>
              <div class="flex flex-1 mt-10 lg:mt-0">
                <img v-if="pageData.imageUrl" class="rounded" :src="getCdnUrl(pageData.imageUrl)" />
              </div>
            </template>
          </TextAndImage>
        </div>
      </div>
    </template>
  </MetalTierContent>
</template>
