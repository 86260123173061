import { CarrierPage } from '@/page/domain/CarrierPage';

export const cchpPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Chinese Community <br> Health Insurance Coverage`,
    content: `Find the Lowest Prices Available on Chinese Community Medical Insurance Plans or Get Discounts Through Our Covered California Options`
  },
  aboutInfo: {
    visible: true,
    title: 'About Chinese Community Health Insurance',
    content: `<p>Chinese Community Health Plan is diverse as it is unique. They are a non-profit health plan with an integrated delivery system with over 27,000 members. Their mission is to “…improve the health of our community by delivering high-quality, affordable healthcare through culturally competent and linguistically appropriate services.” The Chinese Community Health Plan took root over 30 years ago serving the Chinese-American community in San Francisco. Today they serve all consumers. They are proud to be one of five carriers within the San Francisco and San Mateo counties that offer coverage through the California State Exchange: Covered California. Over 60 percent of CCHP’s members enrolled through Covered California identify themselves as Chinese.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'Take Note',
    content: `<p>The California Department of Managed Health Care (DMHC) in 2014 awarded Chinese Community Health Plan the Bronze Award for the prevention of heart attacks, strokes and diabetic complications.</p><br>
    <p>Chinese Community Health Plan successfully completed an NCQA Certified HEDIS compliance audit in 2013.</p>`,
    imageUrl: '/assets/img/bg/doctor_desk.webp'
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>For 2016 L.A. Care's individual and family plans offered through Covered California will provide access to</p>`,
    redirectUrl: 'https://www.lacare.org/health-plans/la-care-covered',
    subContent: [{
        title: '42',
        content: 'Lorem Ipsum'
      },
      {
        title: '19',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,559',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,801',
        content: 'Lorem Ipsum'
      }
    ]
  },
   finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>Following suite with health care of today, CCHP offers a 24/7 nurse advice phone number so members can receive professional help when a doctor is not available.</p><br>
    <p>Language assistance is offered to members whose need assistance in traditional Chinese, Cantonese and Mandarin. Through community fairs and events CCHP provides informational seminars in English, English-Chinese, (Cantonese and Mandarin), and English-Spanish. You can find more information on this by viewing their Health Resource Center.</p>`,
    imageUrl: '/assets/img/bg/skating.webp'
  }
};
