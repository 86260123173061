import { CarrierPage } from '@/page/domain/CarrierPage';

export const westernPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Western Health Advantage <br> Health Insurance Coverage`,
    content: `Find the Lowest Prices Available on L.A. Care Medical Insurance Plans or Get Discounts Through Our Covered California Options`
  },
  aboutInfo: {
    visible: true,
    title: 'About Western Health Advantage',
    content: `<p>“We treat Individual and Family coverage the same as Group coverage. Members have access to the same network.” – Representative from Western Health Advantage”</p><br>

    <b>Type of Plan:</b> HMO<br>
    <b>Pro’s:</b> Great Service, High Quality Physician Network, Competitive Pricing<br>
    <b>Con’s:</b> Limited geographically to only some parts of Northern California, Limited to the approved network<br><br>

    <p>Top Competitor: Kaiser Permanente</p>`,
    textDirection: 'text-left',
  },
  sectionInfo: {
    visible: true,
    title: 'Take Note',
    content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
    imageUrl: '/assets/img/bg/doctor_desk.webp'
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>For 2016 L.A. Care's individual and family plans offered through Covered California will provide access to</p>`,
    redirectUrl: 'https://www.lacare.org/health-plans/la-care-covered',
    subContent: [{
        title: '42',
        content: 'Lorem Ipsum'
      },
      {
        title: '19',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,559',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,801',
        content: 'Lorem Ipsum'
      }
    ]
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
    imageUrl: '/assets/img/bg/skating.webp'
  }
};
