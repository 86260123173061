<template>
<svg xmlns="http://www.w3.org/2000/svg" width="83.57" height="61.791" viewBox="0 0 83.57 61.791">
  <g id="Group_162" data-name="Group 162" transform="translate(-3467.848 -452.001)">
    <g id="Group_161" data-name="Group 161" transform="translate(3467.848 452.001)">
      <g id="Group_160" data-name="Group 160" transform="translate(0 0)">
        <g id="Group_128" data-name="Group 128" transform="translate(0.568 0.568)">
          <g id="Group_104" data-name="Group 104" transform="translate(45.23 41.341)">
            <path id="Path_879" data-name="Path 879" d="M4115.231,1051.138a16.511,16.511,0,0,0,4.772-.535c-.033-.1-.068-.2-.091-.306a6.817,6.817,0,0,0,1.967-.535,5.514,5.514,0,0,0,1.273-.773c.022-.011.034-.022.057-.033a8.406,8.406,0,0,0,2.693-4.854h.012c.2.443,1.319,2.239,1.319,2.239a3.491,3.491,0,0,0,6.809,0,10.89,10.89,0,0,0,1.375-2h.011c.933,3.694,3.013,6.081,7.457,6.081a5.491,5.491,0,0,1-1.352-3.4,14.667,14.667,0,0,1,.978-5.115l2.2,1.023c4.717,2.955,4.978,6.321,4.978,7.491v5.3H4118.47a13.155,13.155,0,0,0-1.365-1c-.829-.545-1.694-1.035-2.455-1.535a5.974,5.974,0,0,1-2.16-2.045Z" transform="translate(-4112.49 -1041.907)" fill="#f8d14b"/>
          </g>
          <g id="Group_105" data-name="Group 105" transform="translate(67.463 23.189)">
            <path id="Path_880" data-name="Path 880" d="M4433.094,786.407a39.759,39.759,0,0,1,.511,5.569c0,2.126.683,5.1.683,8.161a13.308,13.308,0,0,1-1.056,4.421,14.656,14.656,0,0,0-.978,5.115,5.493,5.493,0,0,0,1.351,3.4c-4.444,0-6.523-2.387-7.457-6.081a31.031,31.031,0,0,1-.705-7.536v-3.013a8.321,8.321,0,0,0,1.41-1.478,10.431,10.431,0,0,0,2-4.5s2.036-.193,2.036-3.251c0-.171-.012-.317-.022-.455Z" transform="translate(-4425.443 -786.407)" fill="#ebedf0"/>
          </g>
          <g id="Group_106" data-name="Group 106" transform="translate(58.643 33.238)">
            <path id="Path_881" data-name="Path 881" d="M4310.107,930.845a31.031,31.031,0,0,0,.705,7.536h-.012a10.811,10.811,0,0,1-1.375,2,3.492,3.492,0,0,1-6.81,0s-1.113-1.8-1.317-2.239h-.012a32.451,32.451,0,0,0,.648-7.3l.181-2.854.013-.011a6.322,6.322,0,0,0,3.888,1.512,6.55,6.55,0,0,0,4.091-1.647Z" transform="translate(-4301.287 -927.845)" fill="#fff"/>
          </g>
          <g id="Group_107" data-name="Group 107" transform="translate(34.692 48.97)">
            <path id="Path_882" data-name="Path 882" d="M3974.7,1150.885a5.977,5.977,0,0,0,2.159,2.045,8.771,8.771,0,0,1-6.319,2.729h-.046a8.861,8.861,0,0,1-6.332-2.693,6.349,6.349,0,0,0,2.217-2.08,3.12,3.12,0,0,0,.41-1.592,7.133,7.133,0,0,0,7.491-.011l.011.011A3.124,3.124,0,0,0,3974.7,1150.885Z" transform="translate(-3964.162 -1149.282)" fill="#fff"/>
          </g>
          <g id="Group_108" data-name="Group 108" transform="translate(24.473 37.034)">
            <path id="Path_883" data-name="Path 883" d="M3828.094,998.424a12.592,12.592,0,0,0-1.75,1.352l-.161-.171h-4.638v-.2l-1.228-13.094,1.114-2.933,1.171,1.41a22.114,22.114,0,0,0,2.125-1.512s1.762-1.5,2.285-1.989a19.549,19.549,0,0,0-.716,5.046,29,29,0,0,1-1.058,7.957,16.53,16.53,0,0,0,4.774.535h2.74a6.359,6.359,0,0,1-2.217,2.08C3829.776,997.4,3828.912,997.878,3828.094,998.424Z" transform="translate(-3820.318 -981.282)" fill="#fff"/>
          </g>
          <g id="Group_109" data-name="Group 109" transform="translate(53.846 15.834)">
            <path id="Path_884" data-name="Path 884" d="M4252.813,690.582a3.434,3.434,0,0,0-.238-.988,1.044,1.044,0,0,0-.876-.614.3.3,0,0,1-.1-.012c-3.637-.272-9.515-3.137-11.7-6.092-.353,2.864-3.786,5.672-5.2,6.07a1.016,1.016,0,0,0-.647.557,2.786,2.786,0,0,0-.193.614,4.135,4.135,0,0,0-.091.92c0,3.058,2.022,3.251,2.022,3.251a10.6,10.6,0,0,0,2.013,4.5,8.27,8.27,0,0,0,1.6,1.625,6.325,6.325,0,0,0,3.888,1.512,6.552,6.552,0,0,0,4.091-1.647v-.012a8.308,8.308,0,0,0,1.411-1.478,10.45,10.45,0,0,0,2-4.5s2.035-.193,2.035-3.251C4252.835,690.866,4252.824,690.72,4252.813,690.582Z" transform="translate(-4233.772 -682.876)" fill="#fff"/>
          </g>
          <g id="Group_110" data-name="Group 110" transform="translate(41.069 52.617)">
            <path id="Path_885" data-name="Path 885" d="M4060.248,1200.626c.762.5,1.626.99,2.456,1.535,0,0-1.614,4.377-5.069,4.377-2.341,0-3.706-3.184-3.706-3.184A8.77,8.77,0,0,0,4060.248,1200.626Z" transform="translate(-4053.928 -1200.626)" fill="#fff"/>
          </g>
          <g id="Group_111" data-name="Group 111" transform="translate(46.287 38.045)">
            <path id="Path_886" data-name="Path 886" d="M4127.457,995.574a1.263,1.263,0,0,1-.092.466v-.033l.092-.49Z" transform="translate(-4127.365 -995.517)" fill="#fff"/>
          </g>
          <g id="Group_112" data-name="Group 112" transform="translate(43.718 34.646)">
            <path id="Path_887" data-name="Path 887" d="M4093.778,951.595a1.374,1.374,0,0,1-.432.58,1.323,1.323,0,0,1-1.762-1.967l2.352-2.454a.276.276,0,0,1,.193-.08.26.26,0,0,1,.262.273l-.011.079-.512,3.047-.09.49Z" transform="translate(-4091.209 -947.673)" fill="#f8d14b"/>
          </g>
          <g id="Group_113" data-name="Group 113" transform="translate(32.249 52.653)">
            <path id="Path_888" data-name="Path 888" d="M3938.547,1203.819s-1.375,3.184-3.717,3.184c-3.307,0-4.933-4.025-5.058-4.354.819-.546,1.682-1.024,2.443-1.523A8.861,8.861,0,0,0,3938.547,1203.819Z" transform="translate(-3929.772 -1201.126)" fill="#fff"/>
          </g>
          <g id="Group_114" data-name="Group 114" transform="translate(28.327 54.152)">
            <path id="Path_889" data-name="Path 889" d="M3897.45,1223.235a6.722,6.722,0,0,1,2.592,5.5h-25.474a6.65,6.65,0,0,1,2.171-5.126,12.562,12.562,0,0,1,1.751-1.352c.124.328,1.75,4.354,5.057,4.354,2.343,0,3.718-3.184,3.718-3.184h.045s1.365,3.184,3.706,3.184c3.456,0,5.07-4.377,5.07-4.377A13.114,13.114,0,0,1,3897.45,1223.235Z" transform="translate(-3874.568 -1222.235)" fill="#f8d14b"/>
          </g>
          <g id="Group_115" data-name="Group 115" transform="translate(22.939 31.657)">
            <path id="Path_890" data-name="Path 890" d="M3808.251,907.619a17.384,17.384,0,0,0-1.3,3.353c-.523.489-2.284,1.989-2.284,1.989a22.076,22.076,0,0,1-2.126,1.512l-1.171-1.41-2.648-3.184a10.175,10.175,0,0,0,6.933-4.285,3.955,3.955,0,0,0,.467.512Z" transform="translate(-3798.725 -905.595)" fill="#fff"/>
          </g>
          <g id="Group_116" data-name="Group 116" transform="translate(29.395 28.815)">
            <path id="Path_891" data-name="Path 891" d="M3912.857,886.511c-.057-.171-.1-.353-.148-.535-.113-.386-.193-.795-.284-1.2a33.442,33.442,0,0,1-.544-5.911c0-1.17.056-6.092-2.3-9.593a8.156,8.156,0,0,0-7.251-3.672c-2.523,0-3.182,1.057-3.182,1.057a4.546,4.546,0,0,0-1.421-.306c-2.115,0-3.842,1.739-5.059,4.115a17.42,17.42,0,0,0-1.3,3.354,19.568,19.568,0,0,0-.716,5.046,28.986,28.986,0,0,1-1.058,7.957,16.524,16.524,0,0,0,4.774.535h2.74a3.119,3.119,0,0,0,.409-1.592,6.8,6.8,0,0,1-1.069-.819,7.861,7.861,0,0,1-2.284-4.08,2.253,2.253,0,0,1-1.922-2.534c0-1.228.6-1.591.6-1.591,1.216,0,4.444-2.649,5.763-5.844a29.581,29.581,0,0,0,5.309,3.99,1.328,1.328,0,0,1,.375-.921l2.353-2.454a.275.275,0,0,1,.193-.08.26.26,0,0,1,.262.273l-.011.079-.512,3.047v.057a1.262,1.262,0,0,1-.09.466,1.366,1.366,0,0,1-.432.579,9.835,9.835,0,0,0,3.637.808s.591.363.591,1.591a2.247,2.247,0,0,1-1.909,2.534,7.775,7.775,0,0,1-2.285,4.08,7.207,7.207,0,0,1-1.08.808l.011.011a3.119,3.119,0,0,0,.41,1.592h2.74a16.5,16.5,0,0,0,4.772-.535C3912.915,886.715,3912.88,886.613,3912.857,886.511Z" transform="translate(-3889.6 -865.595)" fill="#ebedf0"/>
          </g>
          <g id="Group_117" data-name="Group 117" transform="translate(16.005 26.371)">
            <path id="Path_892" data-name="Path 892" d="M3708.065,833.884c2.967,0,3.388-.841,4.592-1.535a7.335,7.335,0,0,0,1.523-1.16v3.137a3.447,3.447,0,0,0,.818,2.149,7.752,7.752,0,0,1-13.867,0,3.447,3.447,0,0,0,.818-2.149v-3.137a7.361,7.361,0,0,0,1.523,1.16C3704.678,833.043,3705.1,833.884,3708.065,833.884Z" transform="translate(-3701.131 -831.189)" fill="#fff"/>
          </g>
          <g id="Group_118" data-name="Group 118" transform="translate(20.29 35.942)">
            <path id="Path_893" data-name="Path 893" d="M3766.741,969.091l-1.114,2.933h-3.058l-1.125-2.933,2.649-3.184Z" transform="translate(-3761.444 -965.907)" fill="#f8d14b"/>
          </g>
          <g id="Group_119" data-name="Group 119" transform="translate(20.188 42.058)">
            <path id="Path_894" data-name="Path 894" d="M3764.292,1052l1.227,13.094v.2h-5.513v-.2l1.228-13.094Z" transform="translate(-3760.006 -1052.001)" fill="#f8d14b"/>
          </g>
          <g id="Group_120" data-name="Group 120" transform="translate(0 34.112)">
            <path id="Path_895" data-name="Path 895" d="M3497.264,948.1l-1.228,13.094v.2h-20.188v-9.469c0-4.194-.023-8.161,6.569-9.753a31.853,31.853,0,0,0,5.98-2.023v.011a21.577,21.577,0,0,0,6.569,6.412l1.171-1.41Z" transform="translate(-3475.848 -940.157)" fill="#fff"/>
          </g>
          <g id="Group_121" data-name="Group 121" transform="translate(12.549 31.657)">
            <path id="Path_896" data-name="Path 896" d="M3659.058,914.473a21.575,21.575,0,0,1-6.569-6.412v-.011a9.668,9.668,0,0,0,3.456-2.455,10.226,10.226,0,0,0,6.934,4.285l-2.649,3.184Z" transform="translate(-3652.489 -905.595)" fill="#fff"/>
          </g>
          <g id="Group_122" data-name="Group 122" transform="translate(11.469)">
            <path id="Path_897" data-name="Path 897" d="M3638.047,474.539a1,1,0,0,0-.217.216,10.047,10.047,0,0,1-.545-4.046,7.948,7.948,0,0,1,4.593-7.651c1.194-1.762,3.057-3.057,6.876-3.057,7.174,0,11.47,2.933,11.47,10.094a13.583,13.583,0,0,1-.545,4.66,1.009,1.009,0,0,0-.216-.216c-3.638,0-1.694-6.888-6.116-6.888-2.3,0-3.148.763-4.593.763s-2.284-.763-4.592-.763C3639.74,467.651,3641.684,474.539,3638.047,474.539Z" transform="translate(-3637.284 -460.001)" fill="#f8d14b"/>
          </g>
          <g id="Group_123" data-name="Group 123" transform="translate(52.504 48.423)">
            <path id="Path_898" data-name="Path 898" d="M4217,1142.368a6.83,6.83,0,0,1-1.967.535c-.057-.171-.1-.353-.148-.535a6.8,6.8,0,0,0,3.388-.773A5.525,5.525,0,0,1,4217,1142.368Z" transform="translate(-4214.881 -1141.595)" fill="#ebedf0"/>
          </g>
          <g id="Group_124" data-name="Group 124" transform="translate(49.378 22.836)">
            <path id="Path_899" data-name="Path 899" d="M4173.178,800.683c0-1.17.057-6.092-2.3-9.593L4171.8,788c.558-2.66,1.125-4.955,1.478-6.558l2.16.239a4.137,4.137,0,0,0-.091.92c0,3.058,2.022,3.251,2.022,3.251a10.6,10.6,0,0,0,2.012,4.5,8.289,8.289,0,0,0,1.6,1.625l-.012.011-.181,2.854a32.45,32.45,0,0,1-.648,7.3,8.41,8.41,0,0,1-2.694,4.854c-.022.011-.034.022-.057.033a6.8,6.8,0,0,1-3.387.772c-.114-.386-.193-.795-.284-1.2A33.41,33.41,0,0,1,4173.178,800.683Z" transform="translate(-4170.881 -781.439)" fill="#ebedf0"/>
          </g>
          <g id="Group_125" data-name="Group 125" transform="translate(51.777 9.014)">
            <path id="Path_900" data-name="Path 900" d="M4225.519,600.414a1.044,1.044,0,0,0-.877-.614.291.291,0,0,1-.1-.012c-3.638-.272-9.514-3.137-11.7-6.092-.352,2.864-3.785,5.672-5.194,6.07a1.019,1.019,0,0,0-.647.557,2.808,2.808,0,0,0-.193.614l-2.16-.239c.125-.535.216-.989.284-1.352,1.785-9.958,7.4-12.471,9.958-11.789a7.285,7.285,0,0,1,3.4-.682c6.274,0,8.775,8.208,9.7,14.176l-2.228.351A3.4,3.4,0,0,0,4225.519,600.414Z" transform="translate(-4204.647 -586.876)" fill="#ebedf0"/>
          </g>
          <g id="Group_126" data-name="Group 126" transform="translate(32.043 34.112)">
            <path id="Path_901" data-name="Path 901" d="M3944.33,946a9.832,9.832,0,0,1-3.637-.808,1.33,1.33,0,0,1-2.137-1.046,29.6,29.6,0,0,1-5.309-3.99c-1.318,3.195-4.546,5.843-5.763,5.843,0,0-.6.363-.6,1.591a2.254,2.254,0,0,0,1.922,2.534,7.852,7.852,0,0,0,2.284,4.081,6.8,6.8,0,0,0,1.069.819,7.134,7.134,0,0,0,7.491-.011,7.268,7.268,0,0,0,1.08-.808,7.779,7.779,0,0,0,2.285-4.081,2.247,2.247,0,0,0,1.909-2.534C3944.921,946.363,3944.33,946,3944.33,946Z" transform="translate(-3926.881 -940.157)" fill="#fff"/>
          </g>
          <g id="Group_127" data-name="Group 127" transform="translate(11.469 7.649)">
            <path id="Path_902" data-name="Path 902" d="M3659.678,574.777a1.011,1.011,0,0,0-.216-.216c-3.638,0-1.694-6.888-6.116-6.888-2.3,0-3.148.763-4.593.763s-2.284-.763-4.592-.763c-4.422,0-2.478,6.888-6.115,6.888a1,1,0,0,0-.217.216,3.418,3.418,0,0,0-.545,2.069c0,3.444,2.433,3.66,2.433,3.66a12.086,12.086,0,0,0,2.149,5.035,7.805,7.805,0,0,0,.772.853,7.36,7.36,0,0,0,1.523,1.16c1.206.694,1.625,1.535,4.592,1.535s3.389-.841,4.593-1.535a7.35,7.35,0,0,0,1.523-1.16,7.809,7.809,0,0,0,.773-.853,12.1,12.1,0,0,0,2.148-5.035s2.432-.215,2.432-3.66A3.415,3.415,0,0,0,3659.678,574.777Z" transform="translate(-3637.284 -567.673)" fill="#fff"/>
          </g>
        </g>
        <g id="Group_159" data-name="Group 159">
          <g id="Group_129" data-name="Group 129" transform="translate(32.043 34.112)">
            <path id="Path_903" data-name="Path 903" d="M3928.476,949.219a7.621,7.621,0,0,1-4.053-1.143,7.32,7.32,0,0,1-1.155-.885,8.4,8.4,0,0,1-2.364-4.019,2.837,2.837,0,0,1-2.023-3.012,2.3,2.3,0,0,1,.878-2.078.573.573,0,0,1,.294-.081c.861,0,3.952-2.377,5.238-5.492a.568.568,0,0,1,.923-.189,29.24,29.24,0,0,0,5.193,3.9.568.568,0,0,1-.566.986,29.984,29.984,0,0,1-4.839-3.534c-1.407,2.756-4.148,5.2-5.709,5.444a1.628,1.628,0,0,0-.275,1.042c0,1.78,1.258,1.954,1.4,1.968a.561.561,0,0,1,.5.439,7.26,7.26,0,0,0,2.117,3.791,6.207,6.207,0,0,0,.982.753,6.565,6.565,0,0,0,6.889-.009l.008,0a6.676,6.676,0,0,0,1-.745,7.27,7.27,0,0,0,2.11-3.785.568.568,0,0,1,.51-.44c.137-.013,1.384-.188,1.384-1.968a1.71,1.71,0,0,0-.254-1.026,10.651,10.651,0,0,1-3.619-.846.568.568,0,1,1,.427-1.053,9.264,9.264,0,0,0,3.424.767.57.57,0,0,1,.3.084,2.315,2.315,0,0,1,.861,2.075,2.835,2.835,0,0,1-2.01,3.011,8.315,8.315,0,0,1-2.368,4.023,7.845,7.845,0,0,1-1.165.872A7.582,7.582,0,0,1,3928.476,949.219Z" transform="translate(-3918.881 -932.158)" fill="#303e58"/>
          </g>
          <g id="Group_130" data-name="Group 130" transform="translate(44.82 48.981)">
            <path id="Path_904" data-name="Path 904" d="M4108.274,1154.248a.568.568,0,0,1-.568-.568,6.121,6.121,0,0,0-2.389-5.066,12.572,12.572,0,0,0-1.308-.958c-.419-.276-.849-.538-1.267-.792s-.81-.494-1.189-.743a6.6,6.6,0,0,1-2.346-2.245,3.7,3.7,0,0,1-.482-1.869.568.568,0,1,1,1.137,0,2.547,2.547,0,0,0,.334,1.308l.006.013a5.351,5.351,0,0,0,1.964,1.836l.007,0c.365.24.751.475,1.161.725s.864.527,1.295.81a13.778,13.778,0,0,1,1.419,1.041,7.261,7.261,0,0,1,2.792,5.936A.567.567,0,0,1,4108.274,1154.248Z" transform="translate(-4098.725 -1141.438)" fill="#303e58"/>
          </g>
          <g id="Group_131" data-name="Group 131" transform="translate(28.327 48.981)">
            <path id="Path_905" data-name="Path 905" d="M3867.137,1154.248a.568.568,0,0,1-.568-.568,7.246,7.246,0,0,1,2.35-5.54,13.149,13.149,0,0,1,1.826-1.412c.447-.3.905-.575,1.349-.844.387-.234.752-.454,1.1-.682a5.915,5.915,0,0,0,2.03-1.878l.005-.01a2.551,2.551,0,0,0,.334-1.308.568.568,0,1,1,1.137,0,3.713,3.713,0,0,1-.482,1.87,6.885,6.885,0,0,1-2.4,2.278c-.362.238-.736.464-1.131.7-.433.262-.88.532-1.307.817a12.164,12.164,0,0,0-1.673,1.29,6.087,6.087,0,0,0-2,4.716A.569.569,0,0,1,3867.137,1154.248Z" transform="translate(-3866.568 -1141.438)" fill="#303e58"/>
          </g>
          <g id="Group_132" data-name="Group 132" transform="translate(34.692 52.617)">
            <path id="Path_906" data-name="Path 906" d="M3963.063,1196.491a9.481,9.481,0,0,1-6.74-2.866.568.568,0,1,1,.817-.79,8.339,8.339,0,0,0,5.926,2.52h.042a8.148,8.148,0,0,0,5.908-2.553.569.569,0,0,1,.823.784,9.28,9.28,0,0,1-6.729,2.9Z" transform="translate(-3956.163 -1192.626)" fill="#303e58"/>
          </g>
          <g id="Group_133" data-name="Group 133" transform="translate(39.03 28.815)">
            <path id="Path_907" data-name="Path 907" d="M4026.728,880.489a.568.568,0,0,1,0-1.137,19.453,19.453,0,0,0,4.06-.371c-.026-.1-.05-.194-.074-.289-.093-.317-.164-.644-.231-.961-.02-.089-.039-.179-.059-.269,0-.005,0-.01,0-.015a34.085,34.085,0,0,1-.556-6.019v-.081c0-3.924-.736-7.017-2.2-9.195a7.653,7.653,0,0,0-6.78-3.421c-1.688,0-2.511.526-2.71.8l.01-.014-.964-.6c.084-.136.906-1.324,3.665-1.324a8.729,8.729,0,0,1,7.724,3.925c1.591,2.367,2.4,5.675,2.393,9.829v.08a33.046,33.046,0,0,0,.533,5.8l.059.268c.068.315.132.612.214.893a.189.189,0,0,1,.006.022c.044.177.086.344.136.494.005.017.01.035.015.052.015.067.041.146.068.228l.009.028a.569.569,0,0,1-.359.715A16.969,16.969,0,0,1,4026.728,880.489Z" transform="translate(-4017.22 -857.595)" fill="#303e58"/>
          </g>
          <g id="Group_134" data-name="Group 134" transform="translate(29.395 29.566)">
            <path id="Path_908" data-name="Path 908" d="M3886.94,890.3a16.975,16.975,0,0,1-4.956-.565.568.568,0,0,1-.358-.716,28.26,28.26,0,0,0,1.029-7.78,20.035,20.035,0,0,1,.736-5.2,17.86,17.86,0,0,1,1.34-3.467c1.458-2.849,3.435-4.42,5.563-4.42a4.99,4.99,0,0,1,1.632.347.568.568,0,0,1-.423,1.055h0a3.918,3.918,0,0,0-1.209-.266c-2.267,0-3.826,2.384-4.553,3.806a16.739,16.739,0,0,0-1.254,3.247,18.934,18.934,0,0,0-.7,4.894,31.057,31.057,0,0,1-.91,7.554,19.481,19.481,0,0,0,4.059.37.568.568,0,1,1,0,1.137Z" transform="translate(-3881.598 -868.157)" fill="#303e58"/>
          </g>
          <g id="Group_135" data-name="Group 135" transform="translate(41.069 54.153)">
            <path id="Path_909" data-name="Path 909" d="M4050.2,1219.752c-2.685,0-4.166-3.384-4.228-3.528a.569.569,0,0,1,1.045-.448c.014.033,1.25,2.84,3.183,2.84,3.013,0,4.521-3.966,4.537-4.006a.568.568,0,0,1,1.066.394,9.951,9.951,0,0,1-1.32,2.337A5.412,5.412,0,0,1,4050.2,1219.752Z" transform="translate(-4045.926 -1214.239)" fill="#303e58"/>
          </g>
          <g id="Group_136" data-name="Group 136" transform="translate(32.237 54.152)">
            <path id="Path_910" data-name="Path 910" d="M3927.238,1219.749c-3.58,0-5.344-4.106-5.566-4.665a.586.586,0,0,1-.071-.281.568.568,0,0,1,1.079-.249.49.49,0,0,1,.033.072,8.9,8.9,0,0,0,1.248,2.147,4.205,4.205,0,0,0,3.278,1.84c1.916,0,3.184-2.812,3.2-2.841a.568.568,0,1,1,1.043.451C3931.416,1216.366,3929.923,1219.749,3927.238,1219.749Zm-4.675-5.343h0Z" transform="translate(-3921.601 -1214.235)" fill="#303e58"/>
          </g>
          <g id="Group_137" data-name="Group 137" transform="translate(43.718 34.646)">
            <path id="Path_911" data-name="Path 911" d="M4085.107,945.584a1.887,1.887,0,0,1-1.367-3.2l2.354-2.456.008-.008a.848.848,0,0,1,.595-.246.827.827,0,0,1,.831.841.613.613,0,0,1-.005.079l-.011.079c0,.005,0,.01,0,.015l-.5,3.01a1.812,1.812,0,0,1-.123.655c0,.012-.01.024-.015.036a1.927,1.927,0,0,1-.608.806A1.9,1.9,0,0,1,4085.107,945.584Zm1.125-4.157-1.669,1.742a.756.756,0,0,0,1.01,1.12.781.781,0,0,0,.216-.27l.089-.477Z" transform="translate(-4083.209 -939.673)" fill="#303e58"/>
          </g>
          <g id="Group_138" data-name="Group 138" transform="translate(11.469 14.626)">
            <path id="Path_912" data-name="Path 912" d="M3641.323,673.447a6.145,6.145,0,0,1-4.231-1.19c-.213-.148-.413-.288-.645-.421a7.946,7.946,0,0,1-1.637-1.246,8.253,8.253,0,0,1-.824-.91,12.25,12.25,0,0,1-2.18-4.907,3.217,3.217,0,0,1-1.091-.571,4.276,4.276,0,0,1-1.431-3.566,4,4,0,0,1,.654-2.4l.014-.018a1.536,1.536,0,0,1,.358-.344l.609.96a.166.166,0,0,0,.021-.015.619.619,0,0,0-.089.095,2.911,2.911,0,0,0-.43,1.726,3.286,3.286,0,0,0,.959,2.642,2.006,2.006,0,0,0,.961.452.562.562,0,0,1,.5.453,11.406,11.406,0,0,0,2.041,4.8,7.329,7.329,0,0,0,.716.791l.01.009a6.79,6.79,0,0,0,1.406,1.07c.272.157.5.317.726.473a7,7,0,0,0,7.164,0c.223-.155.452-.315.726-.473a6.8,6.8,0,0,0,1.4-1.069l.01-.009a7.362,7.362,0,0,0,.718-.791,11.407,11.407,0,0,0,2.039-4.8.568.568,0,0,1,.507-.454,2.012,2.012,0,0,0,.954-.452,3.286,3.286,0,0,0,.959-2.642,2.915,2.915,0,0,0-.429-1.726.589.589,0,0,0-.09-.095.187.187,0,0,0,.022.015l.609-.96a1.536,1.536,0,0,1,.358.344l.014.018a3.992,3.992,0,0,1,.654,2.4,4.45,4.45,0,0,1-1.431,3.566,3.225,3.225,0,0,1-1.09.571,12.28,12.28,0,0,1-2.179,4.907,8.409,8.409,0,0,1-.825.91,7.916,7.916,0,0,1-1.635,1.245c-.232.134-.433.274-.645.422A6.143,6.143,0,0,1,3641.323,673.447Z" transform="translate(-3629.285 -657.87)" fill="#303e58"/>
          </g>
          <g id="Group_139" data-name="Group 139" transform="translate(12.231 7.649)">
            <path id="Path_913" data-name="Path 913" d="M3661.988,567.7c-2.153,0-2.727-1.911-3.232-3.6-.614-2.047-1.115-3.291-2.883-3.291a8.786,8.786,0,0,0-2.691.425,5.512,5.512,0,0,1-3.8,0,8.761,8.761,0,0,0-2.7-.426c-1.768,0-2.269,1.243-2.882,3.291-.506,1.686-1.08,3.6-3.233,3.6a.568.568,0,0,1,0-1.137c1.244,0,1.626-1.059,2.144-2.787.548-1.827,1.23-4.1,3.971-4.1a9.863,9.863,0,0,1,3.011.47,4.43,4.43,0,0,0,3.171,0,9.876,9.876,0,0,1,3-.469c2.742,0,3.423,2.274,3.972,4.1.518,1.728.9,2.787,2.143,2.787a.568.568,0,1,1,0,1.137Z" transform="translate(-3640.004 -559.673)" fill="#303e58"/>
          </g>
          <g id="Group_140" data-name="Group 140" transform="translate(29.054 26.371)">
            <path id="Path_914" data-name="Path 914" d="M3878.656,830.123a.566.566,0,0,1-.388-.153,4.559,4.559,0,0,1-.529-.58,4,4,0,0,1-.936-2.5v-3.137a.568.568,0,1,1,1.137,0v3.137a2.864,2.864,0,0,0,.7,1.8l.008.011a3.406,3.406,0,0,0,.4.438.568.568,0,0,1-.388.984Z" transform="translate(-3876.803 -823.188)" fill="#303e58"/>
          </g>
          <g id="Group_141" data-name="Group 141" transform="translate(0 26.371)">
            <path id="Path_915" data-name="Path 915" d="M3468.417,853.311a.568.568,0,0,1-.568-.568V843.23c0-4.213,0-8.57,7-10.261a30.591,30.591,0,0,0,5.868-1.983,9.072,9.072,0,0,0,3.256-2.3,2.862,2.862,0,0,0,.695-1.8v-3.137a.569.569,0,0,1,1.137,0v3.137a4.007,4.007,0,0,1-.94,2.5,10,10,0,0,1-3.66,2.618,31.693,31.693,0,0,1-6.087,2.061c-2.818.68-4.524,1.817-5.371,3.577a13.015,13.015,0,0,0-.765,5.58v9.513A.568.568,0,0,1,3468.417,853.311Z" transform="translate(-3467.849 -823.188)" fill="#303e58"/>
          </g>
          <g id="Group_142" data-name="Group 142" transform="translate(11.469)">
            <path id="Path_916" data-name="Path 916" d="M3652.247,467.892a.568.568,0,0,1-.532-.768,13.11,13.11,0,0,0,.509-4.461c0-3.284-.955-5.738-2.838-7.294-1.792-1.481-4.5-2.231-8.062-2.231-3.993,0-5.521,1.5-6.406,2.807a.57.57,0,0,1-.259.209c-.434.174-4.236,1.855-4.236,7.123a9.57,9.57,0,0,0,.509,3.847.568.568,0,0,1-1.064.4,10.561,10.561,0,0,1-.582-4.246,8.531,8.531,0,0,1,4.788-8.111,6.833,6.833,0,0,1,2.573-2.238,10.362,10.362,0,0,1,4.677-.927c7.987,0,12.037,3.587,12.037,10.662a13.949,13.949,0,0,1-.581,4.859A.569.569,0,0,1,3652.247,467.892Z" transform="translate(-3629.285 -452.001)" fill="#303e58"/>
          </g>
          <g id="Group_143" data-name="Group 143" transform="translate(22.939 31.657)">
            <path id="Path_917" data-name="Path 917" d="M3795.114,907.609a.568.568,0,0,1-.438-.205l-1.171-1.41-2.648-3.184a.569.569,0,0,1,.359-.927,9.611,9.611,0,0,0,6.544-4.047.568.568,0,0,1,.933.65,10.788,10.788,0,0,1-6.344,4.34l3.2,3.85a.569.569,0,0,1-.437.932Z" transform="translate(-3790.725 -897.594)" fill="#303e58"/>
          </g>
          <g id="Group_144" data-name="Group 144" transform="translate(16.005 31.657)">
            <path id="Path_918" data-name="Path 918" d="M3696.813,907.609a.568.568,0,0,1-.437-.931l3.2-3.851a10.851,10.851,0,0,1-6.344-4.338.568.568,0,0,1,.931-.652,9.66,9.66,0,0,0,6.545,4.048.568.568,0,0,1,.359.926l-2.648,3.184-1.171,1.409A.566.566,0,0,1,3696.813,907.609Z" transform="translate(-3693.131 -897.594)" fill="#303e58"/>
          </g>
          <g id="Group_145" data-name="Group 145" transform="translate(26.758 39.023)">
            <path id="Path_919" data-name="Path 919" d="M3845.059,1003.931a.568.568,0,0,1-.3-1.049,21.351,21.351,0,0,0,2.071-1.473.568.568,0,0,1,.716.883,22.534,22.534,0,0,1-2.181,1.551A.566.566,0,0,1,3845.059,1003.931Z" transform="translate(-3844.489 -1001.282)" fill="#303e58"/>
          </g>
          <g id="Group_146" data-name="Group 146" transform="translate(12.549 34.123)">
            <path id="Path_920" data-name="Path 920" d="M3651.625,939.861a.568.568,0,0,1-.3-.086,22.281,22.281,0,0,1-6.743-6.581.568.568,0,1,1,.948-.627,21.152,21.152,0,0,0,6.4,6.243.568.568,0,0,1-.3,1.051Z" transform="translate(-3644.488 -932.312)" fill="#303e58"/>
          </g>
          <g id="Group_147" data-name="Group 147" transform="translate(24.473 39.125)">
            <path id="Path_921" data-name="Path 921" d="M3814.114,1019.883a.567.567,0,0,1-.564-.515l-1.228-13.094a.554.554,0,0,1,.035-.255l1.114-2.933a.568.568,0,1,1,1.063.4l-1.067,2.809,1.215,12.963a.568.568,0,0,1-.512.619Z" transform="translate(-3812.319 -1002.719)" fill="#303e58"/>
          </g>
          <g id="Group_148" data-name="Group 148" transform="translate(20.188 39.125)">
            <path id="Path_922" data-name="Path 922" d="M3752.575,1019.882l-.054,0a.568.568,0,0,1-.513-.619l1.215-12.962-1.078-2.809a.568.568,0,0,1,1.062-.407l1.125,2.933a.556.556,0,0,1,.035.257l-1.228,13.094A.568.568,0,0,1,3752.575,1019.882Z" transform="translate(-3752.006 -1002.717)" fill="#303e58"/>
          </g>
          <g id="Group_149" data-name="Group 149" transform="translate(21.416 42.058)">
            <path id="Path_923" data-name="Path 923" d="M3772.913,1045.138h-3.057a.568.568,0,0,1,0-1.137h3.057a.568.568,0,1,1,0,1.137Z" transform="translate(-3769.288 -1044.001)" fill="#303e58"/>
          </g>
          <g id="Group_150" data-name="Group 150" transform="translate(53.846 15.834)">
            <path id="Path_924" data-name="Path 924" d="M4235.865,695.064a6.918,6.918,0,0,1-4.237-1.633,8.877,8.877,0,0,1-1.705-1.728,11.122,11.122,0,0,1-2.043-4.373c-.9-.3-2.108-1.31-2.108-3.724a4.712,4.712,0,0,1,.1-1.035,3.292,3.292,0,0,1,.229-.724.047.047,0,0,1,0-.011,1.58,1.58,0,0,1,1.012-.869c1.266-.357,4.472-3.053,4.784-5.592a.569.569,0,0,1,1.021-.268,15.028,15.028,0,0,0,5.1,3.883,17.817,17.817,0,0,0,6.179,1.979.551.551,0,0,1,.076.011h.02a1.6,1.6,0,0,1,1.358.947s0,.007,0,.011a3.973,3.973,0,0,1,.282,1.152v.014c.014.157.024.314.024.5,0,2.413-1.215,3.427-2.119,3.724a10.963,10.963,0,0,1-2.031,4.372,8.843,8.843,0,0,1-1.426,1.511.538.538,0,0,1-.074.073A7.129,7.129,0,0,1,4235.865,695.064Zm-7.445-8.772a.549.549,0,0,1,.5.459,10,10,0,0,0,1.9,4.259l.007.008a7.751,7.751,0,0,0,1.492,1.514l.011.008a5.792,5.792,0,0,0,3.532,1.387,5.964,5.964,0,0,0,3.657-1.458.594.594,0,0,1,.074-.073,7.776,7.776,0,0,0,1.313-1.376l.008-.011a9.871,9.871,0,0,0,1.891-4.255.549.549,0,0,1,.5-.463c.179-.024,1.524-.283,1.524-2.686,0-.143-.01-.267-.021-.4a2.83,2.83,0,0,0-.193-.811.471.471,0,0,0-.377-.275.861.861,0,0,1-.174-.018,18.816,18.816,0,0,1-6.559-2.1,18.094,18.094,0,0,1-4.757-3.347,10.244,10.244,0,0,1-5.329,5.405.454.454,0,0,0-.282.241,2.249,2.249,0,0,0-.152.485.25.25,0,0,0,0,.026,3.572,3.572,0,0,0-.077.792C4226.908,686.007,4228.243,686.268,4228.42,686.291ZM4244.353,681h0Z" transform="translate(-4225.771 -674.876)" fill="#303e58"/>
          </g>
          <g id="Group_151" data-name="Group 151" transform="translate(77.455 42.365)">
            <path id="Path_925" data-name="Path 925" d="M4563.63,1062.237a.568.568,0,0,1-.568-.568v-5.3c0-1.851-.818-4.57-4.712-7.009a.568.568,0,1,1,.6-.963,11.6,11.6,0,0,1,4.469,4.649,7.6,7.6,0,0,1,.776,3.323v5.3A.568.568,0,0,1,4563.63,1062.237Z" transform="translate(-4558.083 -1048.312)" fill="#303e58"/>
          </g>
          <g id="Group_152" data-name="Group 152" transform="translate(50.299 9.014)">
            <path id="Path_926" data-name="Path 926" d="M4202.425,607.919a.569.569,0,0,1-.568-.568,38.918,38.918,0,0,0-.383-4.736,29.8,29.8,0,0,1-.3-3.425,39.51,39.51,0,0,0-.5-5.481,29.522,29.522,0,0,0-2.779-9.142c-1.646-3.021-3.785-4.553-6.356-4.553a6.9,6.9,0,0,0-3.118.607.579.579,0,0,1-.428.055,4.566,4.566,0,0,0-3.553.859c-1.61,1.024-4.491,3.747-5.7,10.481-.088.472-.182.921-.289,1.377-.13.591-.286,1.26-.465,2.034-.309,1.331-.66,2.839-1.011,4.515a.568.568,0,1,1-1.112-.233c.354-1.688.706-3.2,1.017-4.539.179-.772.333-1.439.463-2.025a.022.022,0,0,1,0-.008c.1-.44.194-.874.278-1.326a21.761,21.761,0,0,1,2.566-7.322,11.542,11.542,0,0,1,3.639-3.914,6.07,6.07,0,0,1,4.241-1.048,8.484,8.484,0,0,1,3.469-.651c6.957,0,9.413,9.183,10.258,14.657a40.179,40.179,0,0,1,.517,5.656,29.078,29.078,0,0,0,.291,3.286,39.829,39.829,0,0,1,.392,4.876A.568.568,0,0,1,4202.425,607.919Z" transform="translate(-4175.849 -578.876)" fill="#303e58"/>
          </g>
          <g id="Group_153" data-name="Group 153" transform="translate(67.463 33.225)">
            <path id="Path_927" data-name="Path 927" d="M4418.012,923.822a.568.568,0,0,1-.569-.568v-3.013a.568.568,0,1,1,1.137,0v3.013A.568.568,0,0,1,4418.012,923.822Z" transform="translate(-4417.443 -919.673)" fill="#303e58"/>
          </g>
          <g id="Group_154" data-name="Group 154" transform="translate(67.463 36.238)">
            <path id="Path_928" data-name="Path 928" d="M4426.173,976.833c-4.283,0-6.9-2.13-8.007-6.51a30.814,30.814,0,0,1-.723-7.675.568.568,0,0,1,1.137,0,29.809,29.809,0,0,0,.687,7.394,7.923,7.923,0,0,0,2.239,4.143,6.139,6.139,0,0,0,3.529,1.453,5.635,5.635,0,0,1-.783-2.771,15.276,15.276,0,0,1,1.01-5.3c.054-.156.108-.312.163-.466a11.182,11.182,0,0,0,.862-3.768.569.569,0,0,1,1.137,0,12.2,12.2,0,0,1-.927,4.144c-.054.153-.108.307-.162.463a14.036,14.036,0,0,0-.947,4.929,4.957,4.957,0,0,0,1.2,3.012.569.569,0,0,1-.416.956Z" transform="translate(-4417.443 -962.079)" fill="#303e58"/>
          </g>
          <g id="Group_155" data-name="Group 155" transform="translate(55.949 36.238)">
            <path id="Path_929" data-name="Path 929" d="M4255.935,975.368a.568.568,0,0,1-.356-1.012,7.789,7.789,0,0,0,2.5-4.539,31.351,31.351,0,0,0,.635-7.17.568.568,0,1,1,1.136,0,32.389,32.389,0,0,1-.664,7.43,8.855,8.855,0,0,1-2.891,5.166A.566.566,0,0,1,4255.935,975.368Z" transform="translate(-4255.365 -962.079)" fill="#303e58"/>
          </g>
          <g id="Group_156" data-name="Group 156" transform="translate(59.291 33.384)">
            <path id="Path_930" data-name="Path 930" d="M4302.98,925.9h-.036a.569.569,0,0,1-.532-.6l.182-2.854a.569.569,0,0,1,1.135.072l-.181,2.854A.569.569,0,0,1,4302.98,925.9Z" transform="translate(-4302.411 -921.906)" fill="#303e58"/>
          </g>
          <g id="Group_157" data-name="Group 157" transform="translate(59.973 45.775)">
            <path id="Path_931" data-name="Path 931" d="M4315.984,1100.167a.565.565,0,0,1-.226-.047,8.991,8.991,0,0,1-3.641-2.9.568.568,0,1,1,.914-.676,3,3,0,0,0,5.9,0,.568.568,0,1,1,.913.677,9.022,9.022,0,0,1-3.627,2.9A.568.568,0,0,1,4315.984,1100.167Z" transform="translate(-4312.006 -1096.313)" fill="#303e58"/>
          </g>
          <g id="Group_158" data-name="Group 158" transform="translate(7.422 46.469)">
            <path id="Path_932" data-name="Path 932" d="M3572.884,1116.1a.569.569,0,0,1-.568-.568v-8.888a.568.568,0,0,1,1.137,0v8.888A.569.569,0,0,1,3572.884,1116.1Z" transform="translate(-3572.316 -1106.079)" fill="#303e58"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
</template>