import { CarrierList } from '@hfc/ui-core';
import { CarrierRouteCollection } from '@/page/infrastructure/router/CarrierRouteCollection';
import LaCare from '@/page/infrastructure/views/carrierPlans/LaCare.vue';
import Aetna from '@/page/infrastructure/views/carrierPlans/Aetna.vue';
import Anthem from '@/page/infrastructure/views/carrierPlans/Anthem.vue';
import BlueShield from '@/page/infrastructure/views/carrierPlans/BlueShield.vue';
import CCHP from '@/page/infrastructure/views/carrierPlans/CCHP.vue';
import HealthNet from '@/page/infrastructure/views/carrierPlans/HealthNet.vue';
import IEHP from '@/page/infrastructure/views/carrierPlans/IEHP.vue';
import Kaiser from '@/page/infrastructure/views/carrierPlans/Kaiser.vue';
import Molina from '@/page/infrastructure/views/carrierPlans/Molina.vue';
import Sharp from '@/page/infrastructure/views/carrierPlans/Sharp.vue';
import Valley from '@/page/infrastructure/views/carrierPlans/Valley.vue';
import Western from '@/page/infrastructure/views/carrierPlans/Western.vue';

export const carrierRouter = new CarrierRouteCollection([{
    href: '/la-care-health-plan',
    title: 'L.A. Care Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on L.A. Care Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.LACare,
    breadcrumbLabel: 'L.A. Care Health Plan',
    component: LaCare
  },{
    href: '/aetna',
    title: 'Aetna Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Aetna Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.Aetna,
    breadcrumbLabel: 'Aetna Health Plan',
    component: Aetna
  },{
    href: '/anthem',
    title: 'Anthem Blue Cross Health Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Anthem Blue Cross Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.Anthem,
    breadcrumbLabel: 'Anthem Blue Cross',
    component: Anthem
  },{
    href: '/blue-shield',
    title: 'Blue Shield Health Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Blue Shield Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.BlueShield,
    breadcrumbLabel: 'Blue Shield',
    component: BlueShield
  },{
    href: '/chinese-community-health-plan',
    title: 'Chinese Community Health Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Chinese Community Health Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.CCHP,
    breadcrumbLabel: 'Chinese Community Health Plan',
    component: CCHP
  },{
    href: '/health-net',
    title: 'Health Net Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Health Net Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.HealthNet,
    breadcrumbLabel: 'Health Net',
    component: HealthNet
  },{
    href: '/iehp',
    title: 'IEHP Insurance | Health for California',
    description: 'Find the lowest prices available on IEHP Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.IEHP,
    breadcrumbLabel: 'Covered California Health Insurance Companies',
    component: IEHP
  },{
    href: '/kaiser',
    title: 'Kaiser Permanente Health Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Kaiser Permanente Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.Kaiser,
    breadcrumbLabel: 'Kaiser Permanente',
    component: Kaiser
  },{
    href: '/molina-healthcare',
    title: 'Molina Health Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Molina Healthcare Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.Molina,
    breadcrumbLabel: 'Molina Insurance',
    component: Molina
  },{
    href: '/sharp',
    title: 'Sharp Health Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Sharp Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.Sharp,
    breadcrumbLabel: 'Sharp',
    component: Sharp
  },{
    href: '/valley-health-plan',
    title: 'Valley Health Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Valley Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.Valley,
    breadcrumbLabel: 'Valley Health Plan',
    component: Valley
  },{
    href: '/western-health-advantage',
    title: 'Western Health Insurance Coverage | Health for California',
    description: 'Find the lowest prices available on Western Plans and get an online quote. See if you qualify for a discount through our Covered California options.',
    carrierId: CarrierList.Western,
    breadcrumbLabel: 'Western Health Advantage',
    component: Western
  }
]);
