import { ServicesApiResponse } from '@hfc/ui-core';
import { HeaderRepository } from '@/shared/domain/HeaderRepository';
import { MenuItem } from '@/shared/domain/MenuItem';
import { defaultPreset } from '@/shared/infrastructure/dataSource/menuPresets/DefaultPreset';
import { medicarePreset } from '@/shared/infrastructure/dataSource/menuPresets/MedicarePreset';
import { MenuPresets } from '@/shared/domain/MenuPresets';

export class HeaderMemory implements HeaderRepository {

  public async getHeaderItems (planName: string): Promise<ServicesApiResponse<Array<MenuItem>>> {
    switch (planName) {
      case MenuPresets.MEDICARE:
        return {
          success: true,
          data: medicarePreset
        };
      default:
        return {
          success: true,
          data: defaultPreset
        };
    }
  }

}
