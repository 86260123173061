<script lang="ts" setup>
import { LinkButton } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl } = UseUrlHelper();
</script>

<template>
  <div class="px-5 md:px-12 text-gray-500">
    <h4 class="text-blue text-xl font-bold pb-6 md:pb-10">You can find the following types of plans</h4>
    <ul class="text-left list-disc">
      <li class="pb-5">
        <b>Individual and Family Healthcare Plans:</b> Since the passing of the Affordable Care Act, all non-grandfathered health plans must meet the minimum essential benefit requirements. They must provide coverage for hospitalization, emergency, preventative care, and prescription drugs. These plans are ACA compliant. They offer high-quality, affordable coverage to everyone, regardless of pre-existing medical conditions.
      </li>
      <li class="pb-5">
        <b> Short-Term Plans:</b>
        <LinkButton :href="getBaseUrl('/short-term-health-insurance')" custom-class="font-light text-base text-blue-400">
          Short-term health plans
        </LinkButton> are no longer legal in California (SB910). However, consumers may enroll in a traditional medical insurance plan for as little as 30 days. This can fill healthcare insurance gaps and protect consumers who are between jobs.
        <LinkButton :href="getBaseUrl('/travel-health-insurance')" custom-class="font-light text-base text-blue-400">
          Short-term travel
        </LinkButton> insurance is still available.
      </li>
      <li class="pb-5">
        <b>Medicare:</b>
        <LinkButton :href="getBaseUrl('/medicare/senior-health-insurance')" custom-class="font-light text-base text-blue-400">
          Medicare and Senior Health Plans
        </LinkButton> are granted to qualifying individuals over the age of 65. This medical coverage is also available to qualifying individuals who suffer from chronic illnesses or disabilities.
      </li>
      <li class="pb-5">
        <b>Medicaid: </b>
        This is referred to as
        <LinkButton :href="getBaseUrl('/covered-california/health-insurance-companies/medi-cal')" custom-class="font-light text-base text-blue-400">
          Medi-Cal in California
        </LinkButton>. This coverage is offered to individuals and families with a household income that is below 138% of the Federal Poverty Level.
      </li>
    </ul>
  </div>
</template>