<script lang="ts" setup>
import { computed } from 'vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();

const props = defineProps<{
  preTitle?: any,
  title?: any,
  postTitle?: any,
  reverse?: Boolean,
  img?: any,
}>();

const hasPreTitle = computed(() => props.preTitle && props.preTitle.text);
const preTitleExtraClass = computed(() => props.preTitle && props.preTitle.customClass ? props.preTitle.customClass : '');
const hasTitle = computed(() => props.title && props.title.text);
const titleExtraClass = computed(() => props.title && props.title.customClass ? props.title.customClass : '');
const hasPostTitle = computed(() => props.postTitle && props.postTitle.text);
const postTitleExtraClass = computed(() => props.postTitle && props.postTitle.customClass ? props.postTitle.customClass : '');
const hasImg = computed(() => props.img && props.img.src);
const orientationClasess = computed(() => props.reverse ? 'flex-col-reverse md:flex-row-reverse' : 'flex-col md:flex-row');
</script>

<template>
  <div class="2xl:px-20">
    <div :class="orientationClasess" class="flex max-w-7xl mx-auto overflow-hidden 2xl:px-6">
      <div class="flex-1 flex flex-col md:justify-center py-8 md:py-4 lg:py-8 px-6 2xl:px-0 md:pr-0 lg:pr-0 md:min-h-[444px] lg:min-h-[488px] xl:min-h-[529px]">
        <div class="text-left">
          <p
            v-if="hasPreTitle"
            class="mb-3 text-base text-white sm:mb-5 sm:text-lg sm:mx-auto md:mb-5 md:text-lg lg:mx-0"
            v-html="preTitle.text"
            :class="preTitleExtraClass"
          ></p>
          <h1
            v-if="hasTitle"
            class="text-3xl font-bold text-white sm:text-4xl md:text-3xl lg:text-4xl xl:text-6xl"
            v-html="title.text"
            :class="titleExtraClass"
          ></h1>
          <p
            v-if="hasPostTitle"
            class="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
            v-html="postTitle.text"
            :class="postTitleExtraClass"
          ></p>
          <slot />
        </div>
      </div>
      <div v-if="hasImg" class="flex-1 flex items-end">
        <img v-lazy="getCdnUrl(hasImg)" width="662" height="432" :alt="img.alt ? img.alt : 'hfc'" />
      </div>
    </div>
  </div>
</template>