<script lang="ts" setup>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { CarrierList } from '@hfc/ui-core';
import 'vue3-carousel/dist/carousel.css';

const slides = [
  CarrierList.CovCalCertified,
  CarrierList.LACare,
  CarrierList.HealthNet,
  CarrierList.BlueShield,
  CarrierList.Western,
  CarrierList.Molina,
  CarrierList.CCHP,
  CarrierList.Valley,
  CarrierList.Sharp,
  CarrierList.Aetna,
  CarrierList.IEHP,
  CarrierList.Kaiser,
  CarrierList.Anthem,
];

const settings = {
  itemsToShow: 2,
  wrapAround: true,
  snapAlign: 'center-even'
};

const breakpoints = {
  375: {
    itemsToShow: 2,
    snapAlign: 'center-even',
  },
  768: {
    itemsToShow: 3,
    snapAlign: 'center-odd'
  },
  1024: {
    itemsToShow: 5,
    snapAlign: 'center-odd'
  },
};
</script>
<template>
  <div class="bg-blue-100 carriers-carousel py-5 lg:py-8">
    <div class="max-w-7xl mx-auto">
      <Carousel :settings='settings' :breakpoints="breakpoints" :wrapAround="true" class="mx-4 sm:mx-6">
        <Slide v-for="(slide, i) in slides" :key="i">
          <carrier-logo class="w-24 md:w-28 lg:w-32" :carrier-id="slide" custom-class="w-24" vertical="true" />
        </Slide>
        <template #addons>
          <Navigation class="bg-transparent text-blue mx-0">
            <template v-slot:prev>
              <hfc-icon icon="arrow-left" class="h-6 w-6 text-blue-600" />
            </template>
            <template v-slot:next>
              <hfc-icon icon="arrow-right" class="h-6 w-6 text-blue-600" />
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>
  </div>
</template>
