import { CarrierPage } from '@/page/domain/CarrierPage';

export const sharpPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Sharp <br> Health Insurance Coverage`,
    content: `Find the Lowest Prices Available on Sharp Medical Insurance Plans or Get Discounts Through Our Covered California Options`
  },
  aboutInfo: {
    visible: true,
    title: 'About Sharp',
    content: `<p>Since 1992 Sharp Health Plan has been serving the Southern Riverside and San Diego counties as a local not-for-profit organization and subsidiary of Sharp HealthCare. Offering an integrated health care delivery system, they are the leading provider of comprehensive health care in San Diego.</p><br>
    <p>In their own words they have an “Unwavering commitment to excellence and passion for caring…It’s our care philosophy-treating people, not patients, and transforming the health care experience for our entire community.”</p><br>
    <p>Sharp is one of 11 carriers offered in the California State Exchange and one of 6 carriers being offered in Covered California’s pricing region 19.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'Take Note',
    content: `<p>The NCQA (National Committee for Quality Assurance) awarded Sharp the Wellness & Health Promotion Accreditation. They were the only health plan in California to be given this accreditation, and one of 8 health plans selected in the United States.</p><br>
    Best Health, Sharp’s integrative wellness program offers online workshops, a health library of online video’s and information, fitness tracking tools and one-on-one health coaching.</p>`,
    imageUrl: '/assets/img/bg/doctor_desk.webp'
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>For 2016 L.A. Care's individual and family plans offered through Covered California will provide access to</p>`,
    redirectUrl: 'https://www.lacare.org/health-plans/la-care-covered',
    subContent: [{
        title: '42',
        content: 'Lorem Ipsum'
      },
      {
        title: '19',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,559',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,801',
        content: 'Lorem Ipsum'
      }
    ]
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>Through Sharp’s Rees-Stealy Medical Group, select physicians can be accessed using Telehealth for over the phone follow-up appointments and for certain medical conditions. They also offer Sharp Nurse Connection as a way for members to access medical advice after hours over the phone.</p><br>
    <p>If a doctor participates, members can use FollowMyHealth, a secure online service which allows members to view test results, send and receive messages from their doctor, view personal health information, and manage appointments, etc.</p>`,
    imageUrl: '/assets/img/bg/skating.webp'
  }
};
