<script setup lang="ts">
import { computed } from 'vue';
import { ZeroToHundred } from '@hfc/common/src/types/Ranges';

const props = defineProps<{
  priceRate: ZeroToHundred,
}>();

const roundedPriceRate = computed(() => Math.ceil(props.priceRate / 25));

</script>
<template>
  <div class="flex items-center justify-center">
    <span v-for="index in 4" :key="index" :class="{'font-bold': index <= roundedPriceRate, 'font-light': index > roundedPriceRate}">$</span>
  </div>
</template>