<template>
<svg xmlns="http://www.w3.org/2000/svg" width="83.57" height="61.828" viewBox="0 0 83.57 61.828">
  <g id="Group_203" data-name="Group 203" transform="translate(-334 -312)">
    <g id="Group_202" data-name="Group 202" transform="translate(334 312)">
      <g id="Group_201" data-name="Group 201" transform="translate(0)">
        <g id="Group_191" data-name="Group 191" transform="translate(1.019 1.019)">
          <g id="Group_183" data-name="Group 183" transform="translate(33.972 43.484)">
            <path id="Path_946" data-name="Path 946" d="M542.718,574h4.621l-.951,5.435H540V574Z" transform="translate(-540 -574)" fill="#fff"/>
          </g>
          <g id="Group_184" data-name="Group 184" transform="translate(0 23.101)">
            <path id="Path_947" data-name="Path 947" d="M373.972,474.383v5.435h-6.794c0-2.718-.571-5.435-4.077-5.435s-13.589,4.077-13.589,4.077v-4.077H340c0-4.7,2.025-10.722,3.044-13.466A3.939,3.939,0,0,1,345,458.769L354.947,454s2.324,6.794,9.512,6.794c5.666,0,6.794-5.435,6.794-5.435l6.291,4.144c.163.109.312.218.463.326a6.963,6.963,0,0,1,1,.991c.843,1.441,1.753,6.2,1.753,9.377,0,.679.04,3.249.544,4.185h-7.338Z" transform="translate(-340 -454)" fill="#ebedf0"/>
          </g>
          <g id="Group_185" data-name="Group 185" transform="translate(39.407 23.101)">
            <path id="Path_948" data-name="Path 948" d="M613.65,454.719a2.122,2.122,0,0,1,.449,1.672l-5.069,28.863H572l.952-5.435.951-5.435,3.288-18.7A2.032,2.032,0,0,1,579.216,454h32.857A2.133,2.133,0,0,1,613.65,454.719Z" transform="translate(-572 -454)" fill="#f8d14b"/>
          </g>
          <g id="Group_186" data-name="Group 186" transform="translate(0 54.354)">
            <path id="Path_949" data-name="Path 949" d="M340,638h81.532v5.435H340Z" transform="translate(-340 -638)" fill="#fff"/>
          </g>
          <g id="Group_187" data-name="Group 187" transform="translate(1.413 43.484)">
            <path id="Path_950" data-name="Path 950" d="M374.084,579.435h-9.512v1.359a3.8,3.8,0,0,0,.244,1.359s-6.359,2.718-11.115,2.718c-4.81,0-5.381-4.7-5.381-10.871h8.1v4.077S366.5,574,370.007,574,374.084,576.718,374.084,579.435Z" transform="translate(-348.32 -574)" fill="#fff"/>
          </g>
          <g id="Group_188" data-name="Group 188" transform="translate(14.947 6.115)">
            <path id="Path_951" data-name="Path 951" d="M443.831,354.475a33.6,33.6,0,0,1,.475,7c0,4.159-2.269,9.512-6.794,9.512-6.55,0-8.153-6.794-8.153-6.794A3.407,3.407,0,0,1,428,361.474a2.553,2.553,0,0,1,2.718-2.718c0-2.949,3.56-4.756,5.435-4.756,3.628,0,3,.679,6.794.679A1.951,1.951,0,0,0,443.831,354.475Z" transform="translate(-428 -354)" fill="#fff"/>
          </g>
          <g id="Group_189" data-name="Group 189" transform="translate(13.589)">
            <path id="Path_952" data-name="Path 952" d="M438.63,318.109a15.21,15.21,0,0,1,.394,3.968,3.187,3.187,0,0,1-1.834,2.513,1.951,1.951,0,0,1-.884.2c-3.791,0-3.166-.679-6.794-.679-1.875,0-5.435,1.807-5.435,4.756a2.553,2.553,0,0,0-2.718,2.718s-1.359-.91-1.359-6.794c0-2.147,2.093-6.794,6.794-6.794,5.952,0,4.253.679,8.153.679C438.114,318.679,438.63,318.109,438.63,318.109Z" transform="translate(-420 -318)" fill="#f8d14b"/>
          </g>
          <g id="Group_190" data-name="Group 190" transform="translate(17.665 48.919)">
            <path id="Path_953" data-name="Path 953" d="M444,606h22.693l-.952,5.435H448.077a4.034,4.034,0,0,1-3.832-2.718,3.8,3.8,0,0,1-.244-1.359Z" transform="translate(-444 -606)" fill="#f8d14b"/>
          </g>
        </g>
        <g id="Group_200" data-name="Group 200">
          <g id="Group_192" data-name="Group 192" transform="translate(0 54.354)">
            <path id="Path_954" data-name="Path 954" d="M416.551,639.474H335.019A1.019,1.019,0,0,1,334,638.455v-5.435A1.019,1.019,0,0,1,335.019,632h21.742a1.019,1.019,0,1,1,0,2.038H336.038v3.4h79.493v-3.4h-4.077a1.019,1.019,0,1,1,0-2.038h5.1a1.019,1.019,0,0,1,1.019,1.019v5.435A1.019,1.019,0,0,1,416.551,639.474Z" transform="translate(-334 -632)" fill="#979faf"/>
          </g>
          <g id="Group_193" data-name="Group 193" transform="translate(17.665 23.101)">
            <path id="Path_955" data-name="Path 955" d="M460.762,481.292H443.1a5.06,5.06,0,0,1-4.791-3.389A4.8,4.8,0,0,1,438,476.2v-1.359a1.019,1.019,0,0,1,1.019-1.019h21.837l.8-4.592,3.288-18.7A3.043,3.043,0,0,1,467.977,448h32.857a3.172,3.172,0,0,1,2.34,1.063l.025.029a3.138,3.138,0,0,1,.667,2.477l0,.018-5.069,28.863a1.019,1.019,0,0,1-1,.843H460.762Zm1.212-2.038h34.96l4.919-28.01a1.1,1.1,0,0,0-.219-.845,1.121,1.121,0,0,0-.8-.361H467.977a1.014,1.014,0,0,0-1.019.832l-3.29,18.708L462.719,475c0,.012,0,.024-.006.036Zm-21.936-3.4v.34a2.763,2.763,0,0,0,.177.994l.013.035a3.021,3.021,0,0,0,2.868,2.029H459.9l.595-3.4Z" transform="translate(-438 -448)" fill="#303e58"/>
          </g>
          <g id="Group_194" data-name="Group 194" transform="translate(33.972 43.484)">
            <path id="Path_956" data-name="Path 956" d="M537.737,570.038h-2.718a1.019,1.019,0,1,1,0-2.038h2.718a1.019,1.019,0,1,1,0,2.038Z" transform="translate(-534 -568)" fill="#303e58"/>
          </g>
          <g id="Group_195" data-name="Group 195" transform="translate(0 23.101)">
            <path id="Path_957" data-name="Path 957" d="M345.89,470.422H335.019A1.019,1.019,0,0,1,334,469.4c0-4.744,1.947-10.7,3.108-13.821a4.992,4.992,0,0,1,2.467-2.709l9.952-4.771a1.019,1.019,0,0,1,1.4.589c.086.247,2.2,6.1,8.548,6.1a5.912,5.912,0,0,0,5.8-4.627,1.022,1.022,0,0,1,1.558-.64l6.291,4.144c.154.1.291.2.429.3l.069.05a1.019,1.019,0,1,1-1.19,1.655l-.071-.051c-.118-.085-.235-.17-.363-.255l-5.173-3.408a7.77,7.77,0,0,1-7.347,4.87,10.618,10.618,0,0,1-8.22-3.747,13.028,13.028,0,0,1-1.8-2.692l-9,4.314a2.928,2.928,0,0,0-1.443,1.588,46.43,46.43,0,0,0-2.948,12.088h8.8v-4.416a1.019,1.019,0,1,1,2.038,0V469.4A1.019,1.019,0,0,1,345.89,470.422Z" transform="translate(-334 -448.001)" fill="#303e58"/>
          </g>
          <g id="Group_196" data-name="Group 196" transform="translate(1.413 43.484)">
            <path id="Path_958" data-name="Path 958" d="M348.72,580.909a5.4,5.4,0,0,1-5.446-3.777c-.838-2.193-.954-5.2-.954-8.113a1.019,1.019,0,1,1,2.038,0c0,7.366,1.1,9.852,4.362,9.852,4.5,0,10.653-2.609,10.715-2.636a1.019,1.019,0,1,1,.8,1.874C359.968,578.224,353.628,580.909,348.72,580.909Z" transform="translate(-342.32 -568)" fill="#303e58"/>
          </g>
          <g id="Group_197" data-name="Group 197" transform="translate(9.512 43.484)">
            <path id="Path_959" data-name="Path 959" d="M408.684,575.474a1.019,1.019,0,0,1-1.019-1.019c0-3.665-1.222-4.416-3.057-4.416-2.712,0-10.453,2.889-13.207,4a1.019,1.019,0,0,1-1.4-.945v-4.077a1.019,1.019,0,1,1,2.038,0V571.6c2.942-1.132,9.686-3.6,12.569-3.6,5.1,0,5.1,4.858,5.1,6.455A1.019,1.019,0,0,1,408.684,575.474Z" transform="translate(-390 -568)" fill="#303e58"/>
          </g>
          <g id="Group_198" data-name="Group 198" transform="translate(33.972 38.048)">
            <path id="Path_960" data-name="Path 960" d="M535.019,548.909A1.019,1.019,0,0,1,534,547.89V537.019a1.019,1.019,0,0,1,2.038,0V547.89A1.019,1.019,0,0,1,535.019,548.909Z" transform="translate(-534 -536)" fill="#303e58"/>
          </g>
          <g id="Group_199" data-name="Group 199" transform="translate(13.589)">
            <path id="Path_961" data-name="Path 961" d="M425.89,337.139c-5.575,0-8.293-4.55-9.056-7.237a4.458,4.458,0,0,1-1.454-2.858c-1.272-1.619-1.38-5.863-1.38-7.23,0-2.8,2.611-7.813,7.813-7.813a21.384,21.384,0,0,1,5.311.417,11.017,11.017,0,0,0,2.842.263,7.915,7.915,0,0,0,3.046-.348,1.019,1.019,0,0,1,1.612.5,16.131,16.131,0,0,1,.438,4.263,4.1,4.1,0,0,1-1.725,3.03,45.055,45.055,0,0,1,.366,6.482C433.7,330.981,431.279,337.139,425.89,337.139ZM417.4,326.621a2.386,2.386,0,0,0,.917,1.865.989.989,0,0,1,.415.606c.059.243,1.516,6.009,7.161,6.009,3.94,0,5.775-5.068,5.775-8.493a46.989,46.989,0,0,0-.288-5.776h-.052a14.587,14.587,0,0,1-3.7-.369,12.038,12.038,0,0,0-3.091-.31c-1.349,0-4.416,1.412-4.416,3.737a1.019,1.019,0,0,1-1.019,1.019,1.545,1.545,0,0,0-1.7,1.687C417.4,326.6,417.4,326.613,417.4,326.621Zm4.416-12.583c-4.057,0-5.775,4.131-5.775,5.775a21.72,21.72,0,0,0,.326,4.139,3.534,3.534,0,0,1,1.805-.984,5.659,5.659,0,0,1,2.34-3.385,7.436,7.436,0,0,1,4.022-1.468,13.93,13.93,0,0,1,3.53.358,12.688,12.688,0,0,0,3.264.322.941.941,0,0,0,.426-.1l.055-.025a2.223,2.223,0,0,0,1.218-1.577,19.983,19.983,0,0,0-.155-2.608,15.047,15.047,0,0,1-2.9.23,12.846,12.846,0,0,1-3.276-.31A19.714,19.714,0,0,0,421.813,314.038Zm11.081-1.6h0Zm0,0h0Zm0,0h0Z" transform="translate(-414 -312)" fill="#303e58"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
</template>