<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import { MetalPlanData } from '@/page/domain/MetalPlanData';
import { MetalPlanPageData } from '@/page/domain/MetalPlanPageData';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import MetalTierCard from '@/page/infrastructure/components/metalPlans/MetalTierCard.vue';

const { getBaseUrl } = UseUrlHelper();

defineProps<{
  pageData: Partial<MetalPlanPageData>;
  plans: Array<MetalPlanData>;
}>();
</script>
<template>
  <div class="w-full bg-blue py-20">
    <div class="flex flex-col max-w-7xl mx-auto px-6 lg:px-3">
      <div class="flex w-full justify-center mb-10">
        <h3 class="text-white font-bold">What Types of Health Plans are Available</h3>
      </div>
      <div class="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 mb-10">
        <MetalTierCard v-for="plan in plans" :plan="plan" :key="plan.key" />
      </div>
      <div class="flex w-full justify-center">
        <PrimaryButton v-if="pageData.redirectUrl" class="text-xl font-semibold" size="xl" :href="getBaseUrl(pageData.redirectUrl)">Compare All Plans</PrimaryButton>
      </div>
    </div>
  </div>
</template>