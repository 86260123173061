import { CarrierPage } from '@/page/domain/CarrierPage';

export const iehpPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `IEHP Insurance`,
  },
  aboutInfo: {
    visible: true,
    title: 'About IEHP',
    content: `<p>Inland Empire Health Plan (IEHP) is a wellness-focused, Medi-Cal managed care plan serving residents in the Inland Empire (IE) region. It was established in 1994 as the region’s Medi-Cal plan. Today, IEHP also offers metallic plans, including a low-cost silver plan, through Covered California for individuals who aren’t eligible for Medi-Cal.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'Why Choose IEHP?',
    content: `<p>IEHP is known as a health insurance provider that cares about its community and improving access to quality health care. It provides zero-premium or low-cost plans to eligible individuals. IEHP offers advantages such as:</p><br>
    <ul class="ml-10 list-disc">
    <li>Local customer support team</li>
    <li>Care coordinators to help members plan appointments</li>
    <li>Free 24/7 nurse advice line</li>
    <li>More than 8,000 providers and over 90 urgent care centers in its network</li>
    </ul>`,
    imageUrl: '/assets/img/bg/doctor_desk.webp',
    textDirection: 'text-left',
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>For 2016 L.A. Care's individual and family plans offered through Covered California will provide access to</p>`,
    redirectUrl: 'https://www.lacare.org/health-plans/la-care-covered',
    subContent: [{
        title: '42',
        content: 'Lorem Ipsum'
      },
      {
        title: '19',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,559',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,801',
        content: 'Lorem Ipsum'
      }
    ]
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>IEHP is an excellent choice for IE residents seeking no-cost or low-cost health insurance — but IEHP is just one option for great health care coverage. Complete our fast application today and discover various health plans for your budget and health care goals. If you run into any questions, know that our agents are always happy to help.</p><br>
    <p>Contact us today to find out more about IEHP or any other California health plan.</p>`,
    imageUrl: '/assets/img/bg/skating.webp'
  }
};
