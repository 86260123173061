<script setup lang="ts">
import { computed } from 'vue';
import { PrimaryButton, BaseButton } from '@hfc/ui-core';
import { MetalPlanData } from '@/page/domain/MetalPlanData';
import MetalTierContent from '@/page/infrastructure/views/MetalTierContent.vue';
import ParagraphWithIcon from '@/page/infrastructure/components/ParagraphWithIcon.vue';
import { UseMetallicPlansStore } from '@/page/infrastructure/composition/UseMetallicPlansStore';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const props = defineProps<{
  planName: string;
}>();

const { getBaseUrl, getCdnUrl } = UseUrlHelper();

const { metalPlanPageSections, metalPlans } = UseMetallicPlansStore(props.planName);

const silverPlans = computed(() => metalPlans.value.filter(plan => plan.tierName === 'Silver') as Array<MetalPlanData>);
</script>
<template>
  <MetalTierContent v-if="metalPlanPageSections" :metal-plan-page-sections="metalPlanPageSections" :plan-name="planName">
    <template v-slot:InfoBanner="{ pageData }">
      <div v-if="pageData" class="w-full py-16">
        <div class="flex flex-1 flex-col items-center justify-center lg:max-w-4xl mx-auto relative space-y-6 px-6 lg:px-10">
          <div class="text-center flex-col" v-html="pageData.content"></div>
        </div>
      </div>
    </template>
    <template v-slot:afterInfoBanner="{ pageData }">
      <div v-if="pageData.metalPlanTier" class="w-full bg-blue py-16">
        <div class="flex flex-1 flex-col items-center justify-center max-w-7xl mx-auto relative space-y-6 px-6 lg:px-3">
          <h3 class="text-white font-bold" v-html="pageData.metalPlanTier.title"></h3>
          <div class="text-white text-center flex-col" v-html="pageData.metalPlanTier.subtitle"></div>
          <div class="flex flex-col lg:flex-row w-full justify-center space-y-10 lg:space-y-0 lg:space-x-10">
            <div v-for="plan in silverPlans" class="flex flex-col items-center text-center bg-white rounded-xl px-10 py-6">
              <h4 class="text-red font-semibold mb-2">{{ plan.tierName }} {{ plan.tierNumber }}</h4>
              <div class="text-blue-500">Check out this plan for additional details.</div>
              <div class="flex h-full flex-col justify-end">
                <BaseButton class="text-red font-bold text-md" :ignore-styles="true" :href="getBaseUrl(`/covered-california/plans/${plan.tierName.toLowerCase()}-${plan.tierNumber}`)">
                  Learn More <hfc-icon icon="arrow-right" class="ml-1 md:ml-2 mt-2" />
                </BaseButton>
              </div>
            </div>
          </div>
          <div v-if="pageData.metalPlanTier.redirectUrl" class="flex mt-10">
            <PrimaryButton :href="pageData.metalPlanTier.redirectUrl" size="xl" class="text-xl font-semibold">{{ pageData.metalPlanTier.redirectText }}</PrimaryButton>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:TextSection="{ pageData }">
      <div v-if="pageData" class="w-full py-16" :class="pageData.backgroundColor ? pageData.backgroundColor : 'bg-slate-100'" >
        <div class="flex flex-col lg:flex-row flex-1 max-w-7xl mx-auto relative px-6 lg:px-3">
          <div class="flex flex-1 flex-col space-y-2 lg:mr-16">
            <h3 class="font-bold" v-html="pageData.title"></h3>
            <div class="flex w-full flex-col" v-html="pageData.subtitle"></div>
            <div class="flex flex-1 items-end">
              <img v-if="pageData.imageUrl" class="rounded" :src="getCdnUrl(pageData.imageUrl)" />
            </div>
          </div>
          <div class="flex flex-1 flex-col space-y-10 mt-6 lg:mt-0">
            <ParagraphWithIcon v-for="subContent in pageData.subContent?.slice(0,3)" :text="subContent" icon="chevron-right" />
            <div class="flex w-full flex-col" v-html="pageData.content"></div>
            <ParagraphWithIcon v-for="subContent in pageData.subContent?.slice(3,5)" :text="subContent" icon="chevron-right" />
          </div>
        </div>
      </div>
    </template>
  </MetalTierContent>
</template>
