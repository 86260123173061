import { inject, onMounted, ref, provide, Ref } from 'vue';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import { CarrierPage } from '@/page/domain/CarrierPage';

interface UseMetallicPlansStoreInterface {
  carrierData: Ref<Partial<CarrierPage>>;
}

export const UseCarrierDataStore = (planName: string): UseMetallicPlansStoreInterface => {
  let pageStore = inject<PageStore>('PageStore') as PageStore;

  if (!pageStore) {
    pageStore = new PageStore();
    provide('PageStore', pageStore);
  }

  let carrierData = ref<Partial<CarrierPage>>({});

  onMounted(async () => {
    const response = await pageStore.getCarrierData(planName);
    if (response.success && response.data) {
      carrierData.value = response.data;
    }
  });

  return {
    carrierData
  };
}