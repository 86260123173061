import { MenuItem } from '@/shared/domain/MenuItem';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import HealthSubMenu from '@/shared/infrastructure/dataSource/menuPresets/HealthSubMenu';
import LearnSubMenu from '@/shared/infrastructure/dataSource/menuPresets/LearnSubMenu';

const { getBaseUrl } = UseUrlHelper();

export const defaultPreset: Array < MenuItem > = [
  {
    label: 'Health Insurance',
    href: '',
    description: null,
    customClass: '',
    prefixImg: '',
    subMenu: HealthSubMenu
  },
  {
    label: 'Dental',
    href: getBaseUrl('/supplemental-plans/dental'),
    description: null,
    customClass: '',
    prefixImg: '',
    subMenu: []
  },
  {
    label: 'Vision',
    href: getBaseUrl('/supplemental-plans/vision-insurance'),
    description: null,
    customClass: '',
    prefixImg: '',
    subMenu: []
  },
  {
    label: 'Learn',
    href: '',
    description: '',
    customClass: '',
    prefixImg: '',
    subMenu: LearnSubMenu
  }
];
