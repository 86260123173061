<script lang="ts" setup>
import SinglePost from '@/blog/infrastructure/components/SinglePost.vue';
import { useRoute } from '@hfc/ui-core';

const route = useRoute();
const postId = route.params.id as string;

</script>

<template>
  <div class="flex flex-col">
    <h1>This is post view</h1>
    <SinglePost class="border inline-block border-red-400" :id="postId"/>
  </div>
</template>
