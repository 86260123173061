import { RouteRecordRaw } from 'vue-router';
import { RouteMeta } from '@/shared/application/RouteMeta';
import { carrierRouter } from '@/page/infrastructure/router/carrierRouter';
import { metalPlanRouter } from '@/page/infrastructure/router/metalPlanRouter';
import HomeContent from '@/page/infrastructure/views/HomeContent.vue';
import FaqContent from '@/page/infrastructure/views/FaqContent.vue';

export const pageRouter: Array<RouteRecordRaw & { meta: RouteMeta }> = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'HomePage'
    },
    component: HomeContent
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: {
      layout: 'HomePage',
      title: 'Covered California FAQ - Frequently Asked Questions',
      description: 'Find FAQs below about Healthcare Reform and the Affordable Care Act. View questions and answers related to California Health Insurance, Covered',
    },
    component: FaqContent
  },
  ...carrierRouter.getRoutes(),
  ...metalPlanRouter.getRoutes(),
];
