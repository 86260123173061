<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="89.37" height="61.797" viewBox="0 0 89.37 61.797">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_145" data-name="Rectangle 145" width="89.37" height="61.797" transform="translate(0 0)" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_254" data-name="Group 254" transform="translate(-415 -448.105)">
    <g id="Group_259" data-name="Group 259" transform="translate(415 448.105)">
      <g id="Group_250" data-name="Group 250" clip-path="url(#clip-path)">
        <path id="Path_967" data-name="Path 967" d="M75.035,48.689a.866.866,0,0,1,.691.861.741.741,0,0,1-.013.169.864.864,0,0,1-.848.7.442.442,0,0,1-.182-.026.91.91,0,0,1-.4-.2.875.875,0,0,1,.586-1.526.746.746,0,0,1,.17.013" fill="#fff"/>
        <path id="Path_968" data-name="Path 968" d="M75.035,52.171a.7.7,0,0,1-.052.287.862.862,0,0,1-.822.6.7.7,0,0,1-.287-.052.9.9,0,0,1-.248-.131.885.885,0,0,1,.535-1.578h.013a1.012,1.012,0,0,1,.274.052.866.866,0,0,1,.587.822" fill="#fff"/>
        <path id="Path_969" data-name="Path 969" d="M73.04,53.8a.9.9,0,0,1,.443.117.862.862,0,0,1,.444.757.847.847,0,0,1-.118.43.86.86,0,0,1-.756.444.847.847,0,0,1-.43-.118,1.77,1.77,0,0,1-.2-.143.887.887,0,0,1-.144-1.044.863.863,0,0,1,.757-.443" fill="#fff"/>
        <path id="Path_970" data-name="Path 970" d="M64.262,8.739c3.6,0,8.2,2.165,7.682,7.682a13.317,13.317,0,0,0-15.364,0c-.522-5.517,4.082-7.682,7.682-7.682" fill="#ebedf0"/>
        <path id="Path_971" data-name="Path 971" d="M72.049,56.241a.885.885,0,0,1,.065,1.239.916.916,0,0,1-.652.274.847.847,0,0,1-.587-.222.788.788,0,0,1-.209-.3.878.878,0,0,1,.8-1.226.861.861,0,0,1,.587.235" fill="#fff"/>
        <path id="Path_972" data-name="Path 972" d="M55.576,46.5c1.852-.952,3.261-2.1,3.417-3.73.261.1.483.169.652.221,1.265.8,1.461,1.566,4.617,1.566s3.352-.77,4.618-1.566c.169-.052.391-.117.652-.221.156,1.591,1.5,2.726,3.287,3.665a8.678,8.678,0,0,1-17.243.065" fill="#fff"/>
        <path id="Path_973" data-name="Path 973" d="M69.388,59.527a1.032,1.032,0,0,1-.587-.235.9.9,0,0,1-.143-.169.87.87,0,0,1,.26-1.213.894.894,0,0,1,.47-.144.867.867,0,0,1,.73.405.9.9,0,0,1,.144.482.858.858,0,0,1-.391.731.947.947,0,0,1-.483.143" fill="#fff"/>
        <path id="Path_974" data-name="Path 974" d="M67.81,59.841a.872.872,0,0,1-.574.822,1.211,1.211,0,0,1-.3.052.884.884,0,0,1-.822-.587.875.875,0,0,1,.535-1.109.815.815,0,0,1,.3-.052.841.841,0,0,1,.678.352.672.672,0,0,1,.131.235.746.746,0,0,1,.052.287" fill="#fff"/>
        <path id="Path_975" data-name="Path 975" d="M69.388,57.767l1.278-.535a.778.778,0,0,0,.209.3.845.845,0,0,0,.587.222.918.918,0,0,0,.652-.274.885.885,0,0,0-.065-1.239.866.866,0,0,0-.587-.235l.965-.717a1.77,1.77,0,0,0,.2.143.847.847,0,0,0,.43.118.872.872,0,0,0,.43-1.631.9.9,0,0,0-.443-.117l.587-.926a.9.9,0,0,0,.248.13.686.686,0,0,0,.287.053.859.859,0,0,0,.821-.6.692.692,0,0,0,.053-.287.865.865,0,0,0-.587-.822,1.027,1.027,0,0,0-.274-.052l.1-1.1a.9.9,0,0,0,.4.2.449.449,0,0,0,.183.026.864.864,0,0,0,.848-.7.754.754,0,0,0,.013-.17.867.867,0,0,0-.691-.861l.417-1.082a53.3,53.3,0,0,1,5.765,2.4c7.108,3.938,7.5,9.56,7.5,11.138H66.936v-.43a1.168,1.168,0,0,0,.3-.053.87.87,0,0,0,.574-.821.746.746,0,0,0-.052-.287.688.688,0,0,0-.131-.235l1.174-.026a1.032,1.032,0,0,0,.587.235.938.938,0,0,0,.483-.144.856.856,0,0,0,.391-.73.9.9,0,0,0-.144-.483.866.866,0,0,0-.73-.4" fill="#f8d14b"/>
        <path id="Path_976" data-name="Path 976" d="M63.388,60.271a.921.921,0,0,1,.248-.626.907.907,0,0,1,1.239,0,.881.881,0,0,1,0,1.252.866.866,0,0,1-.613.248.848.848,0,0,1-.874-.874" fill="#fff"/>
        <path id="Path_977" data-name="Path 977" d="M62.019,59.006v.013a.869.869,0,0,1,.535,1.109.9.9,0,0,1-.822.587,1.021,1.021,0,0,1-.287-.053.863.863,0,0,1-.535-1.108l.131-.248c.391-.3.535-.352.691-.352a1.021,1.021,0,0,1,.287.052" fill="#fff"/>
        <path id="Path_978" data-name="Path 978" d="M60.01,59.123l-.143.17a1.036,1.036,0,0,1-.587.234.947.947,0,0,1-.483-.143.873.873,0,0,1-.352-.978.914.914,0,0,1,.1-.235.886.886,0,0,1,.731-.4.865.865,0,0,1,.469.143.892.892,0,0,1,.4.743.8.8,0,0,1-.144.47" fill="#fff"/>
        <path id="Path_979" data-name="Path 979" d="M57.793,57.532a.847.847,0,0,1-.587.222.92.92,0,0,1-.652-.274.884.884,0,0,1-.052-1.1.406.406,0,0,1,.117-.143.861.861,0,0,1,.587-.235.879.879,0,0,1,.639.287.859.859,0,0,1,.235.587.888.888,0,0,1-.287.652" fill="#fff"/>
        <path id="Path_980" data-name="Path 980" d="M54.858,55.106a.873.873,0,0,1,.326-1.187.86.86,0,0,1,.431-.117.894.894,0,0,1,.769.443.8.8,0,0,1,.1.418.865.865,0,0,1-.443.769.862.862,0,0,1-1.187-.326" fill="#fff"/>
        <path id="Path_981" data-name="Path 981" d="M55.393,52.184a.891.891,0,0,1-.6.822.684.684,0,0,1-.274.052.854.854,0,0,1-.835-.6.885.885,0,0,1,.535-1.109,1.021,1.021,0,0,1,.287-.052.908.908,0,0,1,.835.6,1.021,1.021,0,0,1,.052.287" fill="#fff"/>
        <path id="Path_982" data-name="Path 982" d="M53.815,48.676a.876.876,0,0,1,.861.7.6.6,0,0,1,.013.144.873.873,0,0,1-.7.874.9.9,0,0,1-.183.026.872.872,0,0,1-.443-1.618.944.944,0,0,1,.286-.117.754.754,0,0,1,.17-.013" fill="#fff"/>
        <path id="Path_983" data-name="Path 983" d="M55.615,53.8a.856.856,0,0,0-.43.117l-.666-.86a.665.665,0,0,0,.274-.053.888.888,0,0,0,.6-.821,1.015,1.015,0,0,0-.052-.287.907.907,0,0,0-.835-.6,1.028,1.028,0,0,0-.287.052l-.417-.926a.9.9,0,0,0,.183-.026.872.872,0,0,0,.7-.874.583.583,0,0,0-.013-.143.876.876,0,0,0-.861-.705.741.741,0,0,0-.169.013.916.916,0,0,0-.287.118l-.444-1.148c.144-.052.274-.1.417-.157.783-.313,1.54-.639,2.244-1a8.678,8.678,0,0,0,17.242-.065,26.157,26.157,0,0,0,2.635,1.174l-.417,1.082a.75.75,0,0,0-.17-.013.875.875,0,0,0-.587,1.526l-.1,1.1h-.013a.885.885,0,0,0-.535,1.578l-.587.926a.861.861,0,0,0-.756.443.885.885,0,0,0,.143,1.044l-.965.717a.878.878,0,0,0-.8,1.226l-1.278.535a.891.891,0,0,0-.469.144.877.877,0,0,0-.118,1.382l-1.174.026a.839.839,0,0,0-.678-.352.815.815,0,0,0-.3.052.873.873,0,0,0,.287,1.7v.43H64.262a.865.865,0,0,0,.613-.248.881.881,0,0,0,0-1.252.907.907,0,0,0-1.239,0,.925.925,0,0,0-.247.626.847.847,0,0,0,.873.874h-2.53v-.43a.9.9,0,0,0,.822-.587.868.868,0,0,0-.535-1.109v-.013a1.021,1.021,0,0,0-.287-.052c-.156,0-.3.052-.691.352l-1.174-.013.143-.17a.789.789,0,0,0,.144-.469.89.89,0,0,0-.4-.743.861.861,0,0,0-.47-.144.885.885,0,0,0-.73.4.914.914,0,0,0-.1.235l-1.239-.652a.845.845,0,0,0,.587-.222.886.886,0,0,0,.287-.652.861.861,0,0,0-.235-.587.883.883,0,0,0-.639-.287.868.868,0,0,0-.587.235.4.4,0,0,0-.117.144l-.887-.835a.847.847,0,0,0,.43-.118.865.865,0,0,0,.444-.769.8.8,0,0,0-.1-.418.9.9,0,0,0-.77-.443" fill="#f8d14b"/>
        <path id="Path_984" data-name="Path 984" d="M52.093,44.306l1.239,3.2c-.143.052-.274.1-.417.156a54.369,54.369,0,0,0-5.609,2.348c-7.121,3.939-7.5,9.56-7.5,11.138H.652V51.323c0-4.787.848-11.934,8.387-13.76a37.77,37.77,0,0,0,5.6-1.813v.013c1.435,5.582,6.469,8.569,12.234,8.569s10.8-2.987,12.234-8.569a38.7,38.7,0,0,0,5.6,1.8,9.835,9.835,0,0,1,7.395,6.743" fill="#fff"/>
        <path id="Path_985" data-name="Path 985" d="M76.17,29.62a1.779,1.779,0,0,0-.209-.339A1.356,1.356,0,0,0,75,28.824a30.551,30.551,0,0,1-15.977-5.23c-1.187,3.209-4.748,4.722-5.844,5.126a1.264,1.264,0,0,0-.652.535.272.272,0,0,0-.052.078,2.744,2.744,0,0,0-.13.274,4.76,4.76,0,0,0-.313,1.839,3.136,3.136,0,0,0,2.6,3.3c.692,4.813,2.218,6.717,3.47,7.552a4.3,4.3,0,0,0,.9.469c.26.1.482.17.652.222,1.265.8,1.461,1.565,4.617,1.565s3.352-.77,4.617-1.565c.17-.052.391-.118.652-.222a4.639,4.639,0,0,0,.9-.469c1.252-.835,2.778-2.739,3.457-7.552a3.136,3.136,0,0,0,2.6-3.3,4.685,4.685,0,0,0-.313-1.826" fill="#fff"/>
        <path id="Path_986" data-name="Path 986" d="M55.184,53.919a.873.873,0,0,0-.326,1.187.86.86,0,0,0,.756.443l.887.835a.885.885,0,0,0,.053,1.1.92.92,0,0,0,.652.274l1.239.652a.872.872,0,0,0,.352.978.943.943,0,0,0,.482.143,1.034,1.034,0,0,0,.587-.234l1.174.013-.13.248a.861.861,0,0,0,.535,1.108,1.015,1.015,0,0,0,.287.052v.431H39.807c0-1.578.378-7.2,7.5-11.139a54.369,54.369,0,0,1,5.609-2.348l.443,1.148a.872.872,0,0,0,.443,1.618l.418.926a.883.883,0,0,0-.535,1.108.853.853,0,0,0,.835.6Z" fill="#f8d14b"/>
        <path id="Path_987" data-name="Path 987" d="M53.176,28.72a1.256,1.256,0,0,0-.652.535.272.272,0,0,0-.052.078,2.734,2.734,0,0,0-.131.274c-.13-.091-1.187-.939-1.187-5.139,0-2.674,2.179-5.882,5.426-8.047a13.319,13.319,0,0,1,15.365,0c3.234,2.165,5.412,5.373,5.412,8.047,0,4.474-1.186,5.152-1.186,5.152a1.886,1.886,0,0,0-.209-.339A1.358,1.358,0,0,0,75,28.824a30.553,30.553,0,0,1-15.978-5.23c-1.187,3.209-4.747,4.722-5.843,5.126" fill="#ebedf0"/>
        <path id="Path_988" data-name="Path 988" d="M39.1,15.508c-4.552-2.8-.717-7.969-4.513-11.739a10.5,10.5,0,0,0-3.613-2.347c4.252,0,9,.834,9,9a15.141,15.141,0,0,1-.639,5.322,1.035,1.035,0,0,0-.235-.235" fill="#979faf"/>
        <path id="Path_989" data-name="Path 989" d="M35.607,16.382c.13,1.578-.026,4.069-3.261,4.343h-.013c-.665-.026-1.226-.065-1.695-.1-2.635-.678-2.165-3.809-2.165-3.809a4.1,4.1,0,0,1,3.547-1.7c3.013,0,3.587,1.265,3.587,1.265" fill="#fff"/>
        <path id="Path_990" data-name="Path 990" d="M32.333,20.725c-.183.013-.378.026-.587.026a4.638,4.638,0,0,1-1.109-.13c.47.039,1.031.078,1.7.1" fill="#fff"/>
        <path id="Path_991" data-name="Path 991" d="M25.264,16.812s.456,3.117-2.165,3.808c-.47.04-1.031.079-1.7.1H21.39c-3.234-.274-3.391-2.765-3.26-4.343,0,0,.573-1.266,3.573-1.266a4.132,4.132,0,0,1,3.561,1.7" fill="#fff"/>
        <path id="Path_992" data-name="Path 992" d="M23.1,20.621a4.632,4.632,0,0,1-1.109.13c-.208,0-.4-.013-.587-.026.666-.026,1.226-.065,1.7-.1" fill="#fff"/>
        <path id="Path_993" data-name="Path 993" d="M26.868,32.111c3.378,0,3.587-.769,4.943-1.578a5.6,5.6,0,0,0,2.048-.952v1.291A4.024,4.024,0,0,0,35.75,33.9a9.191,9.191,0,0,1-8.882,6.939,9.293,9.293,0,0,1-9.091-6.783v-.013a4.17,4.17,0,0,0,2.1-3.169V29.581a5.567,5.567,0,0,0,2.035.952c1.369.809,1.565,1.578,4.956,1.578" fill="#fff"/>
        <path id="Path_994" data-name="Path 994" d="M26.868,40.837A9.19,9.19,0,0,0,35.75,33.9,15.888,15.888,0,0,0,39.1,35.763c-1.435,5.583-6.469,8.569-12.234,8.569s-10.8-2.986-12.234-8.569V35.75a15.817,15.817,0,0,0,3.143-1.695,9.294,9.294,0,0,0,9.091,6.782" fill="#f8d14b"/>
        <path id="Path_995" data-name="Path 995" d="M35.607,16.382s-.574-1.265-3.587-1.265a4.1,4.1,0,0,0-3.547,1.7,7.062,7.062,0,0,0-1.6-.43,6.864,6.864,0,0,0-1.6.43,4.133,4.133,0,0,0-3.561-1.7c-3,0-3.573,1.265-3.573,1.265l-3.5-.874c4.539-2.8.717-7.969,4.513-11.738a10.541,10.541,0,0,1,3.6-2.348,11.381,11.381,0,0,1,8.23,0A10.507,10.507,0,0,1,34.59,3.77c3.795,3.769-.04,8.934,4.512,11.738Z" fill="#fff"/>
        <path id="Path_996" data-name="Path 996" d="M19.147,3.769c-3.795,3.769.026,8.934-4.513,11.739a1.752,1.752,0,0,0-.248.247,15.505,15.505,0,0,1-.626-5.334c0-8.165,4.748-9,8.987-9a10.571,10.571,0,0,0-3.6,2.348" fill="#979faf"/>
        <path id="Path_997" data-name="Path 997" d="M39.337,15.756v-.013a1.012,1.012,0,0,0-.235-.235l-3.5.874c.13,1.578-.026,4.069-3.261,4.343h-.013c-.183.013-.378.026-.587.026a4.638,4.638,0,0,1-1.109-.13c-2.634-.678-2.165-3.809-2.165-3.809a7.049,7.049,0,0,0-1.6-.43,6.849,6.849,0,0,0-1.6.43s.456,3.118-2.165,3.809a4.638,4.638,0,0,1-1.109.13c-.209,0-.4-.013-.587-.026H21.39c-3.235-.274-3.391-2.765-3.261-4.343l-3.495-.874a1.733,1.733,0,0,0-.248.248,3.912,3.912,0,0,0-.626,2.374c0,3.926,2.778,4.173,2.778,4.173.678,4.357,2.074,6.326,3.339,7.278a5.573,5.573,0,0,0,2.035.953c1.369.808,1.565,1.578,4.956,1.578s3.587-.77,4.943-1.578a5.608,5.608,0,0,0,2.048-.953c1.252-.952,2.661-2.921,3.326-7.278,0,0,2.791-.247,2.791-4.173a3.8,3.8,0,0,0-.639-2.374" fill="#fff"/>
        <path id="Path_998" data-name="Path 998" d="M26.868,32.764c-2.7,0-3.538-.456-4.595-1.207-.194-.138-.393-.28-.624-.42l-.03-.009A6,6,0,0,1,19.481,30.1c-1.723-1.3-2.906-3.737-3.515-7.255a3.7,3.7,0,0,1-1.223-.645,4.876,4.876,0,0,1-1.635-4.069,4.574,4.574,0,0,1,.751-2.759l.029-.036a2.249,2.249,0,0,1,.354-.348l.783,1.043.008-.006a1.136,1.136,0,0,0-.134.136,3.361,3.361,0,0,0-.487,1.97,3.74,3.74,0,0,0,1.094,3.009,2.3,2.3,0,0,0,1.1.516.643.643,0,0,1,.579.548c.531,3.408,1.57,5.715,3.087,6.857a4.683,4.683,0,0,0,1.71.814l.117.034a.662.662,0,0,1,.147.064c.308.182.562.362.786.522.789.56,1.359.966,3.839.966s3.049-.408,3.839-.972c.22-.157.47-.335.77-.514a.691.691,0,0,1,.15-.066l.126-.036a4.7,4.7,0,0,0,1.709-.808c1.525-1.16,2.56-3.467,3.078-6.859a.653.653,0,0,1,.587-.551,2.32,2.32,0,0,0,1.1-.516,3.726,3.726,0,0,0,1.1-3.008,3.186,3.186,0,0,0-.5-1.976.652.652,0,0,1,1.034-.8,4.431,4.431,0,0,1,.774,2.772,5.065,5.065,0,0,1-1.643,4.07,3.676,3.676,0,0,1-1.227.644c-.6,3.5-1.774,5.941-3.5,7.257a6.025,6.025,0,0,1-2.142,1.025l-.037.01c-.224.138-.42.278-.61.414-1.059.755-1.9,1.214-4.6,1.214" fill="#303e58"/>
        <path id="Path_999" data-name="Path 999" d="M39.338,16.4a.649.649,0,0,1-.517-.255.731.731,0,0,0-.089-.1.249.249,0,0,0,.025.017l.691-1.106a1.607,1.607,0,0,1,.406.39.652.652,0,0,1-.516,1.05" fill="#303e58"/>
        <path id="Path_1000" data-name="Path 1000" d="M39.1,16.16a.642.642,0,0,1-.341-.1c-2.685-1.654-2.775-4.041-2.862-6.35-.075-1.99-.147-3.87-1.769-5.481a9.89,9.89,0,0,0-3.39-2.2,10.739,10.739,0,0,0-7.75,0,9.976,9.976,0,0,0-3.382,2.2c-1.625,1.614-1.7,3.5-1.775,5.488-.089,2.307-.181,4.692-2.856,6.345a.652.652,0,0,1-.686-1.11c2.084-1.287,2.156-3.14,2.239-5.286.079-2.056.17-4.386,2.158-6.361A11.262,11.262,0,0,1,22.509.814a12.046,12.046,0,0,1,8.711,0,11.2,11.2,0,0,1,3.831,2.492c1.985,1.972,2.073,4.3,2.151,6.356.081,2.146.151,4,2.243,5.289A.652.652,0,0,1,39.1,16.16" fill="#303e58"/>
        <path id="Path_1001" data-name="Path 1001" d="M39.337,16.408a.652.652,0,0,1-.652-.652v-.013a.638.638,0,0,1,.043-.233,14.614,14.614,0,0,0,.6-5.089c0-3.464-.861-5.764-2.633-7.031-1.621-1.16-3.8-1.316-5.714-1.316a.652.652,0,1,1,0-1.3c2.123,0,4.552.185,6.473,1.559,2.139,1.53,3.178,4.177,3.178,8.092a15.8,15.8,0,0,1-.657,5.49.653.653,0,0,1-.634.5" fill="#303e58"/>
        <path id="Path_1002" data-name="Path 1002" d="M14.386,16.408a.653.653,0,0,1-.611-.423,15.951,15.951,0,0,1-.667-5.564c0-3.914,1.039-6.561,3.176-8.092C18.2.955,20.628.769,22.746.769a.653.653,0,1,1,0,1.3c-1.911,0-4.083.156-5.7,1.316-1.77,1.268-2.631,3.568-2.631,7.031A14.957,14.957,0,0,0,15,15.527a.652.652,0,0,1-.382.839.639.639,0,0,1-.229.042" fill="#303e58"/>
        <path id="Path_1003" data-name="Path 1003" d="M52.093,44.959a.654.654,0,0,1-.617-.44,9.939,9.939,0,0,0-2.346-3.874A9.567,9.567,0,0,0,44.545,38.2a39.721,39.721,0,0,1-5.7-1.832,16.416,16.416,0,0,1-3.5-1.95,4.609,4.609,0,0,1-2.145-3.542V29.8a.652.652,0,1,1,1.3,0v1.07a3.416,3.416,0,0,0,1.638,2.509,15.16,15.16,0,0,0,3.212,1.783,38.359,38.359,0,0,0,5.493,1.765,10.475,10.475,0,0,1,7.856,7.164.652.652,0,0,1-.4.829.666.666,0,0,1-.213.036" fill="#303e58"/>
        <path id="Path_1004" data-name="Path 1004" d="M88.717,61.8a.652.652,0,0,1-.652-.652c0-1.984-.7-6.986-7.164-10.568a33.735,33.735,0,0,0-4.17-1.781c-.5-.19-1.015-.385-1.517-.583a26.654,26.654,0,0,1-2.7-1.205c-1.455-.765-3.418-2.032-3.629-4.177a.652.652,0,1,1,1.3-.127c.114,1.155,1.048,2.156,2.941,3.151A25.718,25.718,0,0,0,75.692,47c.494.194,1.006.388,1.5.576a35.123,35.123,0,0,1,4.34,1.86c7.073,3.919,7.836,9.494,7.836,11.709a.652.652,0,0,1-.652.652" fill="#303e58"/>
        <path id="Path_1005" data-name="Path 1005" d="M.652,61.8A.652.652,0,0,1,0,61.145V51.324A20.7,20.7,0,0,1,1.422,43.07a10.429,10.429,0,0,1,7.463-6.141,36.373,36.373,0,0,0,5.492-1.778,15.2,15.2,0,0,0,3.015-1.623.652.652,0,1,1,.77,1.053,16.2,16.2,0,0,1-3.274,1.77A37.625,37.625,0,0,1,9.2,38.2a9.073,9.073,0,0,0-6.581,5.4A19.486,19.486,0,0,0,1.3,51.324v9.821a.652.652,0,0,1-.652.652" fill="#303e58"/>
        <path id="Path_1006" data-name="Path 1006" d="M17.778,34.694a.652.652,0,0,1-.382-1.181c1.214-.876,1.829-1.765,1.829-2.641V29.594a.652.652,0,1,1,1.3,0v1.278c0,1.319-.8,2.563-2.37,3.7a.65.65,0,0,1-.381.123" fill="#303e58"/>
        <path id="Path_1007" data-name="Path 1007" d="M26.778,41.49a9.963,9.963,0,0,1-9.629-7.262.628.628,0,0,1-.024-.173v-.013a.653.653,0,0,1,1.3-.108,8.661,8.661,0,0,0,8.359,6.252h.082a8.539,8.539,0,0,0,8.257-6.448.652.652,0,1,1,1.263.323,9.843,9.843,0,0,1-9.51,7.43h-.094" fill="#303e58"/>
        <path id="Path_1008" data-name="Path 1008" d="M26.868,44.985a14.491,14.491,0,0,1-8.1-2.3A11.423,11.423,0,0,1,14,35.926a.652.652,0,1,1,1.263-.325,10.136,10.136,0,0,0,4.225,6,13.193,13.193,0,0,0,7.378,2.081A13.193,13.193,0,0,0,34.246,41.6a10.136,10.136,0,0,0,4.225-6,.652.652,0,0,1,1.263.325,11.423,11.423,0,0,1-4.762,6.758,14.491,14.491,0,0,1-8.1,2.3" fill="#303e58"/>
        <path id="Path_1009" data-name="Path 1009" d="M31.746,21.4a5.305,5.305,0,0,1-1.267-.15C27.94,20.6,27.635,18,27.827,16.715a.652.652,0,0,1,1.29.194c-.014.1-.342,2.559,1.683,3.08a4,4,0,0,0,.946.11c.182,0,.36-.012.541-.025a.652.652,0,0,1,.092,1.3c-.205.015-.408.028-.633.028" fill="#303e58"/>
        <path id="Path_1010" data-name="Path 1010" d="M32.346,21.377a.652.652,0,0,1-.054-1.3c1.987-.168,2.819-1.241,2.677-3.472-.189-.216-.909-.834-2.948-.834a3.458,3.458,0,0,0-2.99,1.38.646.646,0,0,1-.791.272,6.245,6.245,0,0,0-1.372-.387,5.94,5.94,0,0,0-1.368.386.652.652,0,0,1-.8-.272,3.492,3.492,0,0,0-3-1.379c-2.031,0-2.749.618-2.937.834-.14,2.23.69,3.3,2.679,3.472a.652.652,0,1,1-.11,1.3c-1.937-.164-4.176-1.173-3.857-5.047a.7.7,0,0,1,.056-.216c.078-.169.845-1.648,4.169-1.648a4.95,4.95,0,0,1,3.782,1.575,5.145,5.145,0,0,1,1.382-.31,5.179,5.179,0,0,1,1.381.31,4.911,4.911,0,0,1,3.772-1.575c3.336,0,4.1,1.479,4.18,1.648a.7.7,0,0,1,.056.216c.32,3.874-1.921,4.883-3.855,5.047l-.056,0" fill="#303e58"/>
        <path id="Path_1011" data-name="Path 1011" d="M21.99,21.4c-.225,0-.428-.014-.633-.028a.652.652,0,1,1,.093-1.3c.181.013.358.024.54.024a3.985,3.985,0,0,0,.947-.11c2.014-.534,1.7-2.98,1.682-3.084a.652.652,0,0,1,1.29-.187c.187,1.278-.124,3.869-2.644,4.534l-.008,0a5.305,5.305,0,0,1-1.267.15m1.109-.783h0Z" fill="#303e58"/>
        <path id="Path_1012" data-name="Path 1012" d="M35.606,17.034a.652.652,0,0,1-.158-1.285l3.5-.874a.652.652,0,1,1,.316,1.265l-3.495.874a.624.624,0,0,1-.159.02" fill="#303e58"/>
        <path id="Path_1013" data-name="Path 1013" d="M18.13,17.034a.624.624,0,0,1-.159-.02l-3.5-.874a.652.652,0,1,1,.316-1.265l3.5.874a.652.652,0,0,1-.158,1.285" fill="#303e58"/>
        <path id="Path_1014" data-name="Path 1014" d="M39.807,61.8a.652.652,0,0,1-.652-.652c0-2.218.763-7.8,7.836-11.709a36.352,36.352,0,0,1,4.3-1.848c.456-.175.927-.355,1.386-.536l.016-.006.2-.075.209-.078c.89-.357,1.581-.666,2.176-.974,1.981-1.019,2.955-2.04,3.068-3.214a.652.652,0,1,1,1.3.125c-.209,2.179-2.251,3.468-3.768,4.248-.633.328-1.364.655-2.3,1.03l-.02.007-.2.075-.209.078c-.462.183-.933.363-1.389.538a35.659,35.659,0,0,0-4.138,1.773c-2.151,1.189-7.16,4.6-7.16,10.566a.652.652,0,0,1-.652.652" fill="#303e58"/>
        <path id="Path_1015" data-name="Path 1015" d="M64.262,45.206c-2.724,0-3.468-.562-4.329-1.214-.176-.133-.357-.271-.565-.4-.161-.05-.372-.116-.613-.212a4.945,4.945,0,0,1-1.029-.538c-1.836-1.223-3.074-3.765-3.681-7.555a3.784,3.784,0,0,1-2.67-3.836,5.441,5.441,0,0,1,.359-2.08,2.774,2.774,0,0,1,.128-.275l.026-.05a.96.96,0,0,1,.1-.169,1.922,1.922,0,0,1,.95-.761c1.013-.374,4.38-1.813,5.464-4.743a.651.651,0,0,1,1-.3,30.177,30.177,0,0,0,15.6,5.1,2.01,2.01,0,0,1,1.433.671.581.581,0,0,1,.038.046,2.45,2.45,0,0,1,.283.466l.005.012a5.394,5.394,0,0,1,.364,2.078,3.786,3.786,0,0,1-2.668,3.836c-.6,3.789-1.832,6.33-3.674,7.558a5.292,5.292,0,0,1-1.02.533c-.243.1-.456.164-.618.214-.207.134-.389.272-.565.4-.861.652-1.6,1.214-4.328,1.214M53.04,29.652c-.007.014-.013.027-.02.04a1.512,1.512,0,0,0-.069.144l-.009.023a4.113,4.113,0,0,0-.262,1.587A2.464,2.464,0,0,0,54.711,34.1a.651.651,0,0,1,.557.553c.525,3.653,1.6,6.043,3.186,7.1a3.606,3.606,0,0,0,.77.4l.01,0c.211.084.392.14.552.188l.05.016a.687.687,0,0,1,.156.071c.285.179.52.358.728.515.729.552,1.256.95,3.542.95s2.812-.4,3.541-.95c.208-.157.443-.336.729-.515a.66.66,0,0,1,.155-.071l.05-.016c.16-.048.341-.1.552-.188a4.045,4.045,0,0,0,.775-.4c1.6-1.064,2.663-3.453,3.177-7.1A.652.652,0,0,1,73.8,34.1a2.461,2.461,0,0,0,2.031-2.654,4.122,4.122,0,0,0-.26-1.566,1.1,1.1,0,0,0-.112-.181.7.7,0,0,0-.486-.223,30.778,30.778,0,0,1-11.7-2.755A30.2,30.2,0,0,1,59.3,24.575a8.838,8.838,0,0,1-2.86,3.167,13.645,13.645,0,0,1-3.038,1.59.614.614,0,0,0-.314.25.535.535,0,0,1-.047.07m-.055.064h0m-.462-.461h0Z" fill="#303e58"/>
        <path id="Path_1016" data-name="Path 1016" d="M52.354,30.272a.648.648,0,0,1-.435-.167c-.463-.358-1.417-1.589-1.417-5.637,0-2.9,2.244-6.275,5.716-8.59a13.967,13.967,0,0,1,16.088,0c3.465,2.319,5.7,5.691,5.7,8.59,0,4.5-1.16,5.515-1.515,5.718l-.647-1.132a.166.166,0,0,0-.046.029c.009-.007.9-.784.9-4.615,0-2.438-2.059-5.454-5.123-7.506a12.67,12.67,0,0,0-14.64,0c-3.072,2.048-5.136,5.064-5.136,7.505,0,3.837.906,4.6.915,4.609a.591.591,0,0,1,.081.069l.013.013a.652.652,0,0,1-.461,1.113" fill="#303e58"/>
        <path id="Path_1017" data-name="Path 1017" d="M71.944,17.086a.652.652,0,0,1-.652-.652v-.013c0-.021,0-.041,0-.062A5.89,5.89,0,0,0,69.9,11.64a8.2,8.2,0,0,0-11.282,0,5.887,5.887,0,0,0-1.392,4.719c0,.021,0,.041,0,.062v.013a.652.652,0,0,1-1.3.021,7.225,7.225,0,0,1,1.728-5.693,9.492,9.492,0,0,1,13.212,0A7.23,7.23,0,0,1,72.6,16.455a.653.653,0,0,1-.652.631" fill="#303e58"/>
        <path id="Path_1018" data-name="Path 1018" d="M64.208,54.806a9.358,9.358,0,0,1-9.28-8.229.652.652,0,0,1,1.295-.159A8.053,8.053,0,0,0,64.21,53.5h.048a8.014,8.014,0,0,0,7.912-7.143.652.652,0,1,1,1.3.147,9.317,9.317,0,0,1-9.2,8.3h-.056" fill="#303e58"/>
      </g>
    </g>
  </g>
</svg>
</template>