<script lang="ts" setup>
import { BaseButton } from '@hfc/ui-core';
import AdultComputerIcon from '@/page/infrastructure/components/AdultComputerIcon.vue';
import SeniorIcon from '@/page/infrastructure/components/SeniorIcon.vue';
import AdultIcon from '@/page/infrastructure/components/AdultIcon.vue';
import ChildIcon from '@/page/infrastructure/components/ChildIcon.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl } = UseUrlHelper();
const items = [
  {
    component: AdultIcon,
    title: 'Individual and Family Plans',
    text: 'Explore individual health insurance plans from Covered California. The vast selection of individual health insurance plans offers you the perfect coverage.',
    href: getBaseUrl('/individual-health-insurance')
  },
  {
    component: ChildIcon,
    title: 'Child-Only Health Plans',
    text: 'Learn more about California’s child-only medical plans that provide free or low-cost health insurance coverage for eligible kids and teens.',
    href: getBaseUrl('/child-only-quote')
  },
  {
    component: SeniorIcon,
    title: 'Medicare and Senior Plans',
    text: 'Shop Covered Medicare health insurance plans for California. Get medical coverage for seniors (65 and over) and for individuals with disabilities.',
    href: getBaseUrl('/senior-health-insurance-options')
  },
  {
    component: AdultComputerIcon,
    title: 'Group and Small Business Plans',
    text: 'Learn about small group health insurance in California to help you select the right small business health insurance plans with the best rates.',
    href: getBaseUrl('/group-health-insurance')
  },
];
</script>

<template>
  <h2 class="text-gray-700 font-medium text-3xl">Life happens - so get covered & stay covered</h2>
  <h3 class="text-gray-500 font-medium text-xl">There is a health insurance plan for everyone</h3>
  <div class="lg:grid lg:grid-rows-2 lg:grid-cols-2">
    <div v-for="item in items" :key="item.title" class="flex flex-col lg:flex-row mt-5">
      <div class="pr-5">
        <component :is="item.component" class="lg:w-24"/>
      </div>
      <div class="pr-5">
        <h4 class="py-3 lg:py-0 uppercase text-gray-700 text-lg font-bold">{{item.title}}</h4>
        <p class="text-gray-500">{{item.text}}</p>
        <BaseButton class="text-blue uppercase font-bold text-sm" :ignore-styles="true" :href="item.href">
          Learn more <hfc-icon icon="arrow-right" class="ml-1 md:ml-2 mt-2" />
        </BaseButton>
      </div>
    </div>
  </div>
</template>