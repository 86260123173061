
<script setup lang="ts">
import { ref } from 'vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl } = UseUrlHelper();

const inputSearch = ref('');
const myinput = ref();

function redirect ():void {
  const searchParam = inputSearch.value;
  inputSearch.value = '';
  if (searchParam) {
    window.location.href = getBaseUrl(`/search?s=${searchParam}`);
  }
}
</script>
<template>
  <form @submit.prevent="redirect">
    <div class="flex items-center justify-center">
      <div class="flex border-2 rounded-full border-blue-500">
        <input v-autofocus type="text" class="px-6 py-2 w-60 md:w-max outline-none text-blue-500 md:text-5xl rounded-full" placeholder="Search..." v-model="inputSearch" ref="myinput">
        <button type="submit" class="flex items-center justify-center px-4 border-l bg-blue-500 rounded-r-full -ml-9 border-blue-500">
          <hfc-icon class="text-2xl hover:text-blue-300 text-white flex w-8 h-6" icon="search"/>
        </button>
      </div>
    </div>
  </form>
</template>