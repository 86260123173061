<script setup lang="ts">
import { CarrierList } from '@hfc/ui-core';

const carriers = [
  {
    id: CarrierList.Anthem,
    vertical: 'false'
  },
  {
    id: CarrierList.BlueShield,
    vertical: 'true'
  },
  {
    id: CarrierList.Kaiser,
    vertical: 'false'
  },
  {
    id: CarrierList.HealthNet,
    vertical: 'false'
  },
  {
    id: CarrierList.LACare,
    vertical: 'false'
  },
  {
    id: CarrierList.Molina,
    vertical: 'false'
  },
  {
    id: CarrierList.Sharp,
    vertical: 'false'
  },
  {
    id: CarrierList.CCHP,
    vertical: 'false'
  },
  {
    id: CarrierList.Valley,
    vertical: 'false'
  },
  {
    id: CarrierList.Western,
    vertical: 'false'
  },
  {
    id: CarrierList.Aetna,
    vertical: 'false'
  },
  {
    id: CarrierList.IEHP,
    vertical: 'false'
  },
];

</script>
<template>
  <div class="flex flex-wrap px-7">
    <div v-for="carrier in carriers" :key="carrier.id" class="flex items-center h-14 w-44 mr-5 md:mr-0 md:w-1/2 lg:w-1/3 xl:w-1/4 mb-5 lg:mb-2 md:pr-4">
      <carrier-logo  :carrier-id="carrier.id" :vertical="carrier.vertical" custom-class="flex w-auto h-auto grray-scale" />
    </div>
  </div>
</template>
