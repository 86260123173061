import { Post } from '@/blog/domain/Post';
import { PostRepository } from '@/blog/domain/PostRepository';
import { UseCase } from '@/shared/application/UseCase';

export class FindFeaturedPostUseCase implements UseCase<null, Post> {

  private repository: PostRepository;

  public constructor (repository: PostRepository) {
    this.repository = repository;
  }

  public async run (): Promise<Post> {
    return await this.repository.getFeaturedPost();
  }

}
