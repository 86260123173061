import { CarrierPage } from '@/page/domain/CarrierPage';

export const kaiserPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Kaiser Permanente <br> Health Insurance Coverage`,
  },
  aboutInfo: {
    visible: true,
    title: 'About Kaiser Permanente',
    content: `<p>Find the Lowest Prices Available on Kaiser Permanente Medical Insurance Plans or Get Discounts Through Our Covered California Options</p><br>
    <p>Kaiser Permanente is the oldest and largest Health Maintenance Organization (HMO) in the United States, serving eight states and the District of Columbia. Its largest membership base is in California with over 8 million, many of which are Covered California Kaiser plans.</p><br>
    <p>Kaiser has sought to lead the way in the healthcare model on many fronts. Its integrated delivery of care is what makes it so unique. A patient’s care is coordinated and connected all within one system from the beginning to the end. This can include but is not limited to an office visit, lab work, treatment, disease management and in-patient procedure to recovery.</p><br>
    <p>Kaiser Permanente online tools to manage your health
    <p>The organization comprises three parts:</p><br>
    <ul class="ml-10 list-disc">
    <li>Permanente Medical Groups, which are for-profit professional organizations</li>
    <li>Kaiser Foundation Health Plans, which are nonprofit, public-benefit corporations</li>
    <li>Kaiser Foundation Hospitals, which is a nonprofit, public-benefit corporation</li>
    </ul><br>
    <p>Kaiser Permanente’s history is one of innovation. After seeing the results of evolved healthcare programs for industrial workers in the ’30s and ’40s, Henry J. Kaiser started offering prepaid medical services to his shipyard workers in Richmond, California. This successful model of health coverage was later offered to the public in 1945, founding Kaiser Permanente. Today Kaiser Permanente’s headquarters is in Oakland, California.</p><br>

    <p>Kaiser Permanente insurance is one of the top three choices offered on the California State Exchange. Twenty-four percent of enrollees with the CA State Insurance Exchange have Covered California Kaiser plans.</p>`,
    textDirection: 'text-left',
  },
  sectionInfo: {
    visible: true,
    title: 'Take Note',
    content: `<p>Every year the National Committee for Quality Assurance (NCQA), a nonprofit organization, surveys the performance of over 1,000 health plans (Medicare, Medicaid and Private (Commercial)) and releases a report based on the findings.</p><br>
    <p>NCQA Health Plans Ratings for 2019-2020 gave Kaiser Foundation Health Plan in Northern California 4.5 out of 5 for its commercial health plan. These ratings are based on the health plan’s experience, prevention and treatment. Kaiser is proud that their plans are rated among the best in the nation with no plan rated lower than 4.5.</p>`,
    imageUrl: '/assets/img/bg/doctor_desk.webp'
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>For 2016 L.A. Care's individual and family plans offered through Covered California will provide access to</p>`,
    redirectUrl: 'https://www.lacare.org/health-plans/la-care-covered',
    subContent: [{
        title: '42',
        content: 'Lorem Ipsum'
      },
      {
        title: '19',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,559',
        content: 'Lorem Ipsum'
      },
      {
        title: '2,801',
        content: 'Lorem Ipsum'
      }
    ]
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>“Thrive” is Kaiser’s public campaign slogan. They seek to encourage all members to live life to the fullest with a big emphasis on preventative care and healthy lifestyle choices that impact overall well-being.</p><br>
    <p>Services such as these are added benefits to every member’s plan:</p><br>
    <p>Online tools such as emailing your doctor, scheduling routine appointments, refilling prescriptions and checking lab results make managing health care convenient and streamlined.</p><br>
    <p>Kaiser has also introduced “Video Visits” with a doctor, which provides a convenient way for members enrolled on Covered California Kaiser Plans to access care that is secure and efficient.</p>`,
    imageUrl: '/assets/img/bg/skating.webp'
  }
};
