<script lang="ts" setup>
import { inject } from 'vue';
import { LinkButton, usePlocState } from '@hfc/ui-core';
import { PageStore } from '@/page/infrastructure/store/PageStore';

const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);
</script>

<template>
  <div class="md:px-12 text-gray-500">
    <h4 class="text-blue text-xl font-bold pb-6 md:pb-10">The Covered California Website</h4>
    <div class="text-gray-500 text-left">
      <p class="pb-5">View income limits, get quotes, shop plans online. Submit an application from the convenience of your own home. If you need additional assistance with finding the right health plan at an affordable rate, call to speak with a certified agent:
        <LinkButton :href='`tel:${pageState.officePhoneNumber}`' custom-class="font-light text-base text-blue-400">
          {{ pageState.officePhoneNumber }}
        </LinkButton>
        . Or, visit a local Covered California Storefront. These services are all offered at no cost.</p>
      <p class="pb-5">Through the dedicated efforts of many California health insurance agents, navigators and assisters, our state experienced a drop in the number of those uninsured. From 2013 to 2014 the number of uninsured went from 15% to 10%. As of 2020, this number has dropped to 7% (Kaiser Family Foundation State Health Facts for California).</p>
      <p class="pb-5">There are still more people that need coverage. However, the California State Exchange is one of the most successful at implementing Obama Care (The Affordable Care Act) in the country.</p>
    </div>
  </div>
</template>
