import { AppConfig } from '@hfc/ui-core';
import { blogRouter } from '@/blog/infrastructure/blogRouter';
import { pageRouter } from '@/page/infrastructure/router';

export const appConfig: AppConfig = {
  tracking: {
    enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    metadata: {
      tags: {
        app_id: 'content-ui'
      }
    }
  },
  router: {
    routes: [
      ...blogRouter,
      ...pageRouter,
    ]
  },
  tailwind: {
    enabled: true
  },
  analytics: {
    enabled: false
  },
  screenIndicator: import.meta.env.VITE_SCREEN_INDICATOR_ENABLED === 'true',
  icons: {
    enabled: true
  },
};
