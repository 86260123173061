<script lang="ts" setup>
import VerticalTabs from '@/page/infrastructure/components/VerticalTabs.vue';
import AffordableHealth from '@/page/infrastructure/components/questions/AffordableHealth.vue';
import CoveredCalifornia from '@/page/infrastructure/components/questions/CoveredCalifornia.vue';
import PlansQuestion from '@/page/infrastructure/components/questions/PlansQuestion.vue';
import WhatIsAvailable from '@/page/infrastructure/components/questions/WhatIsAvailable.vue';
import WhenCanEnroll from '@/page/infrastructure/components/questions/WhenCanEnroll.vue';
import WhereToEnroll from '@/page/infrastructure/components/questions/WhereToEnroll.vue';
import IsCoverageRequired from '@/page/infrastructure/components/questions/IsCoverageRequired.vue';
import LicenseContent from '@/page/infrastructure/components/LicenseContent.vue';

const tabs = [
  {
    text: 'What Types of Health Plans are Available',
    component: PlansQuestion,
  },
  {
    text: 'Finding Affordable Health Insurance in California',
    component: AffordableHealth,
  },
  {
    text: 'The Covered California Website',
    component: CoveredCalifornia,
  },
  {
    text: 'Is Health Insurance Coverage Required?',
    component: IsCoverageRequired,
  },
  {
    text: 'Where Can I Enroll in a Health Plan?',
    component: WhereToEnroll,
  },
  {
    text: 'When Can I Enroll?',
    component: WhenCanEnroll,
  },
  {
    text: 'What is Available?',
    component: WhatIsAvailable,
  },
];
</script>

<template>
  <div class="bg-blue-100 text-center">
    <div class="max-w-7xl mx-auto md:px-6">
      <h3 class="pt-16 pb-12 text-gray-600 text-3xl font-medium">
        Let Us Answer Your Questions
      </h3>
      <div class="pb-14 md:pb-0">
        <vertical-tabs :tabs="tabs"></vertical-tabs>
      </div>
      <LicenseContent />
    </div>
  </div>
</template>