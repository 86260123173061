import { CarrierPage } from '@/page/domain/CarrierPage';
import { CarrierRepository } from '@/page/domain/CarrierRepository';
import { UseCase } from '@/shared/application/UseCase';
import { SfApiResponse } from '@hfc/ui-core';

export class GetCarrierDataUseCase implements UseCase<string, SfApiResponse<Partial<CarrierPage>>> {

  private repository: CarrierRepository;

  public constructor (repository: CarrierRepository) {
    this.repository = repository;
  }

  public async run (carrierId: string): Promise<SfApiResponse<Partial<CarrierPage>>> {
    return await this.repository.getCarrierData(carrierId);
  }

}
