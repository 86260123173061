<script setup lang="ts">
import { PrimaryButton, SecondaryButton } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getEnrollmentAppUri } = UseUrlHelper();

</script>
<template>
  <div class="flex flex-col pl-5 gap-3 justify-end">
    <div class="flex w-26">
      <SecondaryButton class="w-full justify-center" :outline="false" :href="getBaseUrl('/medicare?sign-up-webinar')">Sign Up for a Webinar</SecondaryButton>
    </div>
    <div class="flex w-26">
      <PrimaryButton class="w-full justify-center" target="_blank" :href="getEnrollmentAppUri('/medicare/appointment')">Schedule a Consultation</PrimaryButton>
    </div>
    <div class="flex w-26">
      <PrimaryButton class="w-full justify-center" target="_blank" :href="getBaseUrl('/senior-quote')">Get a Quote</PrimaryButton>
    </div>
  </div>
</template>
