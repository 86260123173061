import { MetalPlanPageSection } from '@/page/domain/MetalPlanPageSection';
import { MetalPlanPageRepository } from '@/page/domain/MetalPlanPageRepository';
import { UseCase } from '@/shared/application/UseCase';
import { SfApiResponse } from '@hfc/ui-core';

export class GetMetalPlanPageUseCase implements UseCase<string, SfApiResponse<Partial<MetalPlanPageSection>>> {

  private repository: MetalPlanPageRepository;

  public constructor (repository: MetalPlanPageRepository) {
    this.repository = repository;
  }

  public async run (planName: string): Promise<SfApiResponse<Partial<MetalPlanPageSection>>> {
    return await this.repository.getMetalPlanPageSections(planName);
  }

}
