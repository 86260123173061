import { h, render } from 'vue';
import DefinitionWord from '@/shared/infrastructure/components/DefinitionWord.vue';
import Options from '@/shared/infrastructure/dataSource/HighlighterConfig';

interface HighlighterOption {
  search: string;
  content: string;
}

class TextHighlighter {
  private mainWrapper: HTMLElement;
  private options: Array < HighlighterOption > ;
  private restrictedSelectors: Array<string> = ['.exclude-highlighter', '.breadcrumb', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'button', 'footer', 'the-heading'];

  constructor(element: HTMLElement, options: Array < HighlighterOption > ) {
    this.mainWrapper = element;
    this.options = options;
    this.removePreviousHighlighter();
    this.runHighlighter();
  }

  private runHighlighter(): void {
    this.options.forEach(option => {
      this.highlightText(option);
    });
  }

  private highlightText(option: HighlighterOption): void {
    const regex = new RegExp(`(${option.search})`, 'i');
    const fullWordRegex = new RegExp(`\\b${option.search}\\b`, 'i');

    const walker = document.createTreeWalker(this.mainWrapper, NodeFilter.SHOW_TEXT, null);
    let node: Node | null;

    while ((node = walker.nextNode()) !== null) {
      this.processNode(node, regex, fullWordRegex, option);
    }
  }

  private processNode(node: Node, regex: RegExp, fullWordRegex: RegExp, option: HighlighterOption): void {
    const nodeValue = node.nodeValue;
    const currentElement = node.parentElement;

    if (this.shouldSkipElement(currentElement)) {
      return;
    }

    if (nodeValue && fullWordRegex.test(nodeValue)) {
      const wrapperSpan = document.createElement('span');
      const fragment = document.createDocumentFragment();
      const parts = nodeValue.split(regex);

      let replaceCount = 0;
      parts.forEach((part) => {
        if (regex.test(part) && replaceCount < 1) {
          const highlightEl = this.createHighlightElement(part, option.content);
          fragment.appendChild(highlightEl);
          replaceCount++;
        } else {
          fragment.appendChild(document.createTextNode(part));
        }
      });

      wrapperSpan.appendChild(fragment);
      node.parentNode?.replaceChild(wrapperSpan, node);
    }
  }

  private shouldSkipElement(element: HTMLElement | null): boolean {
    if (!element) return true;

    return  this.restrictedSelectors.some(selector => element.closest(selector) !== null);
  }

  private createHighlightElement(part: string, content: string): HTMLElement {
    const wrapper = document.createElement('span');
    wrapper.className = 'highlighter';
    this.mountComponent(wrapper, part, content);

    return wrapper;
  }

  private mountComponent(wrapper: HTMLElement, word: string, definition: string): void {
    const menuComponent = h(DefinitionWord, { word, definition }, {});

    const container = document.createElement('span');
    render(menuComponent, container);
    wrapper.appendChild(container);
  }

  private removePreviousHighlighter(): void {
    const previousHighlighter = this.mainWrapper.querySelectorAll('.highlighter');
    if (previousHighlighter.length) {
      previousHighlighter.forEach((highlighter) => {
        const parent = highlighter.parentNode;
        if (parent) {
          while (highlighter.firstChild) {
            parent.insertBefore(highlighter.firstChild, highlighter);
          }
          parent.removeChild(highlighter);
        }
      });
    }
  }
}

export default {
  mounted(el: HTMLElement) {
    new TextHighlighter(el, Options);
  },
  updated(el: HTMLElement) {
    new TextHighlighter(el, Options);
  }
};
