<script lang="ts" setup>
import { BaseButton, viewportSize, VIEWPORT_NAMES } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { viewport } = viewportSize();

const { getCdnUrl, getQuoteUrl } = UseUrlHelper();
const getQuoteImg = (): string => {
  const smallDevices : string[] = [VIEWPORT_NAMES.ALL, VIEWPORT_NAMES.SM, VIEWPORT_NAMES.MD];

  return smallDevices.includes(viewport.value.name) ? '/assets/img/get_quote_sm.webp' : '/assets/img/get_quote.webp';
}
</script>

<template>
<div class="flex flex-col items-center justify-center py-16 lg:bg-cover lg:bg-fixed" :style="`background-image:url('${getCdnUrl('/assets/img/bg/get_quote_bg.webp')}')`">
  <h2 class="text-white mb-10 text-center font-medium">Find Affordable Health Insurance</h2>
  <div class="w-3/4 2xl:w-1/2">
    <BaseButton class="" :ignore-styles="true" :href="getQuoteUrl()">
      <img v-lazy="getCdnUrl(getQuoteImg())" alt="Get a quote" class="px-4 lg:px-0" />
    </BaseButton>
  </div>
</div>
</template>
