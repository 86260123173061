import { MetalPlanRouteCollection } from '@/page/infrastructure/router/MetalPlanRouteCollection';
import Bronze60 from '@/page/infrastructure/views/metalTierPlans/Bronze60.vue';
import Bronze60Hsa from '@/page/infrastructure/views/metalTierPlans/Bronze60Hsa.vue';
import Silver from '@/page/infrastructure/views/metalTierPlans/Silver.vue';
import Silver70 from '@/page/infrastructure/views/metalTierPlans/Silver70.vue';
import Silver73 from '@/page/infrastructure/views/metalTierPlans/Silver73.vue';
import Silver87 from '@/page/infrastructure/views/metalTierPlans/Silver87.vue';
import Silver94 from '@/page/infrastructure/views/metalTierPlans/Silver94.vue';
import Gold80 from '@/page/infrastructure/views/metalTierPlans/Gold80.vue';
import Platinum90 from '@/page/infrastructure/views/metalTierPlans/Platinum90.vue';

export const metalPlanRouter = new MetalPlanRouteCollection([
  {
    href: '/bronze',
    title: '2025 Covered California Bronze 60: Peace with Medical Bills',
    description: 'The Bronze 60 Plan is a Covered California health insurance option offering peace of mind coverage with a low monthly premium. Learn more about the plan here!',
    metallicPlanName: 'bronze_60',
    component: Bronze60
  },
  {
    href: '/bronze-60-hsa',
    title: '2025 Covered California Bronze 60 HSA: The Sister Plan',
    description: 'The Bronze 60 HSA Plan is a Covered California sister plan to the Bronze 60, but offers HSA and tax benefits with coverage. Read about the insurance plan here!',
    metallicPlanName: 'bronze_60_hsa',
    component: Bronze60Hsa
  },
  {
    href: '/silver',
    title: '2025 Covered California Silver: Popular Middle Ground',
    description: 'The Silver Plans are Covered California affordable health insurances that balance costs with benefits. Learn more about the popular insurance plans here!',
    metallicPlanName: 'silver',
    component: Silver
  },
  {
    href: '/silver-70',
    title: '2025 Covered California Silver 70: Coverage & Affordability',
    description: 'The Silver 70 Plan is a Covered California health insurance option that offers a mix of coverage and affordability. Learn how this plan can benefit you today!',
    metallicPlanName: 'silver_70',
    component: Silver70
  },
  {
    href: '/silver-73',
    title: '2025 Covered California Silver 73: Enhanced Plan',
    description: 'The Silver 73 Plan is a Covered California option for those eligible with a $0 deductible and reduced service fees. See how you can benefit from this plan here!',
    metallicPlanName: 'silver_73',
    component: Silver73
  },
  {
    href: '/silver-87',
    title: '2025 Covered California Silver 87: Enhanced Plan',
    description: 'The Silver 87 Plan is a Covered California that offers qualified members low copays and a $0 deductible. Find out more about this health insurance plan here!',
    metallicPlanName: 'silver_87',
    component: Silver87
  },
  {
    href: '/silver-94',
    title: '2025 Covered California Silver 94: Enhanced Plan',
    description: 'The Silver 94 Plan is a Covered California option for those who qualify, offering low copays and a $0 deductible. Learn more about this insurance plan here!',
    metallicPlanName: 'silver_94',
    component: Silver94
  },
  {
    href: '/gold-80',
    title: '2025 Covered California Gold 80 Plan: Gold is Golden',
    description: 'The Gold 80 Plan is a Covered California option for those who qualify. It includes a $0 deductible. Learn more about this health insurance option here!',
    metallicPlanName: 'gold_80',
    component: Gold80
  },
  {
    href: '/platinum-90',
    title: '2025 Covered California Platinum 90: Top of the Line',
    description: 'The Platinum 90 Plan is a Covered California option with comprehensive benefits, a $0 deductible, and minimal out-of-pocket costs. Learn about the plan here!',
    metallicPlanName: 'platinum_90',
    component: Platinum90
  }
]);
