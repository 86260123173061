<script lang="ts" setup>
import { Breadcrumb } from '@/page/domain/Breadcrumb';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl } = UseUrlHelper()

defineProps<{
  breadcrumbs: Array<Breadcrumb>
}>();

</script>
<template>
  <div class="flex flex-row py-2 breadcrumb">
    <div v-for="(breadcrumb, index) in breadcrumbs" :key="index">
      <a :href="getBaseUrl(breadcrumb.href)" v-if="index !== breadcrumbs.length - 1" class="cursor-pointer text-blue-500 hover:text-blue-700">
        {{ breadcrumb.label }}
      </a>
      <span v-else>{{ breadcrumb.label }}</span>
      <span v-if="index < breadcrumbs.length - 1" class="mx-2"> > </span>
    </div>
  </div>
</template>
