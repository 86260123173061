import { Post } from '@/blog/domain/Post';
import { PostRepository } from '@/blog/domain/PostRepository';

export class PostMemory implements PostRepository {

  public async getFeaturedPost (): Promise<Post> {
    await this.sleep(3000);

    const postResponse = {
      title: 'Did You Know Some Health Care Costs Are Tax-Deductible?',
      excerpt: 'If your insurance doesn’t fully cover your medical bills, you may be able to use them to reduce your tax bill. Only some medical costs are tax-deductible, so it’s important to…',
      content: 'content',
      slug: 'slug',
      author: {
        id: 123,
        name: 'Wendy Barnett',
        email: 'a@a.com',
        avatar: 'string'
      },
      image: 'image.png',
      id: 23,
      date: 1678994399980
    };

    return new Post(postResponse);
  }

  private sleep (ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
