<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import MetalTierCard from '@/page/infrastructure/components/metalPlans/MetalTierCard.vue';
import MetalTierContent from '@/page/infrastructure/views/MetalTierContent.vue';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import ParagraphWithIcon from '@/page/infrastructure/components/ParagraphWithIcon.vue';
import { UseMetallicPlansStore } from '@/page/infrastructure/composition/UseMetallicPlansStore';

const props = defineProps<{
  planName: string;
}>();

const { metalPlanPageSections } = UseMetallicPlansStore(props.planName);
</script>
<template>
  <MetalTierContent v-if="metalPlanPageSections" :metal-plan-page-sections="metalPlanPageSections" :plan-name="planName">
    <template v-slot:HighlightSection="{ pageData, plan }">
      <div v-if="pageData" class="w-full py-16">
        <div class="flex flex-col max-w-7xl mx-auto px-6 lg:px-3">
          <TextAndImage div-class="py-2 lg:py-12" text-class="flex flex-col lg:w-3/5 order-1" image-class="flex flex-1 order-2" :content-direction="pageData.contentDirection">
            <template #text>
              <div class="flex flex-col mb-8" v-html="pageData.content"></div>
              <ParagraphWithIcon v-for="subContent in pageData.subContent" :text="subContent" icon="chevron-right" />
            </template>
            <template #image>
              <div v-if="plan" class="flex flex-row w-full mt-10 lg:mt-0">
                <MetalTierCard :plan="plan" :highlight="true" />
              </div>
            </template>
          </TextAndImage>
          <div v-if="pageData.redirectUrl" class="flex justify-center">
            <PrimaryButton :href="pageData.redirectUrl" size="xl" class="text-xl font-semibold">{{ pageData.redirectText }}</PrimaryButton>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:InfoBanner="{ pageData }">
      <div v-if="pageData" class="w-full bg-blue py-16">
        <div class="flex flex-1 flex-col items-center justify-center max-w-7xl mx-auto relative space-y-6 px-6 lg:px-3">
          <h3 class="text-white font-bold" v-html="pageData.title"></h3>
          <div class="flex w-full text-white flex-col" v-html="pageData.subtitle"></div>
          <div class="flex flex-col text-white w-full lg:w-2/3 mt-2 first:mt-0 space-y-6">
            <ParagraphWithIcon v-for="subContent in pageData.subContent" :text="subContent" icon="chevron-right" icon-color="text-white" class="text-left text-white" />
          </div>
          <div class="flex w-full text-white flex-col" v-html="pageData.content"></div>
        </div>
      </div>
    </template>
  </MetalTierContent>
</template>
