<script lang="ts" setup>
import { BaseButton, viewportSize, VIEWPORT_NAMES } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { viewport } = viewportSize();

const { getCdnUrl, getBaseUrl } = UseUrlHelper();

const services = [
  {
    title: 'Obama Care',
    text: 'Covered California is the Golden State’s official health exchange marketplace where individuals, families and small businesses can find high-quality, low-cost California government health insurance.',
    href: getBaseUrl('/obamacare'),
    bg: '/assets/img/bg/doctor.webp'
  },
  {
    title: 'CA Health Exchange',
    text: 'Covered California is the Golden State’s official health exchange marketplace where individuals, families and small businesses can find high-quality, low-cost California government health insurance.',
    href: getBaseUrl('/covered-california'),
    bg: '/assets/img/bg/stethoscope.webp'
  },
  {
    title: 'Income Guidelines',
    text: 'Learn about Obamacare income guidelines in California using our income limits chart, and see if you’re eligible for government assistance.',
    href: getBaseUrl('/covered-california/income-limits'),
    bg: '/assets/img/bg/office.webp'
  },
  {
    title: 'Online Services',
    text: 'Learn about the Covered California website. Find easy online enrollment. Set up your account, log in, buy insurance and more on the California health marketplace website.',
    href: getBaseUrl('/covered-california-website'),
    bg: '/assets/img/bg/calculator.webp'
  },
];
</script>

<template>
<div class="flex flex-col sm:flex-row sm:flex-wrap my-8">
  <div v-for="(service, i) in services" :key="service.title" class="flex flex-col sm:w-1/2 p-3">
    <div class="shadow-lg rounded-lg bg-white border-2 border-gray-50 overflow-hidden">
      <img :src="getCdnUrl(service.bg)" alt="service.title" />
      <div class="flex flex-1 flex-col items-center py-3 lg:py-6 px-4 lg:px-10 space-y-1 sm:space-y-3">
        <div class="uppercase font-semibold text-lg lg:text-2xl text-blue-500">
          {{ service.title }}
        </div>
        <div class="md:h-24 text-center text-sm lg:text-base">
          <p>{{ service.text }}</p>
        </div>
        <div>
          <BaseButton class="uppercase font-normal text-md lg:text-lg text-black" :ignore-styles="true" :href="service.href">
            <u>Learn more</u>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
