<script lang="ts" setup>
import { UseMetallicPlansStore } from '@/page/infrastructure/composition/UseMetallicPlansStore';
import MetalTierContent from '@/page/infrastructure/views/MetalTierContent.vue';

const props = defineProps<{
  planName: string;
}>();

const { metalPlanPageSections } = UseMetallicPlansStore(props.planName);
</script>
<template>
  <MetalTierContent v-if="metalPlanPageSections" :metal-plan-page-sections="metalPlanPageSections" :plan-name="planName">
  </MetalTierContent>
</template>
