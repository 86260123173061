<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import { MetalPlanPageData } from '@/page/domain/MetalPlanPageData';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl, getBaseUrl } = UseUrlHelper();

defineProps<{
  pageData: Partial<MetalPlanPageData>;
}>();
</script>
<template>
  <div class="w-full bg-gradient-to-r from-blue-900 to-blue-500">
    <div class="flex flex-col w-full max-w-7xl mx-auto px-3 overflow-hidden">
      <div v-if="pageData.imageUrl" class="relative flex-1 opacity-20">
        <img class="absolute min-w-[700px] lg:-top-[110px] left-1/2 transform -translate-x-1/2" :src="getCdnUrl(pageData.imageUrl)">
      </div>
      <div class="relative flex flex-col space-y-6 px-8 lg:px-14 py-16 bg-cover">
        <h1 class="text-white font-bold" v-html="pageData.title"></h1>
        <h4 class="text-white mb-2" v-html="pageData.subtitle"></h4>
        <div v-if="pageData.redirectUrl" class="flex">
          <PrimaryButton class="text-xl font-semibold" size="xl" :href="getBaseUrl(pageData.redirectUrl)">Explore More</PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>