<script setup lang="ts">
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();
</script>
<template>
  <div class="flex flex-col max-w-7xl w-full mx-auto py-16 px-6 lg:px-3">
    <div class="flex flex-col w-full items-center">
      <h2 class="font-bold mb-14">Customer Reviews</h2>
    </div>
    <div class="flex flex-col lg:flex-row lg:space-x-10">
      <div class="flex flex-col flex-1">
        <img class="rounded" :src="getCdnUrl('/assets/img/bg/guys_working.webp')" />
        <div class="flex w-full rounded-md justify-center mt-8 bg-gray-50 ">
          <div class="elfsight-app-27248d0a-c467-4a86-b105-8f6f2478f7fa" data-elfsight-app-lazy></div>
        </div>
      </div>
      <div class="flex flex-1 mt-10 lg:mt-0">
        <div class="elfsight-app-885a99a9-f5ba-4191-8293-e9e23f1e370c" data-elfsight-app-lazy></div>
      </div>
    </div>
  </div>
</template>