<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import { CarrierPageData } from '@/page/domain/CarrierPageData';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl, getQuoteUrl } = UseUrlHelper();

defineProps<{
  carrierData: Partial<CarrierPageData>;
}>();
</script>

<template>
  <div class="w-full" :class="carrierData.bgColor">
    <TextAndImage div-class="px-6" :content-direction="carrierData.contentDirection" :text-class="!carrierData.imageUrl ? 'w-full' : 'flex flex-col lg:w-3/5'">
      <template #text>
        <div class="flex flex-col h-full items-center justify-center lg:mt-0" :class="{ 'mt-10' : carrierData.imageUrl }">
          <h2 class="mb-6 text-2xl lg:text-3xl" v-html="carrierData.title"></h2>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.content"></div>
          <PrimaryButton v-if="carrierData.redirectUrl" class="text-sm mt-6 lg:text-md uppercase" size="lg" :href="getQuoteUrl()">{{ carrierData.redirectButtonText }}</PrimaryButton>
          <ImageSection v-if="carrierData.imageSection" class="mt-6" :content="carrierData.imageSection"></ImageSection>
        </div>
      </template>
      <template #image v-if="carrierData.imageUrl">
        <div class="flex flex-1 justify-center">
          <img class="flex max-h-96 rounded" :src="getCdnUrl(carrierData.imageUrl)">
        </div>
      </template>
    </TextAndImage>
  </div>
</template>