import { MetalPlanData } from '@/page/domain/MetalPlanData';
import { MetalPlanRepository } from '@/page/domain/MetalPlanRepository';
import { UseCase } from '@/shared/application/UseCase';
import { SfApiResponse } from '@hfc/ui-core';

export class GetMetalPlanUseCase implements UseCase<string, SfApiResponse<Array<MetalPlanData>>> {

  private repository: MetalPlanRepository;

  public constructor (repository: MetalPlanRepository) {
    this.repository = repository;
  }

  public async run (): Promise<SfApiResponse<Array<MetalPlanData>>> {
    return await this.repository.getPlans();
  }

}
