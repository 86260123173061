<script setup lang="ts">
import { PrimaryButton, AltPrimaryButton } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const props = withDefaults(defineProps<{
  variant?: boolean,
  targetBlank?: boolean,
  url?: string
}>(), {
  variant: false,
  targetBlank: false
});

const { getQuoteUrl } = UseUrlHelper();

const target = props.targetBlank ? '_blank' : '_self';
const url = props.url ? props.url : getQuoteUrl();

</script>

<template>
  <AltPrimaryButton v-if="variant" :target="target" v-bind="$props" :href="url">
    <slot/>
  </AltPrimaryButton>
  <PrimaryButton v-else v-bind="$props" :href="url">
    <slot/>
  </PrimaryButton>
</template>
