<script lang="ts" setup>
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();
defineProps<{
  planName: string,
  iconImg: string,
}>();
</script>

<template>
  <div class="flex items-center md:pl-8 mt-1">
    <img width="77" height="77" v-lazy="getCdnUrl(iconImg)" :alt="`${planName} icon`"  class=""/>
    <p class="text-blue capitalize font-bold text-base ml-8">{{planName}}<br><slot>Plan</slot></p>
  </div>
</template>
