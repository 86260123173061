<script setup lang="ts">
import { computed } from 'vue';
import { MenuItem } from '@/shared/domain/MenuItem';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

defineProps<{
  items: Array<MenuItem>
}>();

const { getBaseUrl, getCdnUrl } = UseUrlHelper();

const getHref = computed(() => (menuItem: MenuItem) => menuItem.isAbsolute ? menuItem.href : getBaseUrl(menuItem.href));

</script>
<template>
  <div v-if="items.length">
    <a v-for="item in items" @click="getHref(item)" :target="item.isBlank ? '_blank' : '_self'" :key="item.label" :href="getHref(item)" class="flex items-center whitespace-nowrap py-1 rounded-none font-light text-gray-500 md:text-blue-400 hover:text-blue hover:bg-gray-100 px-7">
      <img v-if="item.prefixImg" width="27" height="27" :src="`${getCdnUrl(item.prefixImg)}`" :alt="`${item.label} icon`" class="mr-2" />
      {{ item.label }}
    </a>
  </div>
</template>
