import { Ploc } from '@hfc/ui-core';
import { FindFeaturedPostUseCase } from '@/blog/application/FindFeaturedPostUseCase';
import { Post } from '@/blog/domain/Post';
import { PostMemory } from '@/blog/infrastructure/dataSource/PostMemory';
const repository = new PostMemory();
const findFeatured = new FindFeaturedPostUseCase(repository);

export interface PostStoreInterface {
  featured: Post
  featuredLoaded: boolean
}

const initialState: PostStoreInterface = {
  featured: {} as Post,
  featuredLoaded: false
};

export class PostStore extends Ploc<PostStoreInterface> {

  public constructor () {
    super(initialState);
  }

  public changeLoadedState (): void {
    this.changeState({ ...this.state, featuredLoaded: !this.state.featuredLoaded });
  }

  public async getFeaturedPost (): Promise<void> {
    this.changeState({ ...this.state, featuredLoaded: false });
    const featured = await findFeatured.run();
    this.changeState({ ...this.state, featured: featured });
    this.changeState({ ...this.state, featuredLoaded: true });
  }

}
