import HeaderCarrierList from '@/shared/infrastructure/components/heading/HeaderCarrierList.vue';

export default [
  {
    label: 'Health Insurance Options',
    href: '',
    description: null,
    customClass: '',
    prefixImg: '',
    subMenu: [
      {
        label: 'Individual',
        href: '/individual-health-insurance',
        description: null,
        customClass: '',
        prefixImg: '',
        subMenu: []
      },
      {
        label: 'Family',
        href: '/family-health-plans',
        description: null,
        customClass: '',
        prefixImg: '',
        subMenu: []
      },
      {
        label: 'Small Business',
        href: '/group-health-insurance',
        description: null,
        customClass: '',
        prefixImg: '',
        subMenu: []
      },
      {
        label: 'Senior Plans',
        href: '/senior-health-insurance-options',
        description: null,
        customClass: '',
        prefixImg: '',
        subMenu: []
      },
      {
        label: 'Travel Insurance',
        href: '/travel-health-insurance',
        description: null,
        customClass: '',
        prefixImg: '',
        subMenu: []
      },
      {
        label: 'Newborn',
        href: '/newborn-health-insurance-ca-coverage',
        description: null,
        customClass: '',
        prefixImg: '',
        subMenu: []
      },
      {
        label: 'Supplemental Health Plans',
        href: '/supplemental-plans',
        description: null,
        customClass: '',
        prefixImg: '',
        subMenu: []
      },
      {
        label: 'Medi-Cal',
        href: '/covered-california/health-insurance-companies/medi-cal',
        description: null,
        customClass: '',
        prefixImg: '',
        subMenu: []
      }
    ]
  },
  {
    label: 'Metallic plans',
    href: '/covered-california/plans',
    description: null,
    customClass: 'min-w-[540px]',
    prefixImg: '',
    subMenu: [
      {
        label: 'Bronze',
        href: '/covered-california/plans/bronze',
        description: null,
        customClass: '',
        prefixImg: '/assets/img/plans/bronze.webp',
        subMenu: []
      },
      {
        label: 'Silver',
        href: '/covered-california/plans/silver',
        description: null,
        customClass: '',
        prefixImg: '/assets/img/plans/silver.webp',
        subMenu: []
      },
      {
        label: 'Gold',
        href: '/covered-california/plans/gold-80',
        description: null,
        customClass: '',
        prefixImg: '/assets/img/plans/gold.webp',
        subMenu: []
      },
      {
        label: 'Platinum',
        href: '/covered-california/plans/platinum-90',
        description: null,
        customClass: '',
        prefixImg: '/assets/img/plans/platinum.webp',
        subMenu: []
      },
      {
        label: 'Minimum Coverage',
        href: '/covered-california/plans/minimum-coverage',
        description: null,
        customClass: '',
        prefixImg: '/assets/img/plans/catastrophic.webp',
        subMenu: []
      },
    ]
  },
  {
    label: 'Health Insurance Providers',
    href: '/covered-california/health-insurance-companies',
    description: null,
    customClass: '',
    customComponent: HeaderCarrierList,
    prefixImg: '',
    subMenu: []
  }];
