<script setup lang="ts">
import { computed } from 'vue';
import { viewportSize } from '@hfc/ui-core';
import AnimatedPlaceholder from '@/shared/infrastructure/components/AnimatedPlaceholder.vue';
import AnimatedImageSkeleton from '@/shared/infrastructure/components/AnimatedImageSkeleton.vue';
const { viewport } = viewportSize();

const props = defineProps<{
  horizontal?: boolean,
  showExcerpt: boolean,
}>();

const isHorizontal = computed(() => props.horizontal && !viewport.value.isMobile);
const directionClass = computed(() => isHorizontal.value ? 'flex-row' : 'flex-col');
const imageSectionBorderClass = computed(() => isHorizontal.value ? 'border-r-4 border-r-yellow-400' : 'border-b-4 border-b-yellow-400');
const imageSectionRoundedClass = computed(() => isHorizontal.value ? 'rounded-l-2xl' : 'rounded-t-2xl');
const contentSectionRoundedClasses = computed(() => isHorizontal.value ? 'rounded-r-2xl' : 'rounded-b-2xl');

</script>
<template>
  <div class="flex w-full" :class="directionClass">
    <div class="flex-1 flex flex-col rounded-tl-2xl" :class="[imageSectionRoundedClass, imageSectionBorderClass]">
      <AnimatedImageSkeleton class="min-h-[324px] rounded-tl-2xl"
        :class="{ 'rounded-tr-2xl': !isHorizontal, 'rounded-bl-2xl': isHorizontal }" />
    </div>
    <div class="min-h-[324px] flex-1 flex flex-col justify-between p-8 text-gray-500 box-shadow-highlighted space-y-3"
      :class="contentSectionRoundedClasses">
      <div>
        <AnimatedPlaceholder class="w-full h-10 mb-4" />
        <AnimatedPlaceholder class="w-24 h-4" />
      </div>
      <div v-if="showExcerpt" class=" font-normal text-base leading-relaxed text-left space-y-2">
        <AnimatedPlaceholder class="w-3/4 h-3" />
        <AnimatedPlaceholder class="w-full h-3" />
        <AnimatedPlaceholder class="w-1/3 h-3" />
      </div>
      <div class="flex justify-between items-center">
        <AnimatedPlaceholder class="w-28 h-10" />
        <AnimatedPlaceholder class="w-28 h-4" />
      </div>
    </div>
  </div></template>
