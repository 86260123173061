<script setup lang="ts">
import { inject, onMounted, ref } from 'vue';
import { RouterView, useRoute, usePlocState } from '@hfc/ui-core';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import BreadcrumbGenerator from '@/page/infrastructure/components/BreadcrumbGenerator.vue';
import TheFooter from '@/shared/infrastructure/components/footer/TheFooter.ce.vue';
import { Breadcrumb } from '@/page/domain/Breadcrumb';
import TheHeading from '@/shared/infrastructure/components/heading/TheHeading.ce.vue';

const route = useRoute();
const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);
const breadcrumbs = ref<Array<Breadcrumb>>(route.meta.breadcrumbs as Array<Breadcrumb>);

onMounted(() => {
  pageStore.setPhoneEnabledForRoute(route.path);
});
</script>

<template>
  <div class="min-h-screen bg-gray-100 flex flex-col">
    <div class="sticky z-50 top-0 min-h-[112px]">
    <Suspense>
      <TheHeading class="dark" :phone-for-current-route="pageState.phoneForCurrentRoute"></TheHeading>
    </Suspense>
  </div>
    <div v-if="breadcrumbs && breadcrumbs.length > 1" class="flex w-full max-w-7xl mx-auto px-6">
      <BreadcrumbGenerator :breadcrumbs="breadcrumbs" />
    </div>
    <div
      class="flex flex-grow relative flex-col md:flex-row max-w-7xl mx-auto w-full px-6 py-4 min-h-[calc(100vh-112px)]">
      <router-view />
    </div>
    <Suspense>
      <TheFooter />
    </Suspense>
  </div>
</template>
