<script setup lang="ts">
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

defineProps<{
  carrierId?: string;
}>();

const { getBaseUrl, getCdnUrl } = UseUrlHelper();
</script>

<template>
  <div v-if="!carrierId" class="flex justify-start">
    <span class="sr-only">Health Insurance for California</span>
    <a :href="getBaseUrl('/')" class="min-w-[50px]" tabindex="0">
      <img :src="getCdnUrl('/assets/img/logos/hfc_stack.png')"
        class="h-12 md:h-10 lg:h-16 pr-2 focus-visible:outline-none dark:fill-white"
        alt="Health Insurance for California">
    </a>
    <img :src="getCdnUrl('/assets/img/logos/cov_cal.png')"
      class="h-12 md:h-10 lg:h-16 pl-2 border-l border-blue-200 focus-visible:outline-none dark:fill-white"
      alt="Covered California">
  </div>
  <carrier-logo v-else :carrier-id="carrierId" :no-link="true" :vertical="true"
    class="w-28 md:w-36 pr-2 pl-2 focus-visible:outline-none dark:fill-white h-12 md:h-10 lg:h-16 flex items-center" />
</template>
