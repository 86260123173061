<script setup lang="ts">
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import { CarrierList, PrimaryButton } from '@hfc/ui-core';
import BannerCarrier from '@/page/infrastructure/components/carrier/BannerCarrier.vue';

const props = defineProps<{
  carrierId: CarrierList;
}>();

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:SectionInfo="{ carrierData, carrierId }">
      <div v-if="carrierData" class="w-full py-16">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="carrierData.title"></h2>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.content"></div>
          <BannerCarrier v-if="carrierData.imageSection" :content="carrierData.imageSection"></BannerCarrier>
          <div>
            <div v-for="item in carrierData.subContent" class="flex flex-row ml-3">
              <div class="relative mt-3 md:mt-2 mr-2 rounded-full bg-yellow-500 w-2 h-2 md:w-3 md:h-3"></div>
              <div class="flex flex-1 flex-col">
                <h3 class="mb-2" v-html="item.title"></h3>
                <div class="mb-6" v-html="item.content"></div>
              </div>
            </div>
          </div>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.secondContent"></div>
        </div>
      </div>
    </template>
    <template v-slot:afterSectionInfo="{ pageData, carrierId }">
      <div class="w-full bg-slate-100">
        <SectionInfo v-if="pageData.extraSection1" :carrier-data="pageData.extraSection1" :carrier-id="carrierId" ></SectionInfo>
      </div>
    </template>
    <template v-slot:NetworkInfo="{ carrierData }">
      <div v-if="carrierData" class="w-full py-16">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-6" v-html="carrierData.title"></h2>
          <BannerCarrier v-if="carrierData.imageSection" :content="carrierData.imageSection"></BannerCarrier>
          <div class="flex flex-col lg:flex-row">
            <div v-for="item in carrierData.subContent" class="flex flex-1 flex-row px-8 lg:first:border-r-2">
              <div class="flex flex-col">
                <h3 class="mb-2" v-html="item.title"></h3>
                <div class="mb-6" v-html="item.content"></div>
              </div>
            </div>
          </div>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.content"></div>
          <PrimaryButton class="text-sm lg:text-md uppercase" size="lg" :href="carrierData.redirectUrl">{{ carrierData.redirectButtonText }}</PrimaryButton>
        </div>
      </div>
    </template>
  </CarrierPlan>
</template>
