import { CarrierList } from '@hfc/ui-core';
import { RouteRecordRaw } from 'vue-router';
import { RouteMeta } from '@/shared/application/RouteMeta';
import { Component } from 'vue';

export class CarrierRouteCollection {
  private routes: Array<RouteRecordRaw & { meta: RouteMeta }> = [];

  constructor(carriers: Array<{ href: string; title: string; description: string, carrierId: CarrierList, breadcrumbLabel: string, component: Component }>) {
    this.routes = carriers.map(carrier => ({
      path: `/covered-california/health-insurance-companies/${carrier.href}`,
      name: this.getCarrierRouteName(carrier.carrierId),
      meta: {
        layout: 'HomePage',
        title: carrier.title,
        description: carrier.description,
        theme: 'light',
        quoteBanner: true,
        breadcrumbs: [
          {
            label: 'California Health Insurance',
            href: '/'
          },
          {
            label: 'Covered California',
            href: '/covered-california'
          },
          {
            label: 'Health Insurance Companies',
            href: '/covered-california/health-insurance-companies'
          },
          {
            label: carrier.breadcrumbLabel,
            href: `/covered-california/health-insurance-companies/${carrier.href}`
          }
        ]
      },
      props: {
        carrierId: carrier.carrierId
      },
      component: carrier.component
    }));
  }

  private getCarrierRouteName(carrierId: CarrierList) {
    const splittedCarrierId = carrierId.split('_');
    const capitalizedWords = splittedCarrierId.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    return `CarrierPage${capitalizedWords.join('')}`;
  }

  public getRoutes() {
    return this.routes;
  }
}
