<script setup lang="ts">
import { PrimaryButton } from '@hfc/ui-core';
import { ImageSection } from '@/page/domain/CarrierPageData';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl, getBaseUrl } = UseUrlHelper();

defineProps<{
  content: Partial<ImageSection>;
}>();
</script>

<template>
  <div class="w-full overflow-hidden">
    <div v-if="content.imageUrl" class="relative">
      <img class="absolute min-w-[1000px] left-1/2 transform -translate-x-1/2" :src="getCdnUrl(content.imageUrl)">
    </div>
    <div class="relative flex w-full flex-col space-y-2 lg:space-y-6 px-8 py-6 md:py-12 lg:py-20" :class="[content.justifyContent]">
      <div :class="[content.contentWidth, content.textDirection]">
        <h2 class="text-lg md:text-2xl lg:text-4xl" v-html="content.title"></h2>
        <div v-if="content.redirectButtonUrl" class="flex mt-6">
          <PrimaryButton class="text-sm lg:text-md uppercase" size="lg" :href="content.redirectButtonUrl">{{ content.redirectButtonText }}</PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>