<script setup lang="ts">
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();

withDefaults(defineProps<{
  customClass: string;
}>(), {
  customClass: 'lg:h-20',
});
</script>

<template>
  <div class="flex flex-col lg:flex-row justify-center px-6 lg:px-0 py-3 bg-yellow" :class="customClass">
    <div class="flex items-center justify-center h-full px-14">
      <img v-lazy="getCdnUrl('/assets/img/cov_cal_certified.webp')" alt="Covered california" class="w-36 lg:w-auto pb-4 lg:pb-0 h-full object-contain" />
    </div>
    <div class="flex items-center justify-center h-full px-14 border-y-2 lg:border-y-0 lg:border-x-2 border-blue">
      <img v-lazy="getCdnUrl('/assets/img/in_business.webp')" alt="CC In business since" class="w-44 lg:w-auto py-4 lg:py-0 h-full object-contain"/>
    </div>
    <div class="flex items-center justify-center h-full px-14">
      <img v-lazy="getCdnUrl('/assets/img/licensed.webp')" alt="CC Licensed" class="w-48 lg:w-auto pt-4 lg:pt-0 h-full object-contain"/>
    </div>
  </div>
</template>
