<script setup lang="ts">
import { inject } from 'vue';
import { usePlocState, CarrierList } from '@hfc/ui-core';
import ServicesSection from '@/page/infrastructure/components/ServicesSection.ce.vue';
import LicenseContent from '@/page/infrastructure/components/LicenseContent.vue';
import { PageStore } from '@/page/infrastructure/store/PageStore';
import { CarrierPage } from '@/page/domain/CarrierPage';
import LogoSection from '@/page/infrastructure/components/carrier/LogoSection.vue';
import AboutInfo from '@/page/infrastructure/components/carrier/AboutInfo.vue';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import NetworkInfo from '@/page/infrastructure/components/carrier/NetworkInfo.vue';

const pageStore = inject<PageStore>('PageStore') as PageStore;
const pageState = usePlocState(pageStore);

defineProps<{
  carrierId: CarrierList,
  carrierData: Partial<CarrierPage>;
}>();

</script>
<template>
<div v-if="carrierData" class="flex flex-1 flex-col bg-white items-center">

  <slot name="LogoSection" :carrier-data="carrierData.logoSection" :carrier-id="carrierId">
    <LogoSection v-if="carrierData?.logoSection && carrierData?.logoSection.visible" :carrier-data="carrierData.logoSection" :carrier-id="carrierId" ></LogoSection>
  </slot>
  <slot name="afterLogoSection" :page-data="carrierData" :carrier-id="carrierId"></slot>

  <slot name="AboutInfo" :carrier-data="carrierData.aboutInfo" :carrier-id="carrierId">
    <AboutInfo v-if="carrierData?.aboutInfo && carrierData?.aboutInfo.visible" :carrier-data="carrierData.aboutInfo" :carrier-id="carrierId" ></AboutInfo>
  </slot>
  <slot name="afterAboutInfo" :page-data="carrierData" :carrier-id="carrierId"></slot>

  <slot name="SectionInfo" :carrier-data="carrierData.sectionInfo" :carrier-id="carrierId">
    <SectionInfo v-if="carrierData?.sectionInfo && carrierData?.sectionInfo.visible" :carrier-data="carrierData.sectionInfo" :carrier-id="carrierId" ></SectionInfo>
  </slot>
  <slot name="afterSectionInfo" :page-data="carrierData" :carrier-id="carrierId"></slot>

  <slot name="NetworkInfo" :carrier-data="carrierData.networkInfo" :carrier-id="carrierId">
    <NetworkInfo v-if="carrierData?.networkInfo && carrierData?.networkInfo.visible" :carrier-data="carrierData.networkInfo" :carrier-id="carrierId" ></NetworkInfo>
  </slot>
  <slot name="afterNetworkInfo" :page-data="carrierData" :carrier-id="carrierId"></slot>

  <slot name="FinalSection" :carrier-data="carrierData.finalSection" :carrier-id="carrierId">
    <SectionInfo v-if="carrierData?.finalSection && carrierData?.finalSection.visible" :carrier-data="carrierData.finalSection" :carrier-id="carrierId" ></SectionInfo>
  </slot>
  <slot name="afterFinalSection" :page-data="carrierData" :carrier-id="carrierId"></slot>

  <div class="w-full">
    <div class="flex flex-col max-w-7xl mx-auto px-3 py-4">
      <ServicesSection />
    </div>
  </div>

  <div class="flex flex-col max-w-7xl mx-auto px-3">
    <LicenseContent />
  </div>

  <get-quote-banner class="w-full" v-if="pageState.appsEnabled" :applications="JSON.stringify(pageState.appsEnabled)"></get-quote-banner>
</div>
</template>
