<script setup lang="ts">
import { ref } from 'vue';
import { PrimaryButton } from '@hfc/ui-core';
import { TransitionChild, TransitionRoot } from '@headlessui/vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl } = UseUrlHelper();

const open = ref(false);

const toggleOverlay = ():void => {
  open.value = !open.value;
  document.querySelector('body')?.classList.toggle('overflow-hidden');
};
</script>
<template>
  <div @click="toggleOverlay" class="cursor-pointer">
    <slot name="trigger">
      <PrimaryButton>
        Open
      </PrimaryButton>
    </slot>
  </div>
  <TransitionRoot as="template" :show="open" @keydown.esc="toggleOverlay">
    <div class="relative z-[51]">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div>
          <img
            :src="getCdnUrl('/assets/img/logos/hfc.png')"
            class="hidden md:block z-[51] w-72 fixed left-6 top-6 mx-auto lg:mx-0"
            alt="Health Insurance for California"
          >
          <hfc-icon class="z-[51] fixed right-6 top-6 text-5xl w-8 h-8 hover:text-blue-100 dark:text-white text-blue flex cursor-pointer" icon="times" @click="toggleOverlay" />
          <div class="fixed inset-0 bg-blue-300 bg-opacity-60 transition-opacity h-screen"/>
        </div>
      </TransitionChild>
      <div class="fixed z-10 inset-0 overflow-y-auto h-screen">
        <div class="flex items-center  justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" v-if="open">
            <slot name="content"></slot>
          </TransitionChild>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>